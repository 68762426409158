import React, { useState, createContext, useContext, useCallback } from 'react';
import Loader from './components/Loader';


export interface ILoadingMessage {
    id: string;
    title: string;
    content?: JSX.Element;
    key?: string;

}

export interface ILoadingContextData {
    addLoading(message: Omit<ILoadingMessage, 'id'>): void;
    removeLoading(id: string): void;

}

type IProps = {
    children: React.ReactNode
}

const LoadingContext = createContext<ILoadingContextData>({} as ILoadingContextData);

const LoadingProvider: React.FC<IProps> = ({ children }) => {
    const [messages, setMessages] = useState<ILoadingMessage[]>([]);


    const addLoading = useCallback(
        ({ title, content, key = 'loading' }: Omit<ILoadingMessage, 'id'>) => {
            const id = key;
            const newMessage: ILoadingMessage = {
                id,
                title,
                content,
                key
            };

            setMessages(state => [...state, newMessage]);

            return id;
        },
        [],
    );
    const removeLoading = useCallback((id: string) => {
        setMessages(state => state.filter(message => message.id !== id));
    }, []);

    return (
        <LoadingContext.Provider value={{ addLoading, removeLoading }}>
            {children}
            {messages?.length > 0 && messages?.map((contentData, index) => <Loader key={`loader-${index}-${contentData.key}`} title={contentData?.title} />)}
        </LoadingContext.Provider>
    );
};

function useLoading(): ILoadingContextData {
    const context = useContext(LoadingContext);

    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }

    return context;
}

export { useLoading, LoadingProvider };
