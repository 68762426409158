import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns/esm';
import pt from 'date-fns/locale/pt-BR';
import { Container } from './styles';
import { Error } from '../styles';

registerLocale('pt', pt);

interface IProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  placeholder?: string;
}
const DatePickerHour: React.FC<IProps> = ({ name, placeholder, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(
    defaultValue ? parseISO(defaultValue) : null,
  );
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);
  return (
    <Container isFocused={false} isErrored={false} isFilled={false}>

      <span>{placeholder}</span>
      <ReactDatePicker
        locale="pt"
        showTimeSelect
        timeFormat="p"
        timeIntervals={10}
        dateFormat="Pp"
        ref={datepickerRef}
        selected={date}
        onChange={setDate}



        {...rest}
      />
    </Container>
  );
};
export default DatePickerHour;
