import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import ReactInputMask from 'react-input-mask';
import { useField, FormHandles } from '@unform/core';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container } from './styles';
import { Error } from '../styles';
import { FaStar } from 'react-icons/fa';
import { urlWebsite } from '../../../../../../config';
import { useToast } from '../../../../../../hooks/Toast';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
 
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  image ?: string;
}
const RangeInput: React.FC<Props> = ({
  name,
  icon: Icon,
  setCallback = () => {},
 
  formRef,
  image = '',
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const {addToast} = useToast();

  const [currentValue,setCurrentValue] = useState<number>(defaultValue);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFilled(!!inputRef.current);

    const length = setIsFocus(false);
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
 
      getValue: (ref: any) => {
        console.log(currentValue);
        return currentValue;
      },
    });
  }, [fieldName, registerField, currentValue]);

  const itemChange = () => {
    setTimeout(() => {
      setCallback(currentValue);
    },1000)
  }

const changeValue = (v) => {
  setCurrentValue(v);
  itemChange();
  addToast({type: 'success', title: 'Voto adicionado'})

}

  return (
    <Container style={{   margin: '10px 0px', width: '100%', border: '0px', padding: '0px', background: '#fdfdfd',  borderRadius: '10px'}} isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
     <div className='starInput' style={{display:'flex', width:'100%', justifyContent:'center', flexWrap:'wrap',   alignItems:'center'}}>
       {
      image ?  <div style={{width: '100%'}}>
        <img src={`${urlWebsite}/${image}`} style={{width:'100%', borderRadius:'15px 15px 0px 0px'}}/>
       </div> : <></>}
       <div>
      
      
      <div style={{display:'flex', flexWrap: 'wrap', width: '100%',maxWidth: 'auto', border: '0px', padding: '10px 0px 20px 10px', justifyContent:'center',   alignItems:'center'}}>

      <div onClick={() => changeValue(1)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 1 ? 'yellow' : '#fdfdfd', color: '#333'}} > 01 </div>
      <div onClick={() => changeValue(2)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 2 ? 'yellow' : '#fdfdfd', color: '#333'}} > 02 </div>
      <div onClick={() => changeValue(3)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 3 ? 'yellow' : '#fdfdfd', color: '#333'}} > 03 </div>
      <div onClick={() => changeValue(4)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 4 ? 'yellow' : '#fdfdfd', color: '#333'}} > 04 </div>
      <div onClick={() => changeValue(5)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 5 ? 'yellow' : '#fdfdfd', color: '#333'}} > 05 </div>
      <div onClick={() => changeValue(6)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 6 ? 'yellow' : '#fdfdfd', color: '#333'}} > 06 </div>
      <div onClick={() => changeValue(7)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 7 ? 'yellow' : '#fdfdfd', color: '#333'}} > 07 </div>
      <div onClick={() => changeValue(8)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 8 ? 'yellow' : '#fdfdfd', color: '#333'}} > 08 </div>
      <div onClick={() => changeValue(9)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 9 ? 'yellow' : '#fdfdfd', color: '#333'}} > 09 </div>
      <div onClick={() => changeValue(10)} style={{ width: '40px', fontSize: '12px', fontWeight: 'bold', borderRadius: '5px', margin: '2px', border:'1px solid #DDD', height: '40px', display: 'flex', justifyContent: 'center', alignItems:'center', cursor: 'pointer', background : currentValue === 10 ? 'yellow' : '#fdfdfd', color: '#333'}} > 10 </div>

      </div>
    
  
      
      
      
      
     
      </div>
      </div>
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default RangeInput;
