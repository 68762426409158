import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface ToastContainer {
  type: 'success' | 'error' | 'info';
  hasDescription: boolean;
}

const toastTypesVariations = {
  info: css`
    background: #ebf8ff;
    color: #3172b7;
  `,
  success: css`
    background: #e6fffa;
    color: #2e656a;
  `,
  error: css`
    background: #fddedd;
    color: #c53030;
  `,
};

export const Container = styled(animated.div) <ToastContainer>`
  width: 300px;
  position: relative;
  padding: 16px 10px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 5px #000;
  display: flex;
  z-index: 10000;

  p,h2,strong{
    color: rgb(0,14,64) !important;
  }
  

  & + div {
    margin-top: 8px;
  }

  ${props => toastTypesVariations[props.type || 'info']}

  > svg {
    margin: 4px 12px 0 0;
  }
  > div {
    flex: 1;
    padding-right: 8px;

    > p {
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 16px;
    top: 19px;
    opacity: 0.8;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${props =>
    !props.hasDescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`;
