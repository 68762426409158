import React, { useEffect } from 'react';
import { Wrapper, Background, Footer } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';

import PopupComponent from '../../../components/PopupContainer';
 

const DefaultLayout: React.FC = ({ children }) => {
  const { menu } = usePages();
  const { token, user } = useAuth();

  return (<>
    <Wrapper>
      <Menu menuList={menu} fixedMenu={false} />
      {children}
     
      <PopupComponent />
    
    </Wrapper>
    <FooterLayout />
     </>
  );
};

export default DefaultLayout;
