import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { Link } from 'react-router-dom';
import { urlWebsite } from '../../config';

export const CertificateLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 500px;
  border: 1px solid #fff;
  padding: 10px;
  background: rgb(0,14,64);
  border-radius: 5px;

  > a {
    > button {
      width: 150px;
      min-width: 150px;

      border-radius: 8px;
      background: rgb(0, 102, 178);
      border: 0;
      color: #fff;
      margin: 10px;
      padding: 10px;
      transition: background 0.5s;

      &:hover {
        background: rgb(0, 82, 138);
      }
    }
  }

  > p {
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin: 10px;
    padding: 10px;
  }
`;

export const Container = styled.div`
  min-height: 90vh;
  display: flex;

  align-items: center;

  color: #fff;
  justify-content: center;
  position: relative;
  width: 100%;
  flex-direction: column;
  padding: 0 10%;

.darkColor {
  color: #333 !important;
  p,strong{
    color: #333 !important; 
  }
}

  .separator {
    width: 80%;

    height: 2px;
    background: rgb(0, 102, 178);
    margin: 20px 0;
  }

  @media (max-width: 1200px) {
    padding: 0px;

    min-height: auto;
  }

  label {
    display: inline-block;
    background: rgb(88, 41, 93);
    padding: 10px;
    color: #fff;
    margin-bottom: 10px;
  }

  label.inverted {
    background: rgb(65, 193, 202);
    color: rgb(88, 41, 93);
  }

  > div.secondary {
    background: rgb(65, 193, 202);

    > div {
      width: 100%;
    }
  }

  > div.p50 {
    flex-direction: column;
    display: flex;
    width: 50%;
    min-height: 50vh;
    padding: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: 750px) {
      width: 100%;

      min-height: auto;
    }

    a {
      text-decoration: none !important;
      color: #fff !important;
    }

    h2 {
      font-size: 32px;
      margin-bottom: 20px;
      font-weight: bold;
      text-align: left;
      color: #333;

      @media (max-width: 750px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 17px;
      line-height: 1.4;
      color: #333;
      text-align: justify;

      @media (max-width: 750px) {
        font-size: 14px;
      }

      > a {
        text-decoration: none !important;
        color: #333 !important;
      }

      > p {
        > a {
          text-decoration: none !important;
          color: #fff !important;
        }
      }
    }
  }

  div.size400 {
    max-width: 500px;
  }

  img {
    width: 100%;
  }

  .videoContainer {
    width: 100%;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  > div {
    > svg {
      margin-right: 5px;
    }
    padding: 5px 10px;
    width: 100%;
    background: rgb(150, 0, 0);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
  }
`;