import React, { useCallback, useRef, useEffect, useState } from "react";

import { IconType } from "react-icons/lib";
import { Link, useParams } from "react-router-dom";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import api from "../../services/api";
import { date, hour, dateLanguage, simpleDate } from "../../utils/date";
import { Container } from "./styles";
import { addHours } from "date-fns";
import { useToast } from "../../hooks/Toast";
import { Form } from "@unform/web";

import { string } from "yup";

import { useLanguage } from "../../hooks/Language";
import { FaArrowDown, FaArrowUp, FaLock } from "react-icons/fa";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Favorite from "../../components/ClassRoomContainer/modules/Favorite";
import { useAuth } from "../../hooks/Auth";
import { useConfiguration } from "../../hooks/Configuration";
import states from "../LeadForm/FormContainer/states";
import { useSearch } from "../../hooks/Search";
import { loadFromJson } from "../../utils/loadFromJson";
import { hash } from "../../config";

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
  day_reference?: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}

interface IProps {
  bodyModules?: Array<Function>,

}

const Program: React.FC<IProps> = ({ bodyModules = [], }) => {
  const [programs, setPrograms] = useState<Array<IProgramFilter>>([]);
  const [referenceProgram, setReferenceProgram] = useState<Array<IProgramFilter>>([]);
  const [currentDay, setCurrentDay] = useState(0);
  const { projectHash, eventHash } = useParams<IParamsURL>();
  const [search, setSearch] = useState("");
  const [currentProgram, setCurrentProgram] = useState("");
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate, language } = useLanguage();

  const { configuration } = useConfiguration();

  const { reloadKey } = useSearch();



  const [filter, setFilter] = useState({
    date: "",
    category: "",
    search: ""
  });

  const addFilter = (column, value) => {

    const newFilter = { ...filter };
    newFilter[column] = value;

    setFilter(newFilter);

  };

  const newSearch = (data) => {

    const newFilter = { ...filter };
    newFilter.search = search;

    setFilter(newFilter);

  };

  useEffect(() => {

    let programsContent: Array<IProgramFilter> = [];

    if (filter.date) {
      programs.map(p => {
        if (p.simpleDate === filter.date) {
          programsContent.push({ ...p });
        }
      });
    }
    else {
      programsContent = [...programs];
    }



    if (filter.category !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });


        item.program.map(i => {
          if (i?.[configuration?.theme_data?.program_list] === filter.category || !i?.[configuration?.theme_data?.program_list]) {
            filteredProgram[index].program.push(i);
          }
        });


        return false;
      });




      programsContent = [...filteredProgram];
    }



    if (filter.search !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });
        return false;
      });

      programs.map((p1) => {

        const index = filteredProgram.findIndex(p => configuration?.theme_data?.program_list ? p?.[configuration?.theme_data?.program_list] === p1?.[configuration?.theme_data?.program_list] : p.simpleDate === p1.simpleDate);
        if (index >= 0) {
          p1.program.map(p1_program => {
            const text = p1_program?.[`title_${language}`] ? p1_program?.[`title_${language}`] : p1_program?.title?.toString()?.toLowerCase();
            const text2 = p1_program?.[`description_${language}`] ? p1_program?.[`description_${language}`] : p1_program?.description?.toString()?.toLowerCase();
            const searchValue = filter?.search?.toString()?.toLowerCase();
            let indexText = text?.indexOf(searchValue);

            if (indexText < 0) {
              indexText = text2?.indexOf(searchValue);

            }


            if (indexText >= 0) {

              filteredProgram[index].program.push(p1_program);
            }
          });
        }
      });

      programsContent = [...filteredProgram];
    }

    setReferenceProgram(programsContent);

  }, [programs, filter]);

  const checkUser = async (id) => {

    try {
      const response = await api.post(`/check-activity/${id}`);

      if (response && response.data) {
        window.open(response?.data?.url, "_BLANK");
      }
      else {
        addToast({ type: "error", title: translate("Inscrição não encontrada") });
      }


    } catch (err) {
      addToast({ type: "error", title: translate("Inscrição não encontrada") });
    }
  };

  const groupList = {

    room_reference_id: (rep, program_ref: Array<Record<string, any>> = []) => {
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );

      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          room_name: rep.room_reference_id,
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    },

    category: (rep, program_ref: Array<Record<string, any>> = []) => {
      /* const index = program_ref.findIndex(
day => day?.formatted_date === rep.formatted_date && day?.category === rep.category,
); */
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );
      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          category: rep.category,
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    },


    default: (rep, program_ref: Array<Record<string, any>> = []) => {
      const index = program_ref.findIndex(
        day => day?.formatted_date === rep.formatted_date,
      );

      if (index < 0) {
        const item: IProgramFilter = {
          simpleDate: rep.simpleDate,
          formatted_date: rep.formatted_date,
          program: [{ ...rep }],
          date: rep.date
        };

        if (rep?.day_reference) {
          item.day_reference = `${rep?.day_reference || ""} `;

        }

        program_ref.push(item);

      } else {
        program_ref[index].program.push({ ...rep });

        if (rep?.day_reference) {

          program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ""}<br/> ${rep?.day_reference || ""} ` : `${rep?.day_reference || ""} `;
        }
      }

      return program_ref;
    }
  };

  const load = async () => {
    let program_ref: Array<IProgramFilter> = [];
    const rooms = {};

    try {

      let data: Array<Record<string, any>> = [];

      const jsonData = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Program' });

      if (jsonData) {
        data = jsonData || [];
      }
      else {
        const response = await api.get(eventHash ? `/programs-list/${projectHash}/${eventHash}` : `/programs-list/${projectHash}`);

        data = response.data.rows;
      }



      if (data) {
        const responseData = data.reduce((prev, elem) => {
          elem.simpleDate = simpleDate(elem.date);
          elem.formatted_date = dateLanguage(elem.date);
          elem.formatted_date_en = dateLanguage(elem.date, 'en');
          elem.formatted_date_es = dateLanguage(elem.date, 'es');
          elem.formatted_start_hour = hour(elem.start_hour);
          elem.formatted_start_hour_en = hour(elem.start_hour, 'en');
          elem.formatted_start_hour_es = hour(elem.start_hour, 'es');
          elem.formatted_end_hour = hour(elem.end_hour);
          elem.formatted_end_hour_en = hour(elem.end_hour, 'en');
          elem.formatted_end_hour_es = hour(elem.end_hour, 'es');

          console.log(elem);

          if (elem?.[configuration?.theme_data?.program_list]) {
            rooms[elem?.[configuration?.theme_data?.program_list]] = "";
          }

          prev.push(elem);

          return prev;
        }, []);

        const itens: Array<IOption> = [];
        Object.keys(rooms).map(key => {
          itens.push({ label: key, value: key });
        });


        setRoomList(itens);

        if (responseData) {



          responseData.map(rep => {


            program_ref = configuration?.theme_data?.program_list && groupList[configuration?.theme_data?.program_list] ? groupList[configuration?.theme_data?.program_list](rep, program_ref) : groupList.default(rep, program_ref);





          });
        }

        program_ref.map((item, index) => {
          program_ref[index].program.sort((a, b) => {
            return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
          });
        });

        program_ref.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;

        });

        setPrograms(program_ref);
        setReferenceProgram(program_ref);
      }
    }
    catch (err) {
      handleApiErrors(err);
    }
  };

  useEffect(() => {


    load();
  }, []);

  useEffect(() => {

    /* Administrative tool */
    console.log(reloadKey, user, window.location.pathname.indexOf("/manager"));
    if (reloadKey !== "" && user && (user.profile === 1 || user.profile === 4) && window.location.pathname.indexOf("/manager") >= 0) {
      load();
    }

  }, [reloadKey]);

  const line = program => {
    return (
      <tr style={{ cursor: "pointer" }} onClick={() => setCurrentProgram(state => state === program.id ? "" : program.id)}>
        <td className="hourDiv" >  {`
              ${program?.[`formatted_start_hour_${language}`] ? program?.[`formatted_start_hour_${language}`] : program.formatted_start_hour} 
              ${program.formatted_end_hour && `- ${program?.[`formatted_end_hour_${language}`] ? program?.[`formatted_end_hour_${language}`] : program.formatted_end_hour}`}`} </td>  <td style={{ width: "100%" }}>

          <span>

            {program.category ? <><strong style={{ fontSize: "14px", fontWeight: "bold", background: configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : "#f16621", padding: "2px 5px", display: "inline-block", margin: "3px 0px", marginBottom: "5px", color: configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : "#fff" }}> {program?.[`category_${language}`] ? program?.[`category_${language}`] : program.category} </strong><br /></> : <></>}
            {program.mark ? <><strong style={{ fontSize: "12px", width: "auto", borderRadius: "10px", display: "flex", alignItems: "center", fontWeight: "bold", background: "none", paddingBottom: "10px", margin: "3px 0px", marginBottom: "5px", color: "#fff" }}><FaLock size={15} style={{ marginRight: "10px" }} /> {program.mark} </strong> </> : <></>}


            <strong style={{ color: "#fff" }}>
              {" "}

              {program?.[`title_${language}`] ? program?.[`title_${language}`] : program.title}
              {" "}


            </strong>

            {program.description && <div className={"hover"}>
              <div style={{ width: "100%" }}>


                <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program?.[`description_${language}`] ? program[`description_${language}`] : program.description }} />

              </div>


            </div>}
          </span>
        </td>
        {user && <td><div>

          {bodyModules &&
            user &&
            (user.profile === 1 || user.profile === 4) &&
            bodyModules.map((lineModule: Function) => lineModule(program))}


          <span >
            {program.room_reference_id_response &&
              program.room_reference_id_response.title}

            {program.link_url && (
              <a href={`/app/${projectHash}/dashboard/${program.link_url}`} target="_BLANK" rel="noreferrer">
                <button className="defaultButtonMini buttonBlue" type="button">
                  {" "}
                  <FiChevronRight />
                </button>
              </a>
            )}

            {program.activity_id > 0 && (

              <button onClick={() => checkUser(program.activity_id)} className="defaultButtonMini buttonBlue" type="button">
                {" "}
                <FiChevronRight />
              </button>

            )}

            {program.video_id && (
              <div className="optionDiv">
                <button className="defaultButtonMini" type="button"><Favorite type="lesson" subtype="star" id={program.video_id} /></button>
                <Link to={`/app/${projectHash}/dashboard/${program.video_id}`} style={{ margin: "5px" }}>
                  <button className="defaultButtonMini" type="button">
                    {" "}Clique para
                    assistir
                    {/* <FiChevronRight style={{margin: '0px 2px'}} size={24} /> */}
                  </button>
                </Link></div>
            )}
          </span>
        </div>
        </td>}
      </tr>
    );
  };
  const { length } = programs;


  const simpleDateList: Array<string> = [];

  programs.map(filterday => {

    const index = simpleDateList.findIndex(a => a === filterday.simpleDate);

    if (index < 0) {
      simpleDateList.push(filterday.simpleDate);
    }

  });

  const changeCategory = (cat) => {
    const newFilter = { ...filter };
    newFilter.category = newFilter.category === cat ? "" : cat || "";
    setFilter(newFilter);
  };


  return (
    programs.length > 0 ? <Container id="programacao">
      <h1 style={{ color: "rgb(0, 14, 64)", textAlign: "center" }}>{translate(configuration?.program_title ? configuration?.program_title : translate("Programação"))} </h1>
      {configuration?.program_subtitle ? <h2 style={{ color: "rgb(0, 14, 64)", marginBottom: "10px" }}>{translate(configuration?.program_subtitle)} </h2> : <></>}
      <p style={{ textAlign: "center", width: "100%", marginBottom: "20px", color: "rgb(0,15,64)" }}>{translate('Horário de Brasilia')}</p>


      {configuration?.theme_data?.program_header === "no" ? <></> : <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap", alignContent: "center", justifyContent: "center", width: "100%" }}>

        {/* roomList.length > 0  && configuration?.theme_data?.program_list === 'category' &&  <select className='mobileOnly' style={{padding: '5px 10px', height:'44px', width:'100%'}} onChange={( e) => {
 changeCategory(e.target.value)
}}> 
  <option value={""}>Todas as Áreas</option>
  {roomList.map( room => <option value={room.label}>{room.label}</option>)}
</select> */ }

        {simpleDateList.length > 1 && <> <button style={{ width: "130px", height: "43px", margin: "5px", background: filter.date === "" ? configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : "#f16621" : "none", color: filter.date === "" ? configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : "#fff" : configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : "#f16621", border: `2px solid ${configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : "#f16621"},` }} onClick={() => addFilter("date", "")}>{translate("Todos os dias")}</button>
          {simpleDateList.map(day => {
            return day && <button style={{ width: "60px", margin: "5px", height: "43px", background: filter.date === day ? configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : "#f16621" : "none", color: filter.date === day ? configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : "#fff" : configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : "#f16621", border: `2px solid ${configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : "#f16621"}` }} onClick={() => addFilter("date", day)}>{translate(day)}</button>;
          })}
        </>}

        <Form
          style={{ display: "flex", alignItems: "stretch", background: "none", margin: "5px" }}
          onSubmit={newSearch}
        >


          <input style={{ height: "43px", padding: "10px" }}
            name="pesquisa"
            type="text"
            placeholder={`${translate("Escreva para pesquisar")}...`}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

          <button style={{ width: "100px", height: "43px", background: configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : "#f16621", color: configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : "#fff", borderRadius: "10px", border: "0px", margin: "0px 5px" }} type="submit">{translate("Pesquisar")}</button>
        </Form>


      </div>}

      {roomList && roomList.length > 0 && (configuration?.theme_data?.program_list === "category" || configuration?.theme_data?.program_list === "room_reference_id") && <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexWrap: "wrap", marginTop: "15px" }}>
        <>

          {roomList.map(room => {
            return <button style={{ margin: "3px", borderRadius: "5px", height: "43px", background: filter?.category === room?.label ? "rgb(0,15,64)" : "#777" }} onClick={() => changeCategory(room.label)} className='defaultButtonMiniProgram' >{room.label}</button>;
          })}
        </>
      </div>}


      {referenceProgram.map(program => {
        return (program.program.length > 0 ? <div>
          <div className="programDayHeader" key={`${program.category}-${program.formatted_date}`} >


            {program.formatted_date && <h2 style={{ background: configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : "#f16621", color: configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : "#fff", marginTop: "15px", padding: "10px", marginLeft: "7px" }}>{program?.[`formatted_date_${language}`] ? program?.[`formatted_date_${language}`] : program.formatted_date}</h2>}
            {program.day_reference && <p style={{ background: "#eee", fontSize: "14px", padding: "5px 10px", lineHeight: "18px", textAlign: "center", color: "#333", marginTop: "10px", marginLeft: "7px" }} dangerouslySetInnerHTML={{ __html: program.day_reference }}>{ }</p>}

            { /* program.category && <h2 style={{background: configuration?.theme_data?.primary_background? configuration?.theme_data?.primary_background : '#f16621', color: configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : '#fff', marginTop: '10px', padding: '10px', marginLeft: '7px'}}>{translate(program.category)}</h2> */}

          </div>


          <table>

            <tbody>{program.program.map(items => line(items))}</tbody>
          </table>

        </div> : <></>);
      })}

    </Container> : <></>
  );
};
export default Program;
