import styled from 'styled-components';

export const Side = styled.div`
  width: 300px;
  height: 100vh;
  overflow-y: scroll;
  background: #fff;

  z-index: 999;
  transition: 0.2s all;

  position: relative;

  > input {
    padding: 10px 20px;
  }

  > div {
    padding: 10px;
    > button {
      text-align: left;
      padding: 10px;
      border: 0;
      width: 100%;
      background: none;
      color: #333;
      border-radius: 0;
      border-bottom: 1px solid #ddd;
      transition: 0.5s all;
      cursor: pointer;

      &:hover {
        background: #eee;
        padding-left: 15px;
      }

      > p.title {
        color: #333;
        display: block;
        font-size: 14px;
        font-weight: bold;
      }
      > p.date {
        font-size: 12px;
        color: #444;
        width: 100%;
        text-align: left;
      }
      > p.teachers {
        font-size: 12px;
        color: #444;
        width: 100%;
        text-align: left;
      }
    }
  }

  > div + div {
    border-top: 1px solid #eee;
  }

  @media (max-width: 730px) {
    width: 100%;
  }
`;

interface IMainContainer {
  visible?: boolean;
}

export const MainContainer = styled.div<IMainContainer>`
  width: ${props => (props.visible ? `calc(100%-300px)` : `100%`)};
  height: 100%;
  min-width: 400px;

  display: flex;
  justify-content: space-between;

  flex-direction: column;
  border-left: 2px solid #eee;
  @media (max-width: 730px) {
    border-left: 0px;
  }

  > div {
    padding: 10px;
    > nav {
      color: #333;
      margin-top: 10px;
      padding: 10px;
      border-bottom: 1px solid #ddd;
      transition: 0.5s all;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      > div.main {
        display: flex;
        width: 100%;
        flex-direction: column;
        > p.title {
          color: #333;
          display: block;
          font-size: 14px;
          font-weight: bold;
        }
        > p.date {
          font-size: 12px;
          color: #444;
          width: 100%;
          text-align: left;
        }
        > p.teachers {
          font-size: 12px;
          color: #444;
          width: 100%;
          text-align: left;
        }
      }

      > div.modules {
        display: flex;
        width: 150px;
        min-width: 150px;
        align-items: center;
        justify-content: flex-start;

        > svg {
          color: #333;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }

  > div + div {
    border-top: 1px solid #eee;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  background: #fff;
  display: flex;
  justify-content: center;

  @media (max-width: 730px) {
    flex-direction: column;
  }
`;
