import React from 'react';
import { urlWebsite } from '../../../../../config';
import { useLanguage } from '../../../../../hooks/Language';
import { useVideo } from '../../../../../hooks/Video';
import { AnimatedContainer } from '../../../styles';
import Favorite from '../../Favorite';

interface IVideos {
    before: any;
    waiting: any;
    online: any;

}


interface IProps {
    videos: IVideos
}

const Template: React.FC<IProps> = ({ videos }) => {
    const { translate, language } = useLanguage();
    const { openVideoByID, courses, videoStatus } = useVideo();




    const listBlock = ({ block, title }) => {

        const index = block?.list?.findIndex(i => i.visible === 'yes' || i.visible === '2');

        if (index < 0) {
            return <></>;
        }


        return (<>
            {videoStatus &&
                block &&
                block.list &&
                block.list.length > 0 &&
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <h2 style={{ padding: '10px', background: '#fff', margin: '10px auto', borderRadius: '10px', color: 'rgb(0,14,64)' }}>{title}</h2>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' }}>
                        {block.list.map((lesson, index) => (
                            lesson?.visible !== '1' ? printLesson(lesson, index) : <></>))}
                    </div></div>
            }


        </>)
    }


    const getKeywords = (keywords: Array<string>) => {
        if (keywords && keywords.length > 0) {
            return <div className="keywordList">
                {keywords.map(key => <label>{key}</label>)}
            </div>
        }
        else {
            return <></>;
        }
    }

    const getSpeakers = (speakers: string) => {
        if (speakers && speakers.indexOf(';') > 0) {

            const listSpeakers = speakers.split(";");

            if (listSpeakers && listSpeakers.length >= 0) {
                return <div className="speakerList">
                    {listSpeakers.map(speaker => <p>{speaker}</p>)}
                </div>
            }


        }
        else {
            return <></>;
        }
    }

    const printLesson = (lesson, index) => {

        const statusSelector = {
            "1": translate('Em breve'),
            "2": <><div className="redBall" /> {translate('Ao vivo')}</>,
            "3": translate('Finalizado')
        }



        return (<div key={`videoLesson${lesson.hash}`} style={{ position: 'relative' }}><AnimatedContainer style={{ display: lesson.visible === 'yes' || lesson.visible === '2' ? 'flex' : 'none' }}
            key={`videoLesson${lesson._id}`}
            className={`videoBox ${lesson.class_name && lesson.class_name} ${lesson.status && `status${lesson.status}`}`}
            onClick={() => { openVideoByID(courses, lesson._id) }}
            background_color={'rgba(0,14,64,0.8)'}
            background={
                lesson.image
                    ? `${urlWebsite}/${lesson.image}`
                    : ''
            }
        >

            <div className="videoThumbHeader">
                <div>{lesson.start_hour} - {translate(lesson.release_date_formatted)} </div>
                <div className="videoStatus"> {translate(statusSelector[lesson.status]) || translate('Em breve')}</div>
            </div>


            {lesson.image && (<>
                {lesson.room_name && <p style={{ color: '#fff', width: '90%', textAlign: 'left', marginBottom: '10px', fontSize: '12px' }}>{lesson.room_name}</p>}
                <h2 style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: lesson?.[`title_${language}`] ? lesson[`title_${language}`] : lesson.title }} />
                {lesson.subtitle && <p dangerouslySetInnerHTML={{ __html: lesson.subtitle }} />}

                {lesson.nested_speakers && getSpeakers(lesson.nested_speakers)}

                {lesson.keywords && getKeywords(lesson.keywords)}



            </>)}

            {!lesson.image && lesson.title && (
                <>
                    <h2 style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: lesson?.[`title_${language}`] ? lesson[`title_${language}`] : lesson.title }} />

                </>
            )}


        </AnimatedContainer>
            <div style={{ display: lesson.visible === 2 || lesson.visible === 'yes' ? 'flex' : 'none' }} className="lessonFooter">
                <button className="lessonStar" title="Favoritar"><Favorite key={`lesson-${lesson._id}-star`} type="lesson" id={lesson._id} subtype="star" /></button>

            </div>
        </div> || <></>
        )
    }

    return (<>
        {listBlock({ block: videos.online, title: translate('Online Agora') })}
        {listBlock({ block: videos.waiting, title: translate('Em breve') })}
        {listBlock({ block: videos.before, title: translate('On Demand') })}

    </>);

}

export default Template;