export const hash = '468c8e08-ff13-44d8-b0e5-0e7abc894923';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6Ld9r7cZAAAAABw5wijiLD5PhlWqLivIN9NqFKi_';
export const apiUrl = `https://api-global-v3.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br';



//export const apiSocket = 'http://localhost:8080';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://clubeacademico.sbcbm.org.br';
export const supportNumber = `555195181533`;
export const home = 'Home';
export const haveTranslate = false;
export const defaultProjectHash = 'sbcbm';
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Clube Acadêmico SBCBM';
export const logo = '/assets/logo.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'Clube Acadêmico SBCBM';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  spotify: '',
  twitter: '',
};


export const defaultTheme = {
  defaultBackground: '#0088c4',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: '#fff',
    backgroundSticky: '#fff',
    backgroundMobile: '#fff',
    color: '#555',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
