import React, {

  useEffect,
  useRef,
  useState,

} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { uuid } from 'uuidv4';
import { Container } from './styles';
import { useSocket } from '../../../../hooks/Socket';
import TextPopper from '../../../TextPopper';
import Textarea from '../../../Forms/TextareaInput';
import { useToast } from '../../../../hooks/Toast';
import Button from '../../../Button';
import { Modal } from '../../styles';
import { FiX } from 'react-icons/fi';
import { useLanguage } from '../../../../hooks/Language';

interface IReceiver {
  room_id: string;
  module_id?: number;

}

interface ICreator {
  room_id: string;
  module_id?: number;
  active?: boolean;
  setOption: React.Dispatch<React.SetStateAction<string>>;
}

export const RoomMessagerContainer: React.FC<IReceiver> = ({
  room_id,
  module_id = 1,
}) => {
  const { socket } = useSocket();

  const [message, setMessage] = useState<Array<string>>([]);
  const [active, setActive] = useState(false);
  const buildMessage = (string: string, timer = 300) => {
    const { length } = string;



    if (string.trim() !== '') {
      setActive(true);

    }
    else {
      setActive(false);
    }

    const stringArray: Array<string> = [];

    const track: Array<string> = [];
    for (let x = 0; x < length; x += 1) {
      const char = string.substr(x, 1);
      stringArray.push(char);
    }

    for (let y = 0; y < timer; y += 1) {
      track.push('');
    }

    setMessage([string]);
  };

  useEffect(() => {

    socket?.off(`getRoomMessage-${room_id}`);
    socket?.on(`getRoomMessage-${room_id}`, state => {




      buildMessage(state?.message);
    });
  }, [room_id]);

  useEffect(() => {
    buildMessage('');
    socket?.emit('requestRoomMessage', { room_id, global: '1', module_id });
  }, [room_id, module_id]);



  return (
    <> {active ? <Container>
      <TextPopper
        keyName={`key${room_id}`}
        key={uuid()}
        group
        direction="up"
        time={15000}
        messages={message}
      />
    </Container> : <></>}</>
  );
};

export const RoomMessagerCreator: React.FC<ICreator> = ({
  room_id,
  module_id = 1,
  active = false,
  setOption
}) => {
  const { socket } = useSocket();
  const { addToast } = useToast();
  const [creatorActive, setCreatorActive] = useState(false);
  const { translate } = useLanguage();
  const formRef = useRef<FormHandles>(null);

  const handleMessage = data => {
    if (data.message) {
      socket?.emit('sendRoomMessage', {
        message: data.message,
        room_id,
        module_id,

      });
      addToast({ type: 'success', title: translate('Enviado com sucesso') });
      setOption('');
    }
  };

  return (
    <>

      {active && (
        <Modal top right fullHeight>
          <div>
            <button type='button' className='closeAdminButton' onClick={() => setOption('')}>{translate("Fechar")} <FiX /></button>

            <Form ref={formRef} onSubmit={handleMessage} initialData={{}}>
              <h2 style={{ textAlign: 'center' }}>{translate("Mensagem")}</h2>
              <Textarea name="message" placeholder="" />
              <div className='actionDiv'>
                <button className='actionAdminButton' type="submit">{translate("Enviar")}</button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};
