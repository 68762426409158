import React, { useEffect, useState } from 'react';

import Gallery from '../Gallery';
import { AnimatedDiv } from './styles';
import { urlWebsite } from '../../config';

import Vimeo from '../Vimeo';
import Youtube from '../Youtube';

interface IPhoto {
  title?: string;
  video_id: string;
  type_id: string;
  className?: string;
}

interface IData {
  data: Array<IPhoto>;
  targetOpen?: string;
}

const VideoGallery: React.FC<IData> = ({ data, targetOpen }) => {
  const [currentImage, setCurrentImage] = useState(-1);
  const [dataItems, setDataItems] = useState<Array<IPhoto>>(data);
  const [current, setCurrent] = useState(0);
  const [fixed, setFixed] = useState(false);
  const dataSize = dataItems.length - 1;



  return (
    <>
      {data && 
      <>
      <Gallery>
        {data.map((video, index) => (
           video?.video_id ? 
          <AnimatedDiv
            key={`${urlWebsite}/${video.video_id}`}
            
          >
            {  video.type_id === '1' ? <Youtube id={video.video_id} /> : <Vimeo id={video.video_id}/>}
          </AnimatedDiv> : <></>
        ))}
      </Gallery></>
}
    </>
  );
};
export default VideoGallery;
