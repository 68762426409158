import styled from 'styled-components';

export const CourseContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 1;
  > aside {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 600px;
    > button {
      margin: 10px;
      min-width: 200px;
      padding: 10px;
      color: #333;
      border-radius: 8px;
      display: flex;
      background: #fff;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      > input {
        width: 30px;
      }
    }
  }
`;
