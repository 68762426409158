import React from 'react';
import { FaChalkboardTeacher, FaFileExcel, FaList } from 'react-icons/fa';
import CopyToClipboard from '../../../core/components/CopyToClipboard';
import { apiUrl, getToken, urlWebsite } from '../../../../../config';
import userEvent from '@testing-library/user-event';
import { useAuth } from '../../../../../hooks/Auth';
import downloadit from '../../../../../utils/download';
import { useParams } from 'react-router-dom';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function ExportList({ lineData }: ICreateModule): JSX.Element {
  const { user } = useAuth();
  const { projectHash } = useParams<Record<string, any>>();

  const download = () => {

    downloadit({ url: `${apiUrl}/export-user-access/${lineData?._id?.toString()}` });
  }

  const moduleList = (): JSX.Element => {

    return <> {user && user.profile === 1 ? <button className='searchButton' type='button' onClick={() => download()}><FaList title="Exportar Lista geral" /></button> : <></>}
    </>;
  }



  return moduleList();
}
