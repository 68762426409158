export const siglas = [
  { sigla: 'OUTRAS', razao_social: '', classificacao: '' },
  {
    sigla: 'CEFETMINAS',
    razao_social:
      'Fundação de Apoio a Educação e Desenvolvimento Tecnológico de Minas Gerais - Fundação CEFETMINAS',
    classificacao: 'A',
  },
  {
    sigla: 'COPPETEC',
    razao_social:
      'Fundação Coordenação de Projetos, Pesquisas e Estudos Tecnológicos',
    classificacao: 'C',
  },
  {
    sigla: 'FACC',
    razao_social:
      'Fundação de Apoio ao Desenvolvimento da Computação Científica',
    classificacao: 'A',
  },
  {
    sigla: 'FACEPE',
    razao_social:
      'Fundação de Apoio à Cultura, Ensino, Pesquisa e Extensão de Alfenas',
    classificacao: 'A',
  },
  {
    sigla: 'FACEV',
    razao_social:
      'Fundação Artística, Cultural e de Educação para a Cidadania de Viçosa ',
    classificacao: 'A',
  },
  {
    sigla: 'FACTI',
    razao_social: 'Fundação de Apoio a Capacitação em Tecnologia da Informação',
    classificacao: 'A',
  },
  {
    sigla: 'FACTO',
    razao_social:
      'Fundação de Apoio ao Desenvolvimento da Ciência e Tecnologia',
    classificacao: 'A',
  },
  {
    sigla: 'FADECIT',
    razao_social:
      'Fundação de Apoio e Desenvolvimento da Educação, Ciência e Tecnologia de Minas Gerais',
    classificacao: 'A',
  },
  {
    sigla: 'FADEPE',
    razao_social:
      'Fundação de Apoio e Desenvolvimento ao Ensino, Pesquisa e Extensão',
    classificacao: 'C',
  },
  {
    sigla: 'FADESP',
    razao_social: 'Fundação de Amparo e Desenvolvimento da Pesquisa',
    classificacao: 'C',
  },
  {
    sigla: 'Fade-UFPE',
    razao_social: 'Fundação de Apoio ao Desenvolvimento da UFPE',
    classificacao: 'C',
  },
  {
    sigla: 'FADEX',
    razao_social:
      'Fundação Cultural e de Fomento à Pesquisa , Ensino, Extensão e Inovação',
    classificacao: 'A',
  },
  {
    sigla: 'FADURPE',
    razao_social: 'Fundação Apolônio Salles de Desenvolvimento Educacional ',
    classificacao: 'A',
  },
  {
    sigla: 'FAEPE',
    razao_social: 'Fundação de Apoio ao Ensino, Pesquisa e Extensão ',
    classificacao: 'A',
  },
  {
    sigla: 'FAEPI',
    razao_social:
      'Fundação de Apoio ao Ensino, Pesquisa, Extensão e Interiorização do IFAM - FAEPI',
    classificacao: 'A',
  },
  {
    sigla: 'FAEPU',
    razao_social: 'Fundação de Assistência, Estudo e Pesquisa de Uberlândia',
    classificacao: 'A',
  },
  {
    sigla: 'FAESPE',
    razao_social: 'Fundação de Apoio ao Ensino Superior Público Estadual',
    classificacao: 'A',
  },
  {
    sigla: 'FAHUB',
    razao_social:
      'Fundação de Apoio ao Desenvolvimento Científico e Tecnológico do Hospital da UnB',
    classificacao: 'A',
  },
  {
    sigla: 'FAI.UFSCAR',
    razao_social:
      'Fundação de Apoio Institucional ao Desenvolvimento Cientifico e Tecnológico',
    classificacao: 'C',
  },
  {
    sigla: 'FAP UNIFESP',
    razao_social: 'Fundação de Apoio a Universidade Federal de São Paulo',
    classificacao: 'A',
  },
  {
    sigla: 'FAPEB',
    razao_social:
      'Fundação de Apoio à Pesquisa, Desenvolvimento e Inovação do Exército Brasileiro',
    classificacao: 'A',
  },
  {
    sigla: 'FAPEC',
    razao_social: 'Fundação de Apoio a Pesquisa ao Ensino e a Cultura',
    classificacao: 'A',
  },
  {
    sigla: 'FAPED',
    razao_social: 'Fundação de Apoio à Pesquisa e ao Desenvolvimento',
    classificacao: 'A',
  },
  {
    sigla: 'FAPEPE',
    razao_social:
      'Fundação de Apoio ao Ensino, Pesquisa e Extensão de Itajubá ',
    classificacao: 'A',
  },
  {
    sigla: 'FAPESE',
    razao_social: 'Fundação de Apoio a Pesquisa e Extensão de Sergipe',
    classificacao: 'A',
  },
  {
    sigla: 'FAPEU',
    razao_social: 'Fundação de Amparo à Pesquisa e Extensão Universitária',
    classificacao: 'C',
  },
  {
    sigla: 'FAPEX',
    razao_social: 'Fundação de Apoio à Pesquisa e à Extensão',
    classificacao: 'C',
  },
  {
    sigla: 'FAPTO',
    razao_social: 'Fundação de Apoio  Cientifico e Tecnológico do Tocantins',
    classificacao: 'A',
  },
  {
    sigla: 'FAPUR',
    razao_social:
      'Fundação de Apoio à Pesquisa Científica e Tecnológica da UFRRJ',
    classificacao: 'B',
  },
  {
    sigla: 'FASTEF',
    razao_social:
      'Fundação de Apoio a Serviços Técnicos, Ensino e Fomento à Pesquisas',
    classificacao: 'A',
  },
  {
    sigla: 'FATEC',
    razao_social: 'Fundação de Apoio a Tecnologia e Ciência ',
    classificacao: 'A',
  },
  {
    sigla: 'FAU',
    razao_social: 'Fundação de Apoio Universitário',
    classificacao: 'B',
  },
  {
    sigla: 'FAUEL',
    razao_social:
      'Fundação de Apoio ao Desenvolvimento da Universidade Estadual de Londrina',
    classificacao: 'A',
  },
  {
    sigla: 'FAUF',
    razao_social:
      'Fundação de Apoio à Universidade Federal de São João Del Rei',
    classificacao: 'A',
  },
  {
    sigla: 'FAURG',
    razao_social: 'Fundação de Apoio à Universidade do Rio Grande ',
    classificacao: 'A',
  },
  {
    sigla: 'FAURGS',
    razao_social:
      'Fundação de Apoio da Universidade Federal do Rio Grande do Sul',
    classificacao: 'C',
  },
  {
    sigla: 'FCMF',
    razao_social: 'Fundação Casimiro Montenegro Filho',
    classificacao: 'A',
  },
  {
    sigla: 'FCO',
    razao_social: 'Fundação Christiano Ottoni',
    classificacao: 'B',
  },
  {
    sigla: 'FCPC',
    razao_social: 'Fundação Cearense de Pesquisa e Cultura',
    classificacao: 'A',
  },
  {
    sigla: 'FDMS',
    razao_social: 'Fundação Delfim Mendes Silveira',
    classificacao: 'A',
  },
  {
    sigla: 'FEC',
    razao_social: 'Fundação Euclides da Cunha de Apoio Institucional a UFF',
    classificacao: 'B',
  },
  {
    sigla: 'FEEng',
    razao_social: 'Fundação Empresa Escola de Engenharia da UFRGS',
    classificacao: 'A',
  },
  {
    sigla: 'FEESC',
    razao_social: 'Fundação de Ensino e Engenharia de Santa Catarina',
    classificacao: 'B',
  },
  {
    sigla: 'FEPBA',
    razao_social: 'Fundação Escola Politécnica da Bahia',
    classificacao: 'A',
  },
  {
    sigla: 'FEPE',
    razao_social: 'FUNDAÇÃO DE APOIO AO ENSINO, PESQUISA E EXTENSÃO - FEPE',
    classificacao: 'A',
  },
  {
    sigla: 'FEPESE',
    razao_social: 'Fundação de Estudos e Pesquisas Sócio-Econômicos',
    classificacao: 'A',
  },
  {
    sigla: 'FEST',
    razao_social: 'Fundação Espírito-santense de Tecnologia ',
    classificacao: 'B',
  },
  {
    sigla: 'FGD',
    razao_social: 'Fundação Guimarães Duque',
    classificacao: 'A',
  },
  {
    sigla: 'FINATEC',
    razao_social: 'Fundação de Empreendimentos Científicos e Tecnológicos',
    classificacao: 'C',
  },
  {
    sigla: 'Fiotec',
    razao_social:
      'Fundação para o Desenvolvimento Cientifico e Tecnológico em Saúde ',
    classificacao: 'C',
  },
  {
    sigla: 'FIPT',
    razao_social: 'Fundação de Apoio ao Instituto de Pesquisas Tecnológicas',
    classificacao: 'C',
  },
  {
    sigla: 'FJMONTELLO',
    razao_social: 'Fundação Josué Montello',
    classificacao: 'A',
  },
  { sigla: 'FLE', razao_social: 'Fundação Luiz Englert ', classificacao: 'A' },
  {
    sigla: 'FSADU',
    razao_social: 'Fundação Sousândrade de Apoio ao Desenvolvimento da UFMA',
    classificacao: 'B',
  },
  {
    sigla: 'FUJB',
    razao_social: 'Fundação Universitária José Bonifácio',
    classificacao: 'B',
  },
  {
    sigla: 'FUNAEPE',
    razao_social: 'Fundação de Apoio ao Ensino, Pesquisa e Extensão',
    classificacao: 'A',
  },
  {
    sigla: 'FUNAPE',
    razao_social: 'Fundação de Apoio à Pesquisa',
    classificacao: 'C',
  },
  {
    sigla: 'FUNARBE',
    razao_social: 'Fundação Arthur Bernardes ',
    classificacao: 'B',
  },
  {
    sigla: 'FUNCAMP',
    razao_social: 'Fundação de Desenvolvimento da UNICAMP',
    classificacao: 'C',
  },
  {
    sigla: 'FUNCATE',
    razao_social: 'Fundação de Ciência, Aplicações e Tecnologia Espaciais',
    classificacao: 'C',
  },
  {
    sigla: 'FUNCEPE',
    razao_social: 'Fundação de Cultura e Apoio ao Ensino, Pesquisa e Extensão ',
    classificacao: 'A',
  },
  {
    sigla: 'FUNCERN',
    razao_social:
      'Fundação de Apoio à Educação e ao Desenvolvimento Tecnológico do Rio Grande do Norte',
    classificacao: 'A',
  },
  {
    sigla: 'FUNDAÇÃO CAED',
    razao_social:
      'Fundação Centro de Políticas Públicas e Avaliação da Educação - Fundação CAED',
    classificacao: 'A',
  },
  {
    sigla: 'Fundação CETREDE',
    razao_social:
      'Fundação de Apoio à Cultura, à Pesquisa e ao Desenvolvimento Institucional, Científico e Tecnológico',
    classificacao: 'A',
  },
  {
    sigla: 'Fundação PaqTcPB',
    razao_social: 'Fundação Parque Tecnológico da Paraíba',
    classificacao: 'A',
  },
  {
    sigla: 'FUNDAHC',
    razao_social: 'Fundação de Apoio ao Hospital das Clinicas da UFG',
    classificacao: 'A',
  },
  {
    sigla: 'FUNDAPE',
    razao_social:
      'Fundação de Apoio e Desenvolvimento ao Ensino, Pesquisa e Extensão Universitária no Acre',
    classificacao: 'A',
  },
  {
    sigla: 'FUNDECC',
    razao_social: 'Fundação de Desenvolvimento Científico e Cultural',
    classificacao: 'A',
  },
  {
    sigla: 'FUNDEP ',
    razao_social: 'Fundação de Desenvolvimento e Pesquisa',
    classificacao: 'C',
  },
  {
    sigla: 'FUNDEPES',
    razao_social:
      'Fundação Universitária de Desenvolvimento de Extensão e Pesquisa',
    classificacao: 'A',
  },
  {
    sigla: 'FUNDMED',
    razao_social: 'Fundação Médica do Rio Grande do Sul',
    classificacao: 'A',
  },
  {
    sigla: 'FUNDUNESP',
    razao_social: 'Fundação para o Desenvolvimento da UNESP ',
    classificacao: 'B',
  },
  {
    sigla: 'FUNEP',
    razao_social: 'Fundação de Apoio a Pesquisa, Ensino e Extensão – FUNEP',
    classificacao: 'A',
  },
  {
    sigla: 'FUNETEC',
    razao_social: 'Fundação de Educação Tecnológica e Cultural da Paraíba',
    classificacao: 'A',
  },
  {
    sigla: 'FUNJAB',
    razao_social: 'Fundação José Arthur Boiteux ',
    classificacao: 'A',
  },
  {
    sigla: 'FUNPAR',
    razao_social:
      'Fundação da UFPR para o Desenvolvimento da Ciência, Tecnologia e Cultura ',
    classificacao: 'C',
  },
  {
    sigla: 'FUNPEC',
    razao_social: 'Fundação Norte-Rio-Grandense de Pesquisa e Cultura ',
    classificacao: 'C',
  },
  {
    sigla: 'FUNTEF',
    razao_social:
      'Fundação de Apoio a Educação, Pesquisa e Desenvolvimento Científico e Tecnológico da UFTPR',
    classificacao: 'A',
  },
  {
    sigla: 'FUPAI',
    razao_social: 'Fundação de Pesquisa e Assessoramento a Indústria',
    classificacao: 'A',
  },
  {
    sigla: 'FUPEF',
    razao_social:
      'Fundação de Pesquisas Florestais do Paraná - FUPEF do Paraná',
    classificacao: 'A',
  },
  {
    sigla: 'FUSP',
    razao_social: 'Fundação de Apoio A Universidade de São Paulo',
    classificacao: 'C',
  },
  { sigla: 'GORCEIX', razao_social: 'Fundação Gorceix', classificacao: 'A' },
  {
    sigla: 'IMEPEN',
    razao_social:
      'Fundação Instituto Mineiro de Estudos e Pesquisas em Nefrologia ',
    classificacao: 'A',
  },
  {
    sigla: 'IPEAD',
    razao_social:
      'Fundação Instituto de Pesquisas Econômicas, Administrativas e Contábeis de Minas Gerais',
    classificacao: 'A',
  },
  {
    sigla: 'MURAKI',
    razao_social: 'Fundação de Apoio Institucional Muraki',
    classificacao: 'A',
  },
  {
    sigla: 'RTVE',
    razao_social: 'Fundação Rádio e Televisão Educativa e Cultural',
    classificacao: 'A',
  },
  {
    sigla: 'Uniselva',
    razao_social:
      'Fundação de Apoio e Desenvolvimento da Universidade Federal de Mato Grosso',
    classificacao: 'B',
  },
  {
    sigla: 'UNISOL',
    razao_social: 'Fundação de Apoio Institucional Rio Solimões',
    classificacao: 'B',
  },
];

export interface ISigla {
  value: string;
  label: string;
  sigla: string;
  classificacao: string;
  razao_social: string;
}

export const readSigla = (): Array<ISigla> => {
  const response: Array<ISigla> = [];

  siglas.map(sig => {
    response.push({ value: sig.sigla, label: sig.sigla, ...sig });
  });

  return response;
};
