import React, { useState, createContext, useContext, useEffect } from 'react';

interface IScrollContextData {
  topY: number;
  bottomY: number;
}

const ScrollContext = createContext<IScrollContextData>(
  {} as IScrollContextData,
);

const ScrollProvider: React.FC = ({ children }) => {
  // eslint-disable-next-line no-restricted-globals
  const { height } = screen;
  const [topY, setTopY] = useState(0);
  const [bottomY, setBottomY] = useState(height);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const half = height / 2;
      const top = window.pageYOffset;
      setTopY(top);
      setBottomY(top + half);
    });
  }, []);

  return (
    <ScrollContext.Provider value={{ topY, bottomY }}>
      {children}
    </ScrollContext.Provider>
  );
};

function useScroll(): IScrollContextData {
  const context = useContext(ScrollContext);

  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }

  return context;
}

export { useScroll, ScrollProvider };
