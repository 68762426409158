import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/Auth';
import { useSocket } from '../../../../hooks/Socket';
import uniqueHash from '../../../../pages/DashboardPages/core/components/UniqueHash';

import { LessonFooter, EmojiElem } from './styles';
interface IProps {
  room?: string | number;
};

const LessonEmoji: React.FC<IProps> = ({ room }) => {
  const { socket } = useSocket();
  const { user } = useAuth();
  const [emojis, setEmojis] = useState<Array<JSX.Element>>([]);

  const emojiRef = {
    happy: () => <img key={uniqueHash()} src="/emojis/happy.png" />,
    love: () => <img key={uniqueHash()} src="/emojis/love.png" />,
    smile: () => <img key={uniqueHash()} src="/emojis/smile.png" />,
    sad: () => <img key={uniqueHash()} src="/emojis/sad.png" />,
    party: () => <img key={uniqueHash()} src="/emojis/party.png" />
  }

  useEffect(() => {
    socket?.off(`emojiReceive-${room}`);
    socket?.on(`emojiReceive-${room}`, (data) => {
      const { emoji, user_id } = data;


      if (emojiRef[emoji]) {
        setEmojis(state => [...state, emojiRef[emoji]()]);
      }
    })
  }, [room])



  const getRandomInt = () => {
    return Math.floor((30 - 1) * Math.random())
  }

  const emojiRefAnimated = {
    happy: () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/happy.png" />,
    love: () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/love.png" />,
    smile: () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/smile.png" />,
    sad: () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/sad.png" />,
    party: () => <EmojiElem key={uniqueHash()} left={getRandomInt()} src="/emojis/party.png" />
  }


  const appendEmoji = (item) => {

    if (emojiRefAnimated[item]) {

      socket?.emit('emojiSend', { emoji: item, room });
    }
  }



  return <LessonFooter>
    {Object.keys(emojiRef).map(key => {
      return <div key={key} onClick={() => appendEmoji(key)}>{emojiRef[key]()}</div>
    })}
    <aside>
      {emojis}
    </aside>
  </LessonFooter>;
};

export default LessonEmoji;
