import React, { useState, createContext, useContext, useEffect } from 'react';
import { uuid } from 'uuidv4';
import io, { Socket } from 'socket.io-client';
import { apiUrl, apiSocket, hash } from '../config';
import { useAuth } from './Auth';

interface ISocketContextData {
  socket?: SocketIOClient.Socket;
  key: string;
}

const SocketContext = createContext<ISocketContextData>(
  {} as ISocketContextData,
);

const key = uuid();

const SocketProvider: React.FC = ({ children }) => {
  const { token, user } = useAuth();
  const [socket, setSocket] = useState<SocketIOClient.Socket>();

  useEffect(() => {
    if (token) {
      setSocket(() => io(apiSocket, {
        query: `socketkey=${user?.id}&token=${token}&key=${key}&hash=${hash}`,
        transports: ['websocket', 'polling'],
        reconnection: true
      }))

    }

  }, [token])

  return (
    <SocketContext.Provider value={{ socket, key }}>
      {children}
    </SocketContext.Provider>
  );
};

function useSocket(): ISocketContextData {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return context;
}

export { useSocket, SocketProvider };
