const template = {
    input : {
        placeholder : '#333',
        background: '#fff',
        color: '#333',
        border: '2px solid #aaa',
        colorFilled : 'rgb(0,100,50)',
        colorFocused : 'rgb(0,150,50)',
        borderColorFocused: 'rgb(0,150,50)',
    },
    button : {
        background : '#333',
        color: '#fff',
        backgroundHover: '#555',
        colorHover: '#fff',
    }
}

export default template;