import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft, FiX } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp, FaCheck, FaExclamation, FaPlus } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { hash } from '../../config';
import { loadFromJson } from '../../utils/loadFromJson';
import { useModal } from '../../hooks/Modal';
import { useLoading } from '../../hooks/LoadingHook';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
}

interface IProgramFilter {
  date: Date;
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate: string;
  day_reference?: string;
  category?: string;
  room_name?: string;
}

interface IProps {
  filterCategory?: string;
  removeCategory?: string;
}
const CourseProductsSubscriptionModule: React.FC<IProps> = ({ filterCategory = '', removeCategory = '' }) => {
  const [globalActivitiesList, setGlobalActivitiesList] = useState<Array<Record<string, any>>>([]);
  const { addLoading, removeLoading } = useLoading();
  const [currentDay, setCurrentDay] = useState(0);
  const { addModal, removeModal } = useModal();
  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate } = useLanguage();
  const { configuration } = useConfiguration();
  const { projectHash, eventHash } = useParams<Record<string, any>>();



  const load = async () => {

    addLoading({ title: 'Carregando', key: 'loading' });

    try {


      const listActivities = await api.get(`/list-activities/${projectHash}`);
      const listMyActivities = await api.get(`/list-my-activities/${projectHash}`);

      const data = listActivities.data.rows;

      const activitiesList: Array<Record<string, any>> = [];
      const myActivitiesList: Array<Record<string, any>> = [];

      listMyActivities?.data?.rows?.map(myActivity => {

        const index = listActivities?.data?.rows?.findIndex(activity => activity?._id?.toString() === myActivity?._id?.toString());

        if (index < 0) {
          myActivitiesList.push(myActivity)
        }
        else {
          myActivitiesList.push(listActivities?.data?.rows[index])
        }

      })


      if (data) {
        data.map((elem) => {
          const textDate = simpleDate(elem.date);

          const index = activitiesList?.findIndex(activityDates => activityDates?.simpleDate === textDate);

          /* check if the activity is already selected */
          const indexActivity = myActivitiesList?.findIndex(activity => activity?.course_product_id?.toString() === elem?._id?.toString());


          let alreadyHaveGroup = false;

          /* have any activity from the same group, but not the same activity */

          elem?.group?.map(groupValue => {


            const indexActivityGroup = myActivitiesList?.findIndex(activityItems => activityItems?.group?.findIndex(g => g === groupValue) >= 0);

            if (indexActivityGroup >= 0) {
              alreadyHaveGroup = true;
            }

          })


          if (indexActivity >= 0) {
            elem.currentStatus = 'selected';
          }
          else if (alreadyHaveGroup) {
            elem.currentStatus = 'blocked';
          }
          else if (elem?.status === 'no') {
            elem.currentStatus = 'ended';
          }
          else if (elem?.quantity <= elem?.quantity_used) {
            elem.currentStatus = 'full';
          }
          else {
            elem.currentStatus = 'available';
          }


          if (index < 0) {
            activitiesList.push({
              date: elem.date,
              formatted_date: dateLanguage(elem.date),
              simpleDate: textDate,
              activities: [elem]
            })
          }
          else {
            activitiesList[index].activities.push(elem)
          }



        });


        activitiesList.map((item, index) => {
          activitiesList[index].activities.sort((a, b) => {
            return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
          });
        });

        activitiesList.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;

        });

        setGlobalActivitiesList(activitiesList);
        removeLoading('loading');
      }
    }
    catch (err) {
      removeLoading('loading');
    }
  };

  useEffect(() => {


    load();
  }, [projectHash, user]);

  const confirmActivity = async (id) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.post(`/confirm-activity-participation/${id}`);

      await load();
      removeModal('confirmDecline');
      removeLoading('loading');
      addToast({ title: 'Adicionado com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }

  }

  const declineActivity = async (id) => {
    addLoading({ title: 'Carregando', key: 'loading' });
    try {

      const confirmActivityResponse = await api.put(`/decline-activity-participation/${id}`);

      await load();
      removeModal('confirmDecline');
      removeLoading('loading');
      addToast({ title: 'Desistência realizada com sucesso', type: 'success' });
    }
    catch (err) {
      removeLoading('loading');
      return addToast({ title: err.response.data.message || err.response.data.error || 'Erro ao realizar solicitação' })
    }

  }

  const askModal = (activityInfo, type) => {

    if (type === 'confirm') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Confirmar participação nesta atividade?</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>{activityInfo?.title}</p>
          <button className='buttonBigGreen' onClick={() => confirmActivity(activityInfo?._id?.toString())}>Confirmar</button>
        </div>
      })
    }
    else if (type === 'decline') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Você deseja desistir desta atividade?</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>{activityInfo?.title}</p>
          <button className='buttonBigGreen' style={{ background: 'rgb(150,0,0)' }} onClick={() => declineActivity(activityInfo?._id?.toString())}>Desistir</button>
        </div>
      })
    }
    else if (type === 'blocked') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Atividade Bloqueada</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Você só pode se inscrever em uma atividade por horário / data</p>
        </div>
      })
    }
    else if (type === 'ended') {
      addModal({
        theme: 'whiteModalMedium',
        key: 'confirmDecline', title: '', content: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
          <h2 style={{ color: '#333', fontSize: '18px' }}>Vagas esgotadas</h2>
          <p style={{ color: '#333', fontSize: '16px' }}>Selecione outra atividade</p>
        </div>
      })
    }
  }

  const checkStatus = (line) => {


    if (line?.currentStatus === 'selected') {
      return <button className='buttonBigGreenIcon  hover hoverToCancel' onClick={() => { askModal(line, 'decline') }} style={{ background: 'rgb(0,150,50)' }} title="Selecionado"><FaCheck className='show' /> <FiX title='Cancelar' className='showOnHover' />  </button>;
    }
    else if (line?.currentStatus === 'blocked') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'blocked') }} style={{ background: 'rgb(150,150,150)' }} title="Bloqueado"><FaExclamation /> </button>;

    }
    else if (line?.currentStatus === 'ended') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'ended') }} style={{ background: 'rgb(150,0,0)' }} title="Encerrado"><FiX /> </button>;

    }
    else if (line?.currentStatus === 'full') {
      return <button className='buttonBigGreenIcon' onClick={() => { askModal(line, 'ended') }} style={{ background: 'rgb(150,0,0)' }} title="Esgotado"> <FiX /></button>;

    }
    else {
      return <button className='buttonBigGreenIcon hover hoverToCheck' onClick={() => { askModal(line, 'confirm') }} style={{ background: 'rgb(0,50,150)' }} title="Adicionar"><FaPlus className='show' /> <FaCheck className='showOnHover' /> </button>;

    }




  }

  const line = program => {
    return (
      <tr style={{ cursor: 'pointer' }} onClick={() => setCurrentProgram(state => state === program.id ? '' : program.id)}>
        <td className="hourDiv" style={{ width: '60px', maxWidth: '60px', minWidth: '60px' }} >
          {checkStatus(program)}

        </td>  <td style={{ width: '100%' }}>

          <span>
            <strong style={{ color: '#1d436f' }}>
              {' '}

              {program.title}
              {' '}


            </strong>

            {program.description && <div className={"hover"}>
              <div style={{ width: '100%' }}>


                <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

              </div>


            </div>}
          </span>
        </td>

      </tr>
    );
  };

  return (
    <Container style={{ background: '#fff', margin: '0px' }}>
      <h1 style={{ color: '#1d436f', fontSize: '18px', textAlign: 'center' }}>{translate('Selecione suas atividades')} </h1>




      {globalActivitiesList.map(program => {
        return (program?.activities?.length > 0 ? <div>
          <div className="programDayHeader" >


            {program.formatted_date && <h2 style={{ background: '#1d436f', color: '#fff', marginTop: '30px', padding: '10px', marginLeft: '7px' }}>{translate(program.formatted_date)}</h2>}

          </div>
          <table>

            <tbody>{program.activities.map(items => line(items))}</tbody>
          </table>

        </div> : <></>)
      })}

    </Container>
  );
};
export default CourseProductsSubscriptionModule;
