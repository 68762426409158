import React, { useCallback, useRef, useEffect, useState } from 'react';

import api from '../../../../../../services/api';

import { Body } from '../../styles';

import { FaFileExcel, FaSpinner } from 'react-icons/fa';
import downloadit, { downloaditPost } from '../../../../../../utils/download';
import { useAuth } from '../../../../../../hooks/Auth';
import { apiUrl } from '../../../../../../config';


interface IOptions {
    text?: string;
    label?: string;
    value: string;
}

interface IReport {
    rows: Array<Record<string, any>>;
    count: number;
}



const CientificLibraryListGlobalColumns: React.FC = () => {

    const { token } = useAuth();
    const [records, setRecords] = useState<IReport>({ rows: [], count: 0 });

    const [loading, setLoading] = useState(false);
    const [column, setColumn] = useState('');
    const [year, setYear] = useState('2023');


    const callReport = async (yearValue, columnValue) => {


        setLoading(true)

        const response = await api.get(`/reporter-list-cientific-library-global/${yearValue}/${columnValue}`);

        if (response) {
            //  setGraphData(response.data);
            setRecords(response.data);

        }
        setLoading(false)

    }


    useEffect(() => {
        if (column && year) {
            callReport(year, column);
        }
    }, [column, year])




    const downloadData = (render) => {

        const elements: Array<Array<string>> = []
        elements.push(['Referência', 'QTD de usuários']);
        render.map(elementData => {


            if (elementData?.title?.trim()?.length > 0) {
                elements.push([elementData?.title, elementData?.count]);
            }

        })

        return downloaditPost({ url: `${apiUrl}/converter/xlsx`, data: elements, type: 'xlsx' });

    }

    const renderList = (recordsValue) => {

        const render = recordsValue ? { ...recordsValue } : { rows: [], count: 0 };



        const elements: Array<JSX.Element> = [];


        elements.push(<tr><td style={{ width: '30px' }}><button className='searchButtonMini' onClick={() => downloadData(records?.rows || [])}><FaFileExcel /></button></td><td><strong style={{ color: '#333' }}>Categoria</strong></td>
            <td><strong style={{ color: '#333' }}>QTD de usuários</strong> </td>

        </tr>)




        render?.rows?.map(elementData => {



            if (elementData?.title?.trim()?.length > 0) {
                elements.push(<tr> <td>{elementData?.title}</td>
                    <td>{elementData?.count}</td>


                </tr>)
            }

        })
        return elements;




    }


    return (

        <Body  >
            <button className={column === 'state' ? 'defaultButton' : 'defaultButtonReverse'} type='button' onClick={() => { setColumn('state') }}>Estado</button>
            <button className={column === 'country' ? 'defaultButton' : 'defaultButtonReverse'} type='button' onClick={() => { setColumn('country') }}>País</button>
            <button className={column === 'position' ? 'defaultButton' : 'defaultButtonReverse'} type='button' onClick={() => { setColumn('position') }}>Categoria</button>
            {loading ? <div style={{ width: '100%', minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><h2 style={{ color: '#333', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><FaSpinner style={{ marginRight: '10px' }} className='spin' size={30} />{` `} Carregando</h2></div> :
                <>

                    <table className='table' style={{ maxWidth: '600px', margin: '0px auto' }}>
                        <tbody>

                            {renderList(records)}
                        </tbody>
                    </table>
                </>
            }


            {
                //graphData && <div style={{ width: '100%', height: '50vh' }}><ChartBar indexBy="hora" headers={['usuarios']} data={graphData} /></div>
            }

        </Body>
    );
};
export default CientificLibraryListGlobalColumns;
