import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
0% {
  transform : rotate(0deg);
}
100% {
  transform : rotate(100deg)
}
`;

export const Container = styled(Link)`
  display: flex;
  flex-direction: row;

  transition: opacity 0.2s;
  font-weight: bold;
  height: 46px;
  justify-content: space-between;
  svg {
      width: 30px;
      font-size: 30px;
      min-width: 30px;
      }
  &:hover {
    opacity: 0.9;
  }

  > h3 {
    width: 100px;
  max-width: 100px;
  min-width: 100px;
  font-size: 12px;
  text-align: center;
  padding: 10px;
  color: #fff !important;
  }

  > p {
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #fff !important;

    svg {
      min-width: 30px;
      width: 30px;
      animation: ${rotateAnimation} 1s infinite;
    }
  }
`;
