import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';
import themeDefault from './ThemeComponents';


const rotate = keyframes`
from {
  transform: rotate(0deg) ;
}
to{
  transform: rotate(360deg) ;
}
`

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/Montserrat-Regular.ttf") format("truetype");
}


@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/Montserrat-Bold.ttf") format("truetype");
}



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;


}

.whiteModal { 
  p,span,h3, strong, h2, h1, h4, nav, aside {
    color: #333 !important;
  }
}

body {
//background: url('/assets/Fundo.jpg') no-repeat center;
background-size: cover ;
background-attachment: fixed;
background : #fff;

>iframe {
  position: relative !important;
  display: none ;
}

.p50.socialFooter .iconSet svg{
  
    min-width: 45px !important;
    font-size: 45px !important;
 
}
 
.typeButtonInitial2 {
      display: flex ;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: ${themeDefault.alternativeButton.color};
      background: ${themeDefault.alternativeButton.background};
      min-width: 100px;
      font-weight: bold;
      border : 0px;
      border-radius: 10px;
      margin: 5px;
      font-size: 12px;

    }


    .selectButtonMini{
      background: #f15a23 ;
      color: #fff;
      transition: 0.5s all;
      border: 0px;
      padding: 5px;
      font-size: 16px;
      border-radius: 5px;

      &:hover{
      background: rgb(0,14,64);
    }
    }

.searchButton{
    background: rgba(0,100,0);
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 30px;
      font-size: 30px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }

  .searchButtonMini{
    background: rgba(0,100,0);
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin: 5px;
    border: 0px;

    box-shadow: 0px 5px 15px rgba(0,0,0,0.3);
    transition: 0.5s all;
    border-radius: 50%;

    >svg{
      width: 20px;
      font-size: 20px;
      color: #fff;
    }

    &:hover{
      background: rgba(0,150,0);
    }
  }
 


  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;



  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

.tooltip{
  background: #777 ;
  color: #333;
}

.spin {
  animation: ${rotate} 2s infinite ;
}

.table{
  width: 100%;
  border-collapse: collapse;
  td{
    border: 1px solid #ddd;
    padding: 10px;
    color: #333;

  }
}


.searchDivStart2{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items:center;
        max-width: 1600px;
        padding: 0px 15px;
        margin: 0px auto;

        >select, input {
          min-width: 300px;
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;
          margin:  10px;
        }

        button{
          padding: 10px;
          border-radius: 15px;
          background: #fff ;
          border: 0px;

          >svg{
            font-size: 18px;
          }
        }

        @media (max-width: 750px) {
    justify-content: center;
    flex-direction: column;
    select, input {
      width: 100%;

    }

    div{
      width: 100%;
    }
    
  }
      }

.slick-slider{
  width: 100%;

 
}

.slick-prev{
  background: none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color: #00aaef;
    font-size: 40px;
    z-index:10;
    opacity: 1 ;
  }
}

.slick-next{
  background:  none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color:  #00aaef;
    font-size: 40px;
    opacity: 1 ;
  }
}

.fixedWhatsapp{
  position: fixed;
    bottom: 15px;
    right: 0px;
    border-radius: 10px 0px 0px 10px;
    background: rgb(50,150,26);
    color: #fff !important;
    padding: 15px;
    border: 0px;
}


.buttonBigGreen{

align-self: center;
  background-color: #1c9284;
  border-bottom-style: none;
  border-color: #1c9284;
  border-left-style: none;
  border-radius: 20px;
  border-right-style: none;
  border-top-style: none;
  border-width: 0px;
  color: #FFFFFF;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  line-height: 1.42857;
  margin-bottom: 20px;
  margin-top: 0px;
  max-width: 388px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 20px;

}

.buttonBigGreenIcon{

align-self: center;
background-color: #1c9284;
border-bottom-style: none;
border-color: #1c9284;
border-left-style: none;
border-radius: 10px;
border-right-style: none;
border-top-style: none;
border-width: 0px;
color: #FFFFFF;
cursor: pointer;
font-family: "Open Sans", sans-serif;
font-size: 18px;
font-style: normal;
font-weight: bold;
height: 50px;
justify-content: center;
line-height: 1.42857;

margin-top: 0px;
max-width: 388px;
text-align: center;
text-decoration: none;
width: 40px;
height: 40px;
padding-right: 10px;
padding-left: 10px;

gap: 10px;
display: flex ;
align-items: center;
justify-content: center;

>svg{
  font-size: 24px;
  min-width: 20px;
  min-height: 20px;
  color: #fff;
}

}


.CaptureLeads{
  min-width: 250px;
  background: rgb(241, 90, 35)  ;
  color: ${themeDefault.textComponent.title.color} ;
  padding: 10px 15px;
  border-radius: 5px;
  border: 0px;

  @media (max-width: 1000px) {
    display: none ;
  }

}

.divSecoundaryBackground {
  background: ${themeDefault?.textComponent?.title.color} !important;
}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.padding{
  padding: 15px;
}

.margin{
  margin: 15px;
}

.shadowBox{
  display: flex;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgb(7 13 28 / 25%) 0px 10px 100px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#root{
  min-height: 100vh;
 


}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:  'MontserratBold',Helvetica,  sans-serif;
color : ${themeDefault.textComponent.title.color};
}

h2{

      font-size: 16px;
}

p, span{
  font-family:'Montserrat',Helvetica,  sans-serif;
  color : ${themeDefault.textComponent.paragraph.color};
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}


.computerOnly{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

.fixedBottom{
    z-index: 0;
    position: fixed;
    bottom: -30px;
    right: 0px;
    width: 45%;
}

div.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);

      svg{
        cursor: pointer;
        transition: 0.4s all ;
        &:hover{
          transform: scale(1.1) ;
        }
      }
   
    }

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff ;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64) ;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 5px 5px rgba(171, 230, 255,0.7);
       
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      flex-direction: column;
      display: flex ;
      justify-content: center;
      align-items: center;
 
      color: rgb(0, 15, 64)  !important;
      font-weight: bold;

      background: rgb(171, 230, 255) !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;
    
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .tip{
      padding: 5px;
     font-size: 14px;
      width:100%;
      text-align: left;
      font-weight: bold;
   
      color: #916f00 !important;
      display: flex ;
      justify-content: flex-start;
      align-items: center;

      >svg {
        animation: ${rotate} 5s infinite ;
      }
     
  
    }

    .rotateAnimation{
      animation: ${rotate} 5s infinite ;
    }

    .simpleButton {
         margin-top: 0px;
 
  
      padding: 5px;
      text-align: center;
      background: #fff !important;
      color: #333  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #333  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    button.defaultButtonMini,     button.defaultButtonMini2, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: rgb(0,15,64) !important;
      color: ${defaultTheme.defaultColor}  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }


    button.defaultButtonMini2{
      padding: 10px 20px  !important ;
      margin-top: 25px ;
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      cursor: pointer;
      background: #f15a23 !important;
      color: #fff  !important;
      border-radius: 10px  !important;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #333;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #333  !important;
  border: 1px solid #ddd  !important;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2)  !important;

  span{
    color: #333  !important;
  }

  input {
    color: #333  !important;

    &::placeholder {
    color: #333  !important;
  }
  }
}
}



.primary {}

.secondary{}

.container{
    width: 100%;
}

.width-full{
    width: 100%;
}

.width-auto{
    width: auto !important ;
}

.min-100{
    min-width: 100px;
}


.min-200{
    min-width: 200px;
}
.min-300{
    min-width: 300px;
}
.min-400{
    min-width: 400px;
}

.min-600{
    min-width: 600px;
}
.max-160{
    max-width: 150px;
}
.max-200{
    max-width: 200px;
}
.max-300{
    max-width: 300px;
}
.max-400{
    max-width: 400px;
}

.max-800{
    max-width: 800px;
}

.max-900{
    max-width: 900px;
}


.max-1000{
    max-width: 1000px;
}

.max-1100{
    max-width: 1100px;
}

.max-1200{
    max-width: 1200px;
}

.max-1400{
    max-width: 1400px;
}

.max-80p{
    max-width: 80%;
}

.max-90p{
    max-width: 90%;
}

.max-95p{
    max-width: 95%;
}

.relative {
 
position: relative;

}


.full-width {
 
         width: 100% !important;
         max-width: 100%;
   
}

.full-width-mobile{
    @media (max-width: 750px) {
        max-width: 100% !important;
    }
}

.auto-width{
    width: auto !important ;
}

.self-center{
    align-self: center;
}

.prev-slider-button, .next-slider-button{
    
    background: rgb(0,120,170);
    color: #fff;
    width: 35px;
    height: 35px;
    font-size: 15px;
    border-radius: 50%;
    top: 120px;
  
    position: absolute;
    z-index:10000;
    display: flex ;
    align-items: center;
    justify-content: center;
    padding: 0px;

    >svg{
        color: #fff;
        font-size: 18px; 
    }
 
}

.prev-slider-button{
    left: -10px;
}

.next-slider-button{
    right: -10px;
 
}

.fixed-150{
    >div, >aside, >label, >button, >a {
        width: 150px;
        min-width: 150px;
    }
}

.fixed-200{
    >div, >aside, >label, >button, >a {
        width: 200px;
        min-width: 200px;
    }
}

.fixed-250{
    >div, >aside, >label, >button, >a {
        width: 250px;
        min-width: 250px;
    }
}

.fixed-300{
    >div, >aside, >label, >button, >a {
        width: 300px;
        min-width: 300px;
    }
}

.shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.gap-xs{
    gap: 7.5px;
}

.gap-sm{
    gap: 15px;
    @media (max-width: 750px) {
      gap: 7.5px;
    }
}

.gap-md{
    gap: 20px;
}

.gap{
    gap: 30px;


}

.gap-bg{
    gap: 45px;
}

.border {
    border: 1px solid #ccc;
}

.border-none{
    border: 0px solid #ccc;
}

.border-radius-sm{
    border-radius: 7.5px;
}

.border-radius{
    border-radius: 15px;
}

.pad-xs{
    padding: 7.5px !important;
}

.pad-sm{
    padding: 15px;
}

.pad{
    padding: 30px;
   
}

.pad-bg{
    padding: 45px;
}

.pad-x-xs{
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.pad-x-sm{
    padding-left: 15px;
    padding-right: 15px;
}

.pad-x{
    padding-left: 30px;
    padding-right: 30px;
}

.pad-x-bg{
    padding-left: 45px;
    padding-right: 45px;
}

.pad-y-xs{
    padding-top: 7.5px;
    padding-bottom: 7.5px;
}

.pad-y-sm{
    padding-top: 15px;
    padding-bottom: 15px;
}

.pad-y{
    padding-top: 30px;
    padding-bottom: 30px;
}

.pad-y-bg{
    padding-top: 45px;
    padding-bottom: 45px;
}

.height-85{
    height: 85vh;
}

.white{
    background: #fff;
}
 
.overflow-y-auto{
    overflow-y:auto ;
}


 

.container-pad{
    
 

    @media (max-width: 750px) {
        padding:  30px;
    }

}



.slick-slider{
  width: 100% ; 
  
>button{
  top: 100px !important;
}
 
}
 
.slick-slide{
    width: 100%;
}

.slick-prev{
  background: none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color: #00aaef;
    font-size: 40px;
    z-index:10;
    opacity: 1 ;
  }
}

.slick-next{
  background:  none !important ;
  width: auto !important ;
  height: auto !important ;
  border-radius: 50%;
  z-index: 1;
  &:before{
    color:  #00aaef;
    font-size: 40px;
    opacity: 1 ;
  }
}


.multipleSlider{
    .slick-track{
    width: 100% !important ;
    
}
}

.swiper-horizontal{
    width: 100%;
    margin: 0px;
}



.full-height {
    min-height: 100vh;
}
.widescreen {
    width: 100%;
    padding-bottom: 56.25%;
}
.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row-space-between{
    justify-content: space-between;
}

.column {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.row-to-column {

    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 750px) {
        flex-direction: column;
        align-items: center;
    justify-content: flex-start;
    }

}

.align-start{
    align-items: flex-start;
}
 
.flex-wrap{
    flex-wrap: wrap;
}

.stretch {
    align-self: stretch;
}

.row-start{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.row-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.column-start{
    align-items: flex-start;
    justify-content:flex-start ;
}

.column-end{
    align-items: flex-end;
    justify-content: center;
}


.row-to-column-start{
    align-items: center;
    justify-content: flex-start;
  

    @media (max-width: 750px) {
        align-items: flex-start;
    justify-content: center;
    }
}

.align-start{
    align-items: flex-start;
}
 

.row-space-between{
    display: flex ;
    align-items: center;
    justify-content: space-between;
}

.margin-right-sm{
    margin-right: 15px;
}

.overflow-hidden-height{
    overflow-y: auto ;
    max-height: 70vh;
}

.overflow-x-hidden{
    overflow-x: hidden ;
   
}


.input-xs {
    .inputContainer {
        min-height: 30px !important;
        padding: 0px;

        input{
            padding: 7.5px !important;

        }
    }
}




.icon-button {
    min-width: 46px ;
    min-height: 46px ;
    width: 20px ;
    height: 20px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    &:hover{
        svg{
            transform: scale(1.2) rotate(90deg);
        }
    }

}

.socialIcon{
  background: #fff;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex ;
  justify-content: center;
  align-items: center;
  svg{
    font-size: 24px;
    color: rgb(46, 81, 126);
  }
}

.tag {
    
   padding: 5px 10px;
   background: rgba(0,50,250,0.2);
   color: #333;
   font-size: 12px;
   border-radius: 5px;
   

}

.filter-icon-position{
    position: absolute;
    top: 7.5px ;
    right: 7.5px;
    opacity: 0.3;
    width: 10px;
    height: 10px;
    cursor: pointer ;

    &:hover{
        opacity: 1;
        color: #405cf5;
        transform: scale(1.2) ;
    }

}

.icon-button-xs {
    min-width: 30px ;
    min-height: 30px ;
    width: 30px ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    

    &:hover{ 
        svg{
            transform: scale(1.2);
        }
    }

}

.icon-button-xs-width {
    min-width: auto ;
    min-height: 30px ;
    width: auto ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;
    font-size: 14px;
    gap: 7.5px;


    p{
        font-size: 14px; 
    }

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    &:hover{
        svg{
            transform: scale(1.2) ;
        }
    }

}

.soft-button {

    background-color: #fafafa;
    color: #aaa;

    &:hover{
        background-color: #405cf5;
    color: #fff;
    }

}

.medal{
    width: 15px;
    height: 15px;
  
    padding: 0px;
    display: flex ;
    align-items: center;
    justify-content: center;
    border:0px;
    background: none;
    >svg{
        width: 15px;
        height: 15px;
    }
}

.highlightTitle{
    font-size: 16px;
    font-weight: bold;
   
    width: auto;
    align-self: flex-start;
    color: #777 !important;
    padding: 7.5px 15px;
    padding-left: 0px;
    margin-bottom: 15px;
    border-radius: 6px;

     
}

.titleColor{
    color: #3e77a2;
}

.color-button {
    background-color: #405cf5;
    color: #fff;
}

.button-xxs {
     
    min-height: 30px ;
    width: auto ;
    height: 30px ;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7.5px;
    border: 0px;

    svg{ 
        min-width: 20px;
        min-height: 20px;
    }

    

    &:hover{ 
        svg{
            transform: scale(1.2);
        }
    }

}

.rotate180{
    transform: rotate(180deg) ;
}

.checkboxIcon{
    min-width: 15px;
    min-height: 15px;
}

.text-title {
    font-size: 56px;
    font-weight: 700;
    line-height: 56px;

    @media (max-width: 750px) {
        font-size:  32px;
 
    line-height:  32px;
    }
   
}

.text-subtitle {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: 750px) {
        font-size:  28px;
 
    line-height:  28px;
    }
}

.text-paragraph {
    font-size: 18px;
    line-height: 1.5;
}

.text-center{
    text-align: center;
}

.text-left{
    text-align: left;
}

.text-right{
    text-align: right;
}

.text-justify{
    text-align: justify;
}





.inputContainer{
position: relative ;

    .inputPlaceholder{
     position: absolute ;
     top: -8px;
     background: #fff ;
     font-size: 11px;
     margin: 0px 15px;
 }
}

.filterButton {
    font-size: 12px;
    border: 0px;
}



.dark-theme{
    background: #333;

    p,h2,h1{
        color: #fff;
    }

    input {
        background: none ;
        color: #fff;
        &::placeholder {
        color: #ddd;
      
    }
    }

    tr{
        background: #333 ;
        color: #fff;

        &:hover{
            background: #444 ;  
        }
    }
    .table-header{
        background: #555;
         color: #fff;
         th{
            background: #555;
         }
        
    }
}

.thumb{
  background-position: top center !important;
  background-size: cover !important;
}

.pointer{
  cursor: pointer ;
}

.grow{
  transition: 0.5s all;
  &:hover{
  transform: scale(1.1) ;
  }
}


.greenButton2{
    padding: 10px 20px;
    background: #0088c4;
    color: #fff;
    border: 0px;
    border-radius: 10px;
    margin: 10px 0px;
    transition: 0.4s all;
    font-weight: bold;

    display: flex !important ;
    gap: 5px  !important;
    justify-content: center  !important;
    align-items: center  !important;
    flex-direction: row  !important;

    &:hover{
      transform: scale(1.15) ;
    }
  }


  .white{
    color: #fff !important;
  }

  .space-between { justify-content:  space-between;}
`;
