import React from 'react';
import { Background, Loader } from "./styles"

const LoaderComponent = ({ title = '', content = <></> }) => {


    return <Background>
        <div>
            <img src="/assets/logo.png" />
        </div>
        <h2>{title}</h2>
    </Background>



}

export default LoaderComponent;