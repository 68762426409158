import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IconType } from 'react-icons';

import { MdChat } from 'react-icons/md';
import { AiOutlineLike } from 'react-icons/ai';
import { FiMail, FiPhone, FiArrowLeft, FiVideo } from 'react-icons/fi';
import { FaVideo } from 'react-icons/fa';
import { useAuth } from '../../hooks/Auth';

import {
  Feira,
  Expositor,
  Content,
  ImageBackground,
  Container,
  ChatIcon,
  Title,
} from './styles';

import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';
import Youtube from '../../components/Youtube';
import Vimeo from '../../components/Vimeo';
import { useToast } from '../../hooks/Toast';
import ImageGallery from '../../components/ImageGallery';

interface IPhotos {
  url: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  description: string;
  video_id: string;
  video_type: number;
  link_name: string;
  social: Record<string, any>;
  image_gallery: Array<IPhotos>;
  category?: number;
  extra_text?: string;
}

interface IProps {
  link_name : string;
}

const ExpositorPage: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { link_name } = useParams<IProps>();

  const [expositors, setExpositors] = useState<IExpositor>({} as IExpositor);
  const [vote, setVote] = useState(false);
  useEffect(() => {
    const load = async () => {
      const expositores = await api.get(`/expositors-list-one/${link_name}`);

      setExpositors(expositores.data);
    };

    load();
  }, []);

  const startChat = () => {
    console.log('hi');
  };

  return (
    <>
      <Link
        style={{
          position: 'fixed',
          bottom: '5px',
          left: '5px',
          cursor: 'pointer',
          padding: '10px',
          zIndex: 200,
          background: '#fff',
          color: '#333',
          borderRadius: '10px',
          textDecoration: 'none',
        }}
        to="/#galeria_fotos"
        onClick={e => {
          e.preventDefault();
          window.location.href = `${urlWebsite}#${'galeria_fotos'}`;
        }}
      >
        <FiArrowLeft />
{' '}
Voltar
</Link>{' '}
      {expositors && expositors.title && (
        <>
          <ImageBackground
            style={{ flexDirection: 'column' }}
            background={expositors?.background}
          >
            {expositors.image_gallery && (
              <Container
                style={{
                  paddingTop: '50px',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <h2>{expositors.title}</h2>
                <div className="separator" style={{ width: '80%' }} />
                <ImageGallery data={expositors.image_gallery || []} />
              </Container>
            )}
          </ImageBackground>
        </>
      )}
    </>
  );
};
export default ExpositorPage;
