import { Form } from '@unform/web';
import React, { useState } from 'react';
import { FaBell, FaCertificate, FaIdBadge, FaReceipt, FaTicketAlt, FaTrophy } from 'react-icons/fa';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';

import { Container, ContainerWhite } from './styles';
 
 

const UserHome: React.FC = () => {

  const {user} = useAuth();

  const [userInfo,setUserInfo] = useState<Record<string,any>>({});

  return (
    <>
      <Container >
        <div className='p800'>
        <div className='user-header'>
        <h2>
          Área do Participante
        </h2>
        <div />
        </div>
        <div className='user-block shadow'>
          <div className='img-block'>
            <img src="/apoio/user.jpg"/>
            <h2>{user.name}</h2>
            <p>{user.position}</p>
            <p>{userInfo.category || 'Participante'}</p>
          </div>
          <div className='content-block'>
              <div>
                <p>Dados do participante</p>
                <div>
                    <p>Empresa: <strong>Amazônia Brasileira Transportes SA.</strong></p>
                    <p>Profissão: <strong>{user.position}</strong></p>
                    <p>Especialidade: <strong>{user.especialidade}</strong></p>
                </div>
               </div>
               <div>
                <p>Minhas conquistas</p>
                <div>
                    <p>Comentários: <strong>{userInfo.comments || '0'}</strong></p>
                    <p>Certificados: <strong>{userInfo.certificates || '0'}</strong></p>
                    <p>Troféus: <strong>{userInfo.trophys || '0'}</strong></p>
                </div>
               </div>

               <div>
                <p>Configurações</p>
                <div>
                    <p>Receber notificações: <InOut name="notifications" setCallback={(value) => console.log(value)} placeholder='' value={userInfo.notifications || 'no'} /></p>
                    <p>Chats Privados: <InOut name="notifications" setCallback={(value) => console.log(value)} placeholder='' value={userInfo.notifications || 'no'} /></p>
                    <p>Receber emails: <InOut name="notifications" setCallback={(value) => console.log(value)} placeholder='' value={userInfo.notifications || 'no'} /></p>
                </div>
               </div>
            
          </div>
        </div>
        <div className='user-block shadow'>
        <div className='content-block'>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                <p style={{margin: '10px', display:'flex', alignItems: 'center', justifyContent:'flex-start'}}><FaBell size={15} style={{marginRight:'5px'}}/> Notificações</p>
                <p style={{margin: '10px', display:'flex', alignItems: 'center', justifyContent:'flex-start'}}><FaTicketAlt size={15} style={{marginRight:'5px'}}/> Inscrições</p>
                <p style={{margin: '10px', display:'flex', alignItems: 'center', justifyContent:'flex-start'}}><FaCertificate size={15} style={{marginRight:'5px'}}/> Certificados</p>
                <p style={{margin: '10px', display:'flex', alignItems: 'center', justifyContent:'flex-start'}}><FaTrophy size={15} style={{marginRight:'5px'}}/> Troféus</p>
        
                </div>
                </div>

  
          </div>
          <div className='user-block shadow' style={{overflow:'hidden'}}>
          <div className='content-block'>
                  <table>
                    <tr><th>Data</th><th>Título</th><th>Mensagem</th></tr>
                    <tr><td>22 de abril de 2021 - 20h42 </td><td>Certificado liberado</td><td>O certificado de participação no evento foi liberado para você, acesse sua aba de certificados e visualize.</td></tr>
                  </table>
                  </div>    
                  </div>


        </div>
      </Container>
    </>
  );
};
export default UserHome;
