import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { supportNumber, authTitle, logo, urlWebsite } from '../../config';
import { Container, Content, AnimatedDiv2, ErrorContainer } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';
import { useLanguage } from '../../hooks/Language';
import Loader from '../../components/Loader';
import { FaWhatsapp } from 'react-icons/fa';
import Logo from '../../components/Logo';
import { useConfiguration } from '../../hooks/Configuration';
import api from '../../services/api';



interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams {
  redirect?: string;
}

interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}

const AccessContentByURL: React.FC = () => {

  const { signIn, getTrackingIntegration } = useAuth();


  const { translate } = useLanguage();

  const { projectHash, eventHash, saleHash, userHash, contentHash } = useParams<Record<string, any>>();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [found, setFound] = useState(false);
  const [errorInfo, setErrorInfo] = useState(false);

  const { configuration, setConfiguration } = useConfiguration();

  const checkUserContent = async () => {
    setLoading(true);
    try {
      const checkUser = await api.get(`/check-content-access/${projectHash}/${userHash}`);

      if (checkUser && checkUser?.data?.email) {
        await signIn({ email: checkUser?.data?.email });

        setFound(true);
        setLoading(false);

        setTimeout(() => {
          window.location.href = contentHash ? `/app/${projectHash}/dashboard/${contentHash}` : `/app/${projectHash}/dashboard`;

        }, 3000);
      }
      else {
        setNotFound(true);
        setLoading(false);
      }
    }
    catch (err) {
      setLoading(false);
      setErrorInfo(true);
    }

  }

  useEffect(() => {

    if (projectHash && userHash) {
      checkUserContent();
    }

  }, [projectHash, userHash])


  return (<>

    <Container>
      <Content>
        <AnimatedDiv2 className='shadowBox'>

          {loading ? <><h2>Seja bem-vindo(a)</h2>
            <p>Estamos preparando seu acesso</p>

          </> : <></>}

          {notFound ? <><h2>Prezado visitante</h2>
            <p>Não identificamos seu cadastro, clique no botão abaixo para acessar a página do evento</p>
            <Link to={`/app/${projectHash}`}><button className='defaultButton'>Acessar</button></Link>

          </> : <></>}

          {errorInfo ? <><h2>Prezado visitante</h2>
            <p>Identificamos um erro em seu processo de autenticação</p>
            <p>Clique no botão abaixo para acessar a página do evento</p>
            <Link to={`/app/${projectHash}`}><button className='defaultButton'>Acessar</button></Link>

          </> : <></>}

          {found ? <><h2>Olá, {user?.name}</h2>
            <br />
            <strong>Seja bem-vindo! </strong><p>Estamos felizes com o seu acesso.</p>
            <br />
            <p>Você está sendo redirecionado.</p>
            <br />
            <p style={{ fontSize: '11px' }}>Caso não seja redirecionado, clique no botão abaixo:</p>
            <Link style={{ margin: '0px' }} to={contentHash ? `/app/${projectHash}/dashboard/${contentHash}` : `/app/${projectHash}/dashboard`}><button className='defaultButton'>Acessar</button></Link>
          </> : <></>}




        </AnimatedDiv2>
      </Content>
    </Container></>
  );
};
export default AccessContentByURL;
