import styled, { keyframes } from 'styled-components';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const fadeFromBottom = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Fixed = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2100;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.7);
  padding-top: 100px;
  overflow: auto;

  > div.imageContent {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: auto;
      max-width: 100%;
      max-height: 70vh;
      animation: ${fadeFromLeft} 0.5s;
    }
  }
  > div.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    max-width: 600px;

    > button {
      margin: 10px;
      color: #fff;
      border: 2px solid #fff;
      background: none;
      border-radius: 4px;
      padding: 10px;
      z-index: 2300;
    }
  }
`;

interface IBackground {
  background: string;
}

export const PhotoContainer = styled.div<IBackground>`


width: 350px;
    margin: 5px;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s all;
      position: relative;
      flex-direction: column;
      padding: 20px;

      cursor: pointer;


        @media (max-width: 750px) {
          width: auto;
          padding: 0;
        }


      > div {
        background: url(${props => props.background}) no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
        width: 300px;
        height: 200px;
        min-height:200px;
        min-width: 300px;
        max-width: 300px;
        max-height: 200px;
        border-radius: 10px;

        @media (max-width: 750px) {
          width: 150px;
        height: 100px;
        min-height:100px;
        min-width: 150px;
        }
        overflow: hidden;

        transition: 0.2s all;
        animation :${fadeFromBottom} 0.9s;
display: flex;
align-items: center;
justify-content: center;

        > img {

          width: auto;
          min-height: 100%;
          max-height: 100%;
        }

        >img.mt30 {
          margin-top: -30px;
        }
      }

      &:hover div {

        box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
        transform: scale(1.1);
      }





      >h2 {
        font-size: 1.5rem !important;
        margin-top: 10px;
        margin-bottom: 0px !important;
        padding: 0px;
        transition: 0.5s all;
        transition-delay: 0.1s;
        animation ${fadeFromBottom} 1s;
        text-align: center !important;

        @media (max-width: 750px) {
          font-size: 1.5rem !important;
      }
      }

      > p {
        transition: 0.5s all;
        transition-delay: 0.2s;
        font-size: 16px  !important;
        text-align: center !important;
        padding: 0px 10px;
        animation ${fadeFromBottom} 1.4s;
        font-weight: 400 !important;
        text-align: center;
      }`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0;

  animation: ${fadeFromBottom} 1s;

  button.tag {
    background: #fff;
    color: #333;
    border: none;
    transition: opacity 0.4s;
    padding: 10px;
    border-radius: 4px;

    &:hover {
      opacity: 0.9;
    }
  }

  > .singleCard {
    display: flex;
    width: 100% !important;
    padding: 20px;
    flex-direction: row;
    align-items: center;
    max-width: 900px;
    justify-content: space-between;
    > div {
      margin: 20px;
      min-width: 200px;

      width: 150px;
      height: 150px;
      min-height: 150px;
      min-width: 150px;
      max-width: 150px;
      max-height: 150px;
    }

    > aside {
      max-width: 600px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > h2 {
        margin-top: 0;
        padding: 0;
        font-size: 24px;

        @media (max-width: 750px) {
          text-align: left !important;
        }
      }

      > p {
        @media (max-width: 750px) {
          text-align: left !important;
        }
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      > div {
        margin: 10px;
      }
    }
  }
`;
