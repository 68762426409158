import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  padding-top: 5px;
  padding-right: 30px;
  overflow: hidden;
  z-index: 300000000000;
`;
