import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import { supportNumber, authTitle, logo, urlWebsite, defaultProjectHash } from '../../config';
import { Container, Content, AnimatedDiv2, ErrorContainer } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';
import { useLanguage } from '../../hooks/Language';
import Loader from '../../components/Loader';
import { FaLock, FaWhatsapp } from 'react-icons/fa';
import Logo from '../../components/Logo';
import { useConfiguration } from '../../hooks/Configuration';
import SignUp from '../SignUp';
import { Title } from '../Expositor/styles';
import { TitleUI } from '../../components/UIComponents/Title/styles';
import Paragraph from '../../components/UIComponents/Paragraph';
import Axios from 'axios';
import api from '../../services/api';
import { useLoading } from '../../hooks/LoadingHook';
import { useModal } from '../../hooks/Modal';
import MaskedInput from '../../components/Forms/MaskedInput';



interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams {
  redirect?: string;
  setCallback?: Function;
}

interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}

const SignInNaoAssociado: React.FC<IParams> = ({ setCallback = () => { }, redirect = '/' }) => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(true);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { translate } = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Record<string, string>>({});
  const [manutencao, setManutencao] = useState(false);
  const [showSubscription, setShowSubscription] = useState(false);
  const { projectHash = defaultProjectHash, eventHash } = useParams<IParamsURL>();


  const { configuration, setConfiguration } = useConfiguration();

  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();

  const handleSubmit = useCallback(
    async (data: SignInData) => {


      addLoading({ title: 'Validando seu acesso', key: 'loading' })

      // Verificando se é associado
      const dataInfo = {
        login: data?.email,
        password: data?.password,
      };

      try {
        const checkAssociated = await api.post('signin-integration/sbcbm', dataInfo);



      }
      catch (err) {



      }

      try {
        formRef.current?.setErrors({});

        const shape = withPassword
          ? {
            email: Yup.string()
              .required(`${translate('CPF')} ${translate('obrigatório')}`),
            password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
          }
          : {
            email: Yup.string()
              .required(`${translate('CPF')} ${translate('obrigatório')}`)

          };

        const schema = Yup.object().shape(shape);
        const { email: email_trim } = data;
        data.email = email_trim.toLowerCase().trim();

        await schema.validate(data, { abortEarly: false });

        let { email, name, password } = data;

        email = email?.trim().toLowerCase();

        await signIn({ email, password, name, product_id: configuration?.current_event_id_response?.product_id, project_id: projectHash, event_id: eventHash ? eventHash : '' });
        removeLoading('loading');
        window?.location?.reload();

        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          removeLoading('loading');
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        removeLoading('loading');

        const { status } = err?.response;

        if (status >= 500) {

          setErrorMessage({ title: 'Um momento...', description: 'Estamos com alta demanda de pedidos de acesso. <br/><br/>Por favor refaça seu login em 1 minuto para acessar.' })
          setTimeout(() => {
            setErrorMessage({});
          }, 60000)

        }
        else {

          setErrorMessage({ title: '', description: 'Olá! Não localizamos o CPF informado em nossa base de dados. Por favor certifique-se de que está correto ou inscreva-se!' })
          setTimeout(() => {
            setErrorMessage({});
          }, 5000)

        }




      }
    },
    [signIn, addToast, history],
  );

  if (showSubscription) {
    return <SignUp />
  }


  return (<>
    {loading && <Loader message="Autenticando seu acesso ..." />}
    <Container className='gap-sm pad row-to-column'>



      <Content>
        <AnimatedDiv2 className='shadowBox'>

          <Form className="form" ref={formRef} onSubmit={handleSubmit}>

            <button type='button' onClick={() => setShowSubscription(true)} className='greenButton2 buttonColored' style={{ width: '100%', maxWidth: '340px', minHeight: '50px', padding: '10px' }}


            >
              {translate('Novo Cadastro')}
            </button>

            <p style={{ width: '100%', background: '#333', height: '2px', position: 'relative', margin: '20px 0px', display: 'inline-block', }}>
              <p className='white' style={{ position: 'absolute', color: '#fff', left: '50%', transform: 'translateX(-50%) translateY(-20px)', fontSize: '12px', background: '#333', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {translate('OU')}
              </p>
            </p>
            <h2 style={{ margin: '20px auto' }} className="titleElement">{translate('FAÇA SEU LOGIN')}</h2>

            <MaskedInput mask='999.999.999-99' name="email" placeholder={translate('CPF')} />
            {withPassword && <Input

              name="password"
              type="password"
              placeholder={translate('Senha')}
            />}

            {errorMessage && (errorMessage.title || errorMessage.description) && <ErrorContainer>
              <h2 style={{ color: '#fff' }}>{errorMessage.title}</h2>
              <p style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: errorMessage.description }} />
            </ErrorContainer>}
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <button className='defaultButton' style={{ margin: '0px auto', marginTop: '40px' }} type="submit">{translate('Entrar')}</button>
            </aside>



          </Form>
          <aside style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

            {withPassword && <>
              <a style={{ color: '#333' }} href={`/recuperar_senha`}><Button>{translate('Esqueci minha senha')}</Button>

              </a></>}

          </aside>
          <p onClick={() => setCallback()} style={{ color: '#333', cursor: 'pointer', width: '100%', textAlign: 'center', padding: '15px' }}>Voltar</p>

        </AnimatedDiv2>
      </Content>
    </Container></>
  );
};
export default SignInNaoAssociado;
