import styled, { keyframes } from 'styled-components';
const emojiUp = keyframes`
0% {
  opacity: 0;
  transform: scale(0.1) translateY(0);
  filter: blur(5px);

}
10%{
  transform: scale(1.5) translateY(-60);
  filter: blur(0px);
}
50%{
  transform: scale(1.2) translateY(-120px);
  opacity: 1;
}
80%{
  transform: scale(0.5) translateY(-180px);
  opacity: 0;
  display: flex;
}
100%{
  transform: scale(0) translateY(0);
  display: none;
}
`;

export const LessonFooter = styled.div`
  width: auto;
  margin: 0px auto;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  z-index: 100;
  position: relative;

  >div{
    background: rgba(0,0,0,0.3);
    margin: 5px;
    padding: 5px;
    border: 0px solid #000;
    border-radius: 50%;
    cursor: pointer;
    >img{
      width: 30px;

    }
  }

  >aside {
    position: fixed;
    right: 10%;
  
    bottom: 0px;
    width: 100%;
    max-width: 270px;
    height: 1px;
    background: none;
    z-index: 100;
    flex-direction: row-reverse;

>img{
  width: 30px;
  animation : ${emojiUp} 3s forwards;

}

  }

`;

interface IImg {
  left: number;
}
export const EmojiElem = styled.img<IImg>`

  width: 30px;
  animation : ${emojiUp} 3s forwards;
  position: absolute;
  bottom: 0px;
  left : ${props => props.left}%;
`