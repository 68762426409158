import React, { useState, useEffect } from 'react';
import { IconType } from 'react-icons';
import { FiLoader } from 'react-icons/fi';
import api from '../../../../../services/api';
import { Container } from './styles';

interface ICounterCard {
  endpoint: string;
  Icon: IconType;
  title: string;
  value?: number;
  setValue?: boolean;
  onClick?: Function;
  link: string;
}

const CounterCard: React.FC<ICounterCard> = ({
  endpoint,
  Icon,
  title,
  value = 0,
  setValue = false,
  onClick = () => { },
  link
}) => {
  const [counter, setCounter] = useState(value);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async (): Promise<void> => {
      const response = await api.get(endpoint);
      if (response.data && response.data.counter) {
        setCounter(response.data.counter);
        setLoading(false);
      }
    };
    if (setValue === false) {
      load();
    } else {
      setCounter(value);
      setLoading(false);
    }
  }, [endpoint, value, setValue]);

  return (
    <Container to={link}>
      {Icon({})}
      <h3>{title}</h3>
      <p>{loading ? '0' : counter}</p>
    </Container>
  );
};
export default CounterCard;
