import React from 'react';
import { useLanguage } from "../../../hooks/Language";
import { Children } from "react";
import { TitleUI } from "./styles";

interface IProps {
    children?: React.ReactNode;
    style?: Record<string, any>;
    theme?: 'dark' | 'light';
    className?: string;
}

const Title: React.FC<IProps> = ({ children, style, theme, className }) => {
    const { translate } = useLanguage();

    return <TitleUI themeValue={theme} className={className} style={style}>{translate(children)}</TitleUI>;
}

export default Title;