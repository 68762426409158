import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

const fade = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotate = keyframes`
from {

  transform: rotate(0deg);
}
to{
 
  transform:rotate(180deg);
}
`;

const rotateBack = keyframes`
from {

  transform: rotate(180deg);
}
to{
 
  transform:rotate(360deg);
}
`;

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: block;
  background: #fff !important;
  color: #fff;
  padding: 40px 10px;
  width: 100%;
  max-width: 1000px;

 // border: 2px solid rgb(0,15,64);
  margin: 40px auto;
  background: rgba(255,255,255,1);
  padding: 30px 20px;

  @media (max-width: 750px) {
    padding: 30px 0px;
  }

  .hover {
    >svg{
     animation: ${fade} 0.5s forwards;
    }
  }

  .hoverToCheck{
    transition: 0.5s all ;
    .showOnHover{
        display: none;
      }
    &:hover{
      background: rgb(0,150,100) !important;
      .showOnHover{
        display: flex;
      }
      .show{
        display: none ;
      }
    }


  }

  .hoverToCancel{
    transition: 0.5s all ;
    .showOnHover{
        display: none;
      }
    &:hover{
      background: rgb(150,0,0) !important;
      .showOnHover{
        display: flex;
      }
      .show{
        display: none ;
      }
    }


  }

  .rotateSVGBack {
    >svg{
     animation: ${rotateBack} 0.5s forwards;
    }
  }

  .rotateSVG{
    animation: ${rotate} 0.5s ease-in forwards;
  }

  > h2, >h1 {
    display: flex;
    justify-content: center;
  }

  table {
    width: 100%;
  }

  tr{
    transition: 0.5s all;
    background: #fff;


    p, h2, h3, strong, span{
      text-align: left !important;
      display: block;
    margin: 0px;
    padding: 0px;
    font-size: 14px !important;
    }

    strong{
      font-size: 16px !important;
    }

    .hourDiv{
      max-width: 140px;
      min-width: 140px;
      @media (max-width: 750px) {
        max-width:65px;
        min-width: 65px;
  }

    }

    .optionDiv{
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        flex-direction: column;
  }
    }

    &:hover{
      
 
      
    }

    >td {
      padding: 15px 10px !important;
      border-bottom: 1px solid #1d436f !important;
    }
  }

  td { 
    padding: 10px 5px;
    color: #333;
  }

  .miniPrograma {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    margin-top: 5px;

    color: #333 !important;

    p,strong,span{
      color: #333 !important;
      font-size: 16px !important;
    }

    strong{
      color : rgb(241, 102, 33);
     
    }
    > figure {
      max-width: 100%;
      margin: 5px 0;
      width: 100%;
      > table {
        width: 100%;
        border-collapse: collapse;
        max-width: 100%;
        > tbody {
          max-width: 100%;
          > tr {
            border: 1px solid #ddd;
            padding: 5px 10px;
            > td {
              font-size: 10px;
              font-weight: 400;
              padding: 3px;
              min-width: 50px;
            }
            > td + td {
              
              width: 100%;
            }
          }
        }
      }
    }
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }


  }

  @media (max-width: 750px) {
    padding: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;

    > tbody {
      > tr {
        border-top: 1px solid #555;
        padding: 5px 10px;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;
