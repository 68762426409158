import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, ContainerWhite, Evento } from './styles';

import { SpeakersContainer } from '../../components/SpeakersGrid';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import CientificLibraryComponent from '../../components/CientificLibraryAuthors';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  category_id : number;
  id: number;
  category_response ?: Record<any,string>;
  social ?: Record<any,string>;

}

interface ISpeakers {
  title : string;
  position: string;
  order_type : string;
  speakers : Array<ISpeakerData>;
}
interface IParamsURL{
  projectHash ?: string;
  eventHash ?: string;
}
const CientificLibraryAuthor: React.FC = () => {
  
return <CientificLibraryComponent/>;

}

export default CientificLibraryAuthor;
