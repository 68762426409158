import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import { Link } from 'react-router-dom';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const SelecionarData: React.FC = () => {

  return (
    <>

 
      <Container >
       <div style={{padding: '50px 15px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', width: '100%', maxWidth:'350px', background: '#fff', borderRadius: '15px'}} className="shadow">
    <h2 style={{color: 'rgb(0,14,64)', fontSize: '22px', width: '100%', textAlign:'center'}}>Selecione a data</h2>

    <Link to="/app/simposio-internacional-cirurgia-robotica-dasa/inscricao/62fcd11364a3b7c1bd636d85">
      <button className='defaultButtonReverse'> 15 de Setembro</button>
    </Link>
    <Link to="/app/simposio-internacional-cirurgia-robotica-dasa/inscricao/62fff14bd16a1b79bd3c96ad">
      <button className='defaultButtonReverse'> 16 de Setembro</button>
    </Link>
    <Link to="/app/simposio-internacional-cirurgia-robotica-dasa/inscricao/62fff167d16a1b79bd3c96b8">
      <button className='defaultButtonReverse'> 17 de Setembro</button>
    </Link>
    </div>
      </Container>
    </>
  );
};
export default SelecionarData;
