import styled, { css } from 'styled-components';

interface IContainer {
  type: 'error' | 'success' | 'info' | 'clean';
}

export const Container = styled.div<IContainer>`
  width: 100%;
  min-height: 30vh;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 40px;
  flex-direction: column;
  margin-top: 10px;

  color: #fff;
  background: #222;
  text-align: center;

  background: #fff;
    padding: 0px;
    border-radius: 10px;
  
    display: flex;
    flex-direction: column;
    color: #333;
    align-items: center;

  a {
    color: white;

  }

  p {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
    padding: 10px;

    a {
      width: 100%;
      text-align: center;
    }
  }



  ${props =>
    props.type === 'success' &&
    css`
      h3,
      svg {
        color: rgb(0, 200, 0);
      }
    `}

    ${props =>
    props.type === 'error' &&
    css`
        h3,
        svg {
          color: rgb(200, 00, 0);
        }
      `}

    ${props =>
    props.type === 'info' &&
    css`
        h3,
        svg {
          color: rgb(0, 100, 200);
        }
      `}

    ${props =>
    props.type === 'clean' &&
    css`
        background: none;
        color: white;
      `}

      h3{
        font-size: 24px;
        font-weight: 500;
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    font-size: 64px;
    margin: 10px;
  }

  > h2 {
    font-size: 32px;
    font-weight: 500;
  }
`;
