import React, { FC, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Forms/Button';
import Loader from '../../../../components/Loader';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../../components/ResponseMessage';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Box, Error } from './styles';
import SelectSimple from '../../../DashboardPages/core/components/Forms/SelectSimple';
import Whatsapp from '../../../../components/WhatsAppCompartilhar';

import api from '../../../../services/api';
import { recaptchaKey } from '../../../../config';
import getCep from '../../../../services/cep';
import TextareaInput from '../../../../components/Forms/TextareaInput';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';
import StarInput from '../../../DashboardPages/core/components/Forms/StarInput';
import { FaChevronCircleLeft, FaLock, FaSpinner } from 'react-icons/fa';
import RangeInput from '../../../DashboardPages/core/components/Forms/RangeInput';
import { info } from 'console';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import EmojiInput from '../../core/components/Forms/EmojiInput';
import { Container } from '../../../Footer/styles';
import SignInReload from '../../../SignInReload';
import { useConfiguration } from '../../../../hooks/Configuration';
import { useLanguage } from '../../../../hooks/Language';
interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}




/*

Bloco de perguntas [
  {
  title : '',
  description : '',
  questions : [{
    title : '',
    description : '',
    type : '',
    image : '',

  }]
  ]
}

*/

interface IOptions {
  id: string;

  label: string;
  value: string;
}

interface IQuestion {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  options?: Array<string>;
  mandatory?: string;
}

interface IQuestions {
  title?: string;
  description?: string;
  questions: Array<IQuestion>
}

interface IPropsQuestion {
  questionsList: Array<IQuestions>;
  information: Record<string, any>;
  updateFunction: Function;
}

interface IContent {
  title: string;
  description: string;
  thanks_title: string;
  thanks_description: string;
  questions: Array<IQuestions>;
  image?: string;
  status?: number;
}

interface IProps {

  survey_id: string;
  handle?: Function;
  content: IContent

}


const Questions: React.FC<IPropsQuestion> = ({ information, questionsList, updateFunction }) => {
  const { addToast } = useToast();
  const [position, setPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const { translate, language } = useLanguage();
  let index = 0;
  let quest = 0;



  const limit = questionsList.length + 1;

  const itemChange = () => {
    addToast({ title: translate('Adicionado'), type: 'success' })
    setTimeout(() => {
      setPosition(state => state + 1);
    }, 1000)
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 400)
  }, [position])



  const mandatory = (items) => {
    return items.mandatory !== 'yes' ? <aside style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>

      <button className='defaultButtonMini2' type='button' style={{ cursor: 'pointer' }} onClick={() => setPosition(position + 1)}>{translate('PULAR')}</button>
    </aside> : <></>
  }

  return loading ? <aside style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}> <FaSpinner className='rotateAnimation' size={30} style={{ color: '#777', minWidth: '30px', margin: '50px auto' }} /> </aside> : <>
    <aside style={{ display: position === 0 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}><br /><br />

      <br /><br /><h2 style={{ width: '100%', textAlign: 'center', fontSize: '24px', color: "#333", textTransform: 'uppercase', marginBottom: '15px' }} dangerouslySetInnerHTML={{ __html: information?.[`title_${language}`] ? information?.[`title_${language}`] : information.title }} />

      <p style={{ margin: '10px auto', width: '100%', maxWidth: '400px', textAlign: 'center', color: "#333" }} dangerouslySetInnerHTML={{ __html: information?.[`description_${language}`] ? information?.[`description_${language}`] : information?.[`description`] }} />
      <br />
      <button className='defaultButtonMini2' style={{ minWidth: '150px', padding: '20px', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', margin: '10px auto' }} type='button' onClick={() => setPosition(1)}>{translate('Iniciar')}</button>

    </aside>

    {questionsList.map((question, indexQuestion) => {
      quest = quest + 1;


      return <aside key={`question${indexQuestion}`} className="fade" style={{ boxShadow: '0px 0px 0px #fff', border: '0px', display: position === quest ? 'flex' : 'none', flexDirection: 'column', margin: '20px auto', maxWidth: '650px' }}>

        <p style={{ color: '#ccc', textAlign: 'center', width: '100%' }}>{position} / {limit - 1}</p>

        {question.title ? <h2 style={{ fontSize: '18px', textAlign: 'center', width: '100%', padding: '15px' }} dangerouslySetInnerHTML={{ __html: question?.questions?.[0]?.[`title_${language}`] ? question?.questions?.[0]?.[`title_${language}`] : question?.questions?.[0]?.title }} /> : <></>}
        {question.description ? <p style={{ fontSize: '14px', padding: '15px', width: '100%', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: question?.questions?.[0]?.[`description_${language}`] ? question?.questions?.[0]?.[`description_${language}`] : question?.questions?.[0]?.description }} /> : <></>}

        {question.questions.map((items, itemsIndex) => {

          index++;
          if (items.type === 'emoji') {
            return <><EmojiInput
              setCallback={(value) => {
                setPosition(position + 1);

                updateFunction(state => { state[`p${indexQuestion + 1}`] = value; return state });

              }} key={`p${index}`} image={items.image} name={`p${index}`} placeholder={''} />{mandatory(items)}</>
          }

          if (items.type === 'star') {
            return <><StarInput setCallback={(value) => {
              setPosition(position + 1);
              updateFunction(state => { state[`p${indexQuestion + 1}`] = value; return state });

            }} key={`p${index}`} image={items.image} name={`p${index}`} placeholder={''} />{mandatory(items)}</>
          }
          if (items.type === 'range') {
            return <><RangeInput setCallback={(value) => {
              setPosition(position + 1);
              updateFunction(state => { state[`p${indexQuestion + 1}`] = value; return state });

            }} key={`p${index}`} image={items.image} name={`p${index}`} placeholder={''} />{mandatory(items)}</>
          }

          if (items.type === 'input') {
            return <><Input key={`p${index}`} name={`p${index}`} setCallback={(value) => {

              updateFunction(state => { state[`p${indexQuestion + 1}`] = value; return state });

            }} /><br /><br /> <button type="button" className='defaultButtonMini2' style={{ width: '100%', maxWidth: '150px', margin: '10px auto', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', padding: '10px' }} onClick={() => {
              setPosition(position + 1);


            }}>Próximo</button>{mandatory(items)}</>
          }
          if (items.type === 'textarea') {
            return <><aside style={{ border: '2px solid #ddd', width: '100%', borderRadius: '15px' }}><TextareaInput setCallback={(value) => {

              updateFunction(state => { state[`p${indexQuestion + 1}`] = value; return state });

            }} key={`p${index}`} name={`p${index}`} /></aside><br /><br /> <button type="button" className='defaultButtonMini2' style={{ width: '100%', maxWidth: '150px', margin: '10px auto', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', padding: '10px' }} onClick={() => setPosition(position + 1)}>{translate('Próximo')}</button>{mandatory(items)}</>
          }
          if (items.type === 'select') {

            const data: Array<IOptions> = [];

            const options: Array<string> = items.options ? [...items.options] : [];

            options.map((opt) => {
              data.push({ label: opt, value: opt, id: opt });
            })

            return <><CheckboxInput unique setCallBack={() => { itemChange() }} placeholder='' key={`questionItem${itemsIndex}`} options={data || []} name={`p${index}`} /><br /><br /><button type="button" className='defaultButtonMini2' style={{ width: '100%', maxWidth: '150px', margin: '10px auto', background: 'rgb(0,100,150)', padding: '10px', border: '2px solid #fff', color: '#fff' }} onClick={() => setPosition(position + 1)}>{translate('Próximo')}</button>{mandatory(items)}</>
          }
          return <></>;
        })}


        <aside style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
          <FaChevronCircleLeft size={25} style={{ color: '#777', marginRight: '10px' }} onClick={() => setPosition(position - 1)} />
          <p style={{ color: '#777', cursor: 'pointer' }} onClick={() => setPosition(position - 1)}>{translate('VOLTAR')}</p>
        </aside>

      </aside>


    })}
    <aside style={{ display: position === limit ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <h2 style={{ width: '100%', textAlign: 'center', fontSize: '18px', color: "#333", textTransform: 'uppercase', marginBottom: '15px' }} dangerouslySetInnerHTML={{ __html: information?.[`thanks_title_${language}`] ? information?.[`thanks_title_${language}`] : information?.thanks_title }} />

      <p style={{ margin: '10px 0px', textAlign: 'center', maxWidth: '500px', color: "#333" }} dangerouslySetInnerHTML={{ __html: information?.[`thanks_description_${language}`] ? information?.[`thanks_description_${language}`] : information?.thanks_description }} />

      <aside style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FaChevronCircleLeft size={30} style={{ color: '#777', marginRight: '10px' }} onClick={() => setPosition(position - 1)} />
        <button className='defaultButtonMini2' style={{ minWidth: '150px', padding: '10px', margin: '0px auto', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff' }} type="submit">
          {translate('Finalizar')}
        </button>
      </aside>
    </aside>

  </>
}

const FormContainer: React.FC<IProps> = ({ content, survey_id, handle }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [finish, setFinish] = useState(false);
  const [knowAbout, setKnowAbout] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const { removeModal } = useModal();
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const [data, setData] = useState<Record<string, any>>({})

  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };


  const { projectHash, eventHash } = useParams<Record<string, any>>();
  const [information, setInformation] = useState<Record<string, any>>({});
  const [question, setQuestion] = useState<Array<IQuestions>>([]);

  useEffect(() => {

    const newItem: Array<IQuestions> = [];

    content.questions.map(q => {
      newItem.push({ title: q.title, description: q.description, questions: [q] });
    })


    setInformation(content);
    setQuestion(newItem);

  }, [content])


  const handleNewLogin = async () => {


    setLoading(true);





    try {
      formRef.current?.setErrors({});


      const shape = {

      };

      let x = 1;

      Object.keys(data).map(key => {
        if (Array.isArray(data[key])) {
          data[key] = data[key][0] || '';
        }
      })


      question.map(quest => {
        quest.questions.map(q => {
          if (q.mandatory === 'yes') {
            shape[`p${x}`] = Yup.string().required(`${translate('Por favor, avalie a pergunta')}: ${translate(q.title)}`);
          }
          x = x + 1;
        })

      })



      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/send-survey-answer`, {
        survey_id: survey_id,
        answers: data

      });


      if (handle) {
        handle();
      }
      addToast({ title: translate('Pesquisa de satisfação enviada'), description: '', type: 'success' });
      setFinish(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        translate('Erro ao cadastrar sua pesquisa'),
      ]);
    }
    setLoading(false);
  };

  {/* 
  
              <SelectSimple name="p1" label="" options={[
              { label: '0', value: '0'},
              { label: '1', value: '1'},
              { label: '2', value: '2'},
              { label: '3', value: '3'},
              { label: '4', value: '4'},
              { label: '5', value: '5'},
              { label: '6', value: '6'},
              { label: '7', value: '7'},
              { label: '8', value: '8'},
              { label: '9', value: '9'},
              { label: '10', value: '10'},
            ]}/>
 
 
          */         }

  if (finish) {

    return <Container style={{ minHeight: '80vh', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <div style={{ flexDirection: 'column', display: 'flex', width: '100%', minHeight: '60vh', alignItems: 'center', justifyContent: 'center' }}>
        <img src="/emoji_survey/love.png" style={{ cursor: 'pointer', width: '150px', margin: '15px auto' }} />
        <h2>{translate('Obrigado por participar!')}</h2>

        <aside style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Link to={eventHash ? `/app/${projectHash}/event/${eventHash}/dashboard` : `/app/${projectHash}/dashboard`}>
            <button type="button" className="defaultButton">{translate('Voltar')}</button>
          </Link>
        </aside>
      </div>
    </Container>
  }



  return (
    <>
      {loading === true && (
        <Loader message={translate('Carregando...')} position="fixed" />
      )}

      <aside style={{ width: '100%', maxWidth: '600px' }}>

        <Form style={{ padding: '0px', borderRadius: '15px' }} ref={formRef} onSubmit={handleNewLogin} initialData={{}}>


          {question.length > 0 && <Questions updateFunction={setData} information={information} questionsList={question} />}

          {errorMessage && (
            <Error>
              {errorMessage.map((item, index) => (
                <aside key={`error${index}`} dangerouslySetInnerHTML={{ __html: item }} />

              ))}
            </Error>
          )}

        </Form>

      </aside>
    </>
  );
};

const SurveyView: React.FC = () => {

  const { survey_id } = useParams<Record<string, any>>();
  const [survey, setSurvey] = useState<IContent>({} as IContent);
  const [loading, setLoading] = useState(true);
  const [disconnected, setDisconnected] = useState(false);
  const [alreadyFilled, setAlreadyFilled] = useState(false);
  const [ready, setReady] = useState(false);
  const [finish, setFinish] = useState(false);
  const { projectHash, eventHash } = useParams<Record<string, any>>();
  const { translate } = useLanguage();
  const load = async () => {

    setDisconnected(false);
    setReady(false);
    setAlreadyFilled(false);

    try {
      const researchCheck = await api.get(`/check-survey/${survey_id}`);

      if (researchCheck?.data?.status === 200) {
        setFinish(true);
        setLoading(false);
        return;
      }

      setSurvey(researchCheck.data);
      setReady(true);
      setLoading(false);

    }
    catch (err) {
      setDisconnected(true);
      setLoading(false);
    }

  }

  useEffect(() => {
    load();



  }, [survey_id])

  if (finish) {

    return <Container style={{ minHeight: '80vh', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <div className='shadow' style={{ flexDirection: 'column', background: '#fff', borderRadius: '15px', maxWidth: '350px', display: 'flex', width: '100%', minHeight: '400px', alignItems: 'center', justifyContent: 'center' }}>
        <img src="/emoji_survey/love.png" style={{ cursor: 'pointer', width: '150px', margin: '15px auto' }} />
        <h2>{translate('Obrigado!')}</h2>
        <h2>{translate('Você já participou desta pesquisa.')}</h2>

        <aside style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Link to={eventHash ? `/app/${projectHash}/event/${eventHash}/dashboard` : `/app/${projectHash}/dashboard`}>
            <button type="button" className="defaultButton">{translate('Voltar')}</button>
          </Link>
        </aside>
      </div>
    </Container>
  }



  return <Container style={{ minHeight: '80vh', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
    {loading && <Box>
      {translate('Carregando...')} <FaSpinner className='rotateAnimation' size={20} />
    </Box>
    }

    {disconnected && <Box style={{ flexDirection: 'column' }}>
      <div>
        <p>{translate('Você parece estar desconectado')}.<br />
          {translate('Realize seu login novamente')}.</p>
      </div>
      <SignInReload />
    </Box>
    }

    {alreadyFilled && <Box>
      <strong>{translate('Obrigado')}!</strong><br />
      {translate('Você já preencheu esta pesquisa')}.
    </Box>
    }

    {ready && <Box><FormContainer survey_id={survey_id} content={survey} /></Box>}

  </Container>
}

export default SurveyView;
