import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import ContainerLine from '../../components/UIComponents/ContainerLine';
import { Title } from '../Expositor/styles';
import ParagraphDangerous from '../../components/UIComponents/ParagraphDangerous';
import CientificLibrary from '../CientificLibrary';
import CientificLibraryComponent from '../../components/CientificLibrary';
import { ContainerCientific } from '../CientificLibrary/styles';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Sobre: React.FC = () => {


  return (
    <>

      <ContainerLine className='full-width column column pad '>
        <ContainerLine className='max-1200 column column-start gap'>

          <Title style={{ color: '#555', fontSize: '16px', lineHeight: '20px' }} className="text-left width-full">CLUBE ACADÊMICO</Title>

          <ParagraphDangerous text={`<p>
A Sociedade Brasileira de Cirurgia Bariátrica e Metabólica (SBCBM) lança um novo projeto: o <strong>Clube Acadêmico</strong>.
<br/><br/>
A plataforma de streaming de vídeos tem o propósito de promover o acesso privilegiado a um acervo de conteúdo científico de alto nível aos membros associados.
<br/><br/>
Esse projeto é mais uma novidade que visa o futuro da entidade, o crescimento de profissionais atuantes no tratamento da obesidade, mantendo a SBCBM como referência científica nesta área de atuação.
<br/><br/>
O objetivo é  discutir e entrevistar autores sobre seus artigos científicos e cirurgiões sobre vídeos das suas cirurgias, em formato Podcast. Também traremos os principais destaques do que há de novo e relevante no tratamento da obesidade, do diabetes e todas as doenças associadas ao excesso de peso em cada mês.
Tudo em uma plataforma dedicada e acessível para os profissionais que atuam no tratamento da obesidade.
<br/><br/>
Todo o conteúdo é produzido exclusivamente para este projeto que terá acesso gratuito aos membros associados da SBCBM e acesso livre por 30 dias para usuários que queiram conhecer a plataforma.  </p>`} />

          <ContainerCientific>
            <CientificLibraryComponent header={false} />

          </ContainerCientific>
        </ContainerLine>
      </ContainerLine>
    </>
  );
};
export default Sobre;
