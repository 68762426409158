import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { Container } from './styles';
import { hour, dateLanguage } from '../../utils/date';
import api from '../../services/api';
import Vimeo from '../Vimeo';
import Helmet from 'react-helmet';
import PosterZoomComponent from '../CientificLibraryRoom';
import { Button } from '../Button/styles';
import { rgb, rgba } from 'polished';
import Favorite from '../ClassRoomContainer/modules/Favorite';
import { FaBook, FaSearch, FaStar } from 'react-icons/fa';
import { useLanguage } from '../../hooks/Language';
import { string } from 'yup';
import themeDefault from '../../styles/ThemeComponents';
import { urlWebsite } from '../../config';
import { useAuth } from '../../hooks/Auth';
import { useParams, useHistory } from 'react-router-dom';
import SelectSimple from '../Forms/SelectSimple';
import DefaultCategoryGallery from './modules/DefaultCategoryGallery';
import Title from '../UIComponents/Title';
import Paragraph from '../UIComponents/Paragraph';
import { ContainerLineUI } from '../UIComponents/ContainerLine/styles';
import Separator from '../UIComponents/Separator';
import ParagraphDangerous from '../UIComponents/ParagraphDangerous';
import DefaultCientificLibraryRender from './modules/DefaultCientificLibraryRender';
import DisplayCientificLibraryAuthors from './modules/DisplayCientificLibraryAuthors';
import DisplayCientificLibraryCategory from './modules/DisplayCategories';

interface IPoster {
  _id: string;
  keywords: Array<string>;
  star?: number;
  title?: string;
  nested_authors?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  eixo?: string;
  pdf?: string;
  pdf_image?: Array<Record<string, any>>;
  time_to_read?: string;
}

interface IOption {
  page: number;
  order: Record<string, string>;
  where: Record<string, any>;
  eixo?: string;
}

interface IStars {
  stars: number;
}

interface IOptions {
  label: string;
  value: string;
}



export const track = ({ id = '', title = '', reference = '', track = '', client_id = '', client_name }) => {

  /* ID do trabalho
  titulo do trabalho
  reference é a ação
  track o que é
  
  */

  api.post('/add-track', { id, title, reference, track, client_id: client_id, client_name, project_id: 'cientific-library' });


}

export const ShowStars: React.FC<IStars> = ({ stars = 5 }) => {

  return <div className='starCase'>

    <FaStar size={15} style={{ color: stars >= 1 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 2 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 3 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 4 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 5 ? themeDefault.alternativeButton.background : '#fff' }} />
  </div>

}

interface IProps {
  header?: boolean;
}

const CientificLibraryComponent: React.FC<IProps> = ({ header = true }) => {
  const [posters, setPosters] = useState<Array<IPoster>>([]);
  const [selectedPoster, setSelectedPoster] = useState<string>('');

  const { posterid, eixo, author } = useParams<Record<string, any>>();

  const [openPoster, setOpenPoster] = useState(false);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(1);
  const [search, setSearch] = useState('');
  const [haveSelector, setHaveSelector] = useState(false);
  const [category, setCategory] = useState('');
  const [eixos, setEixos] = useState<IOptions[]>([]);

  const [authors, setAuthors] = useState<IOptions[]>([]);

  const { user, signOut } = useAuth();
  const [options, setOptions] = useState<IOption>({
    page: 1,
    order: { orderBy: 'createdAt', direction: 'desc' },
    where: {},
    eixo: ''
  });
  const { translate } = useLanguage();


  const [bigAreas, setBigAreas] = useState([
    { title: 'Entrevistas', description: '<p> Confira as entrevistas do Clube Acadêmico SBCBM com especialistas renomados</p>' },
    { title: 'Cirurgias', description: '<p> Vídeos de cirurgias comentados por especialistas</p>' },
    { title: 'Destaques', description: '<p> Confira os destaques do mês em nossa plataforma</p>' },
  ])

  useEffect(() => {
    window?.scrollTo(0, 0)
  }, [posterid])

  useEffect(() => {
    if (window.location.pathname?.indexOf('acessar') < 0) {
      setOpenPoster(false)
    }

  }, [window.location.pathname])

  useEffect(() => {


    const searchValue = new URLSearchParams(window.location.search).get("search");

    loadKeys();
    loadAuthors();


    track({ id: '', title: 'Biblioteca', reference: 'Visita', track: 'Acesso', client_name: user?.name || '', client_id: user?.id || '' })



    if (searchValue) {
      console.log('passou aqui 2')
      setSearch(searchValue);
      loadSearch({ listOptions: {}, text: searchValue });

    }
    else {
      console.log('passou aqui 3')
      load();
    }


  }, [])

  useEffect(() => {

    if (posterid) {
      setSelectedPoster(posterid);
      setOpenPoster(true);
    }

  }, [posterid])


  const history = useHistory();


  const changeEixo = (value) => {

    const newOptions = { ...options };

    // newOptions.eixo = value;

    setOptions(newOptions);
    if (value) {
      history.push(`/conteudos/categoria/${value}`);
    }
    else {
      history.push(`/`);
    }
    console.log('passou aqui')
    loadSearch({ listOptions: newOptions });

  }


  const changeAuthor = (value, authorsList) => {

    const item = authorsList?.find(i => i?.value === value);

    const newOptions = { ...options };



    setOptions(newOptions);
    if (value) {
      history.push(`/conteudos/autores/${item?.value}/${item?.label}`);
    }
    else {
      history.push(`/`);
    }

    loadSearch({ listOptions: newOptions });

  }



  const loadSearch = async ({ join = false, page = 0, text = '', listOptions }) => {


    const newOptions = listOptions?.page ? { ...listOptions } : { ...options };

    const string = `search=${encodeURIComponent(
      text || search || '',
    )}&order=${encodeURIComponent(JSON.stringify(newOptions.order))}&page=${page > 0 ? page : newOptions.page
      }&where=${encodeURIComponent(JSON.stringify(newOptions.where))}&eixo=${encodeURIComponent(newOptions.eixo || '')}`;



    const response = await api.get(`/list-cientific-library?${string}`);



    if (response && response.data && response.data.rows) {
      const responseData = response.data.rows.reduce((prev, elem) => {
        elem.formatted_date = dateLanguage(elem.date);
        elem.formatted_start_hour = hour(elem.start_hour);
        elem.formatted_end_hour = hour(elem.end_hour);
        if (!elem?.image) {
          elem.image = 'apoio/capa-conteudo.jpeg';
        }

        if (!elem.keywords) {
          elem.keywords = [];
        }
        prev.push(elem);


        return prev;
      }, []);

      if (join) {
        setPosters([...posters, ...responseData]);
      } else {
        setPosters([...responseData]);
      }

      setCounter(response.data.count / 20 < 1 ? 1 : response.data.count / 20);


      //  readEixos(response.data.rows);

    } else {
      setPosters([]);
      setCounter(1);
    }
    setLoading(false);
  };


  const loadKeys = async () => {
    const response = await api.get(`/list-cientific-library-categories`);

    if (response.data) {

      const items: IOptions[] = [];

      response.data.rows.map((key) => {
        items.push({ label: key?.title, value: key?.title });
      })

      items.sort((a, b) => a?.label > b?.label ? 1 : a?.label < b?.label ? -1 : 0)

      setEixos(items || []);

    }

  }


  const loadAuthors = async () => {
    const response = await api.get(`/list-cientific-library-authors`);

    if (response.data) {

      const items: IOptions[] = [];

      response.data.rows.map((key) => {
        items.push({ label: key?.title, value: key?._id });
      })

      items.sort((a, b) => a?.label > b?.label ? 1 : a?.label < b?.label ? -1 : 0)

      setAuthors(items || []);

    }

  }



  const newSearch = () => {
    const newOptions = { ...options };
    newOptions.page = 1;
    setOptions({ ...newOptions });
    loadSearch({ listOptions: {} });
    setSearch('');
  };




  const load = async () => {

    const newOptions = { ...options };
    if (eixo) {


      //  newOptions.eixo = eixo;

      setOptions(newOptions);
    }


    loadSearch({ listOptions: newOptions });
  };

  useEffect(() => {
    if (eixo) {
      console.log('deu eixo')
      load();
      track({ id: '', title: 'Biblioteca', reference: 'Visita', track: `Categoria - ${eixo}`, client_name: user?.name || '', client_id: user?.id || '' })
    }

  }, [eixo])

  useEffect(() => {

    if (author) {
      load();
      track({ id: '', title: 'Biblioteca', reference: 'Visita', track: `Autores - ${author}`, client_name: user?.name || '', client_id: user?.id || '' })
    }

  }, [author])

  useEffect(() => {
    /*
        if (window?.location?.pathname?.indexOf('/conteudo') < 0 && openPoster) {
          setOpenPoster(false);
          history.push(`/`);
        }
      */

  }, [window?.location?.pathname]);


  const closePoster = () => {
    setOpenPoster(false);
  };

  const showPoster = poster => {
    setSelectedPoster(poster.url);
    setOpenPoster(true);
  };

  if (openPoster) {
    return (<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

      <PosterZoomComponent
        posters={posters}
        closePoster={closePoster}
        posterID={selectedPoster}
        closeFunction={() => setOpenPoster(false)}
      />



    </div>
    );
  }

  const addPage = () => {

    setOptions((state) => {
      state.page += 1;
      loadSearch({ join: true, page: state.page, listOptions: {} });
      return state
    });


  };

  const changeCategory = (column, categoryName) => {
    const newOptions = { ...options };
    if (categoryName === '') {
      newOptions.where = {};
      setOptions({ ...newOptions });

      loadSearch({ listOptions: newOptions });
      if (categoryName === 'have_video') {
        setCategory(categoryName);
      }
      return;

    }


    const newCategory =
      newOptions.where && newOptions.where[column] === categoryName
        ? ''
        : categoryName;
    newOptions.page = 1;
    if (newCategory === '' && newOptions.where[column]) {
      delete newOptions.where[column];
    } else {
      newOptions.where[column] = newCategory;
    }

    setOptions({ ...newOptions });

    loadSearch({ listOptions: newOptions });
    setCategory(newCategory);
  };

  const addAndSearch = (text) => {



    loadSearch({ text, listOptions: {} });

  }


  const makeSearch = (value) => {

    setSearch(value);
    loadSearch({ listOptions: {}, text: value });

  }

  return (
    <Container>


      {header ? <div className="posterSelector">





        <div className='posterHeader' >

          <h1>Quais são seus maiores interesses?</h1>
          <div className='separator' />
          <div className='posterSearch'>

            <Form className='row row-to-column'
              style={{ display: 'flex', alignItems: 'stretch', background: 'none' }}
              initialData={{ pesquisa: search, eixo, author }}
              onSubmit={newSearch}
            >

              <input
                name="pesquisa"
                type="text"
                placeholder={`${translate('Buscar aqui')}...`}
                value={search}
                onChange={e => { makeSearch(e.target.value) }}
              />


              <SelectSimple theme='Dark' label="" placeholder='Tema' name="tema" options={eixos} setCallback={(e) => { changeEixo(e); }} />


              <SelectSimple theme='Dark' label="" placeholder='Autor' name="autor" options={authors} setCallback={(e) => { changeAuthor(e, authors); }} />





              <button type="submit">FILTRAR</button>
            </Form>

          </div>
        </div>
      </div> : <></>}

      <Separator className='max-95p ' style={{ margin: '5px auto', marginTop: '45px', height: '2px', background: '#0088c4' }} />


      {!eixo && !author && !openPoster ? <DefaultCientificLibraryRender posters={posters} showPoster={showPoster} bigAreas={bigAreas} /> : <></>}
      {author && !openPoster ? <DisplayCientificLibraryAuthors authorsList={authors} author={author} posters={posters} showPoster={showPoster} bigAreas={bigAreas} /> : <></>}

      {eixo && !openPoster ? <DisplayCientificLibraryCategory eixo={eixo} posters={posters} showPoster={showPoster} bigAreas={bigAreas} /> : <></>}




    </Container>
  );
};
export default CientificLibraryComponent;
