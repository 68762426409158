import axios from 'axios';

interface Response {
  bairro: string;
  cep: string;
  complemento: string;
  gia: string;
  ibge: string; // código do município
  localidade: string; // Cidade
  logradouro: string; // Rua
  uf: string; // Estado Ex: RS
  unidade: string;
  error?: boolean;
}

export default async function getCep(cep: string): Promise<Response> {
  const cepNumber = cep.replace(/[^0-9]/g, '');

  if (cepNumber.toString().length === 8) {
    const response = await axios.get(
      `https://viacep.com.br/ws/${cepNumber}/json/`,
    );

    if (response) {
      return response.data;
    }
  }

  return { error: true } as Response;
}
