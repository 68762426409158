import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import { Whats } from './styles';

interface IWhatsapp {
  number?: string;
  text?: string;
  message?: string;
  iconSize?: string;
  fontSize?: string;
}

const Whatsapp: React.FC<IWhatsapp> = ({
  number = '5551995181533',
  text = 'Oi, estou em seu website!',
  message = 'Tire suas dúvidas pelo WhatsApp',
  iconSize = '30px',
  fontSize = '16px',
}) => {
  return (
    <Whats
      iconSize={iconSize}
      fontSize={fontSize}
      href={`https://api.whatsapp.com/send?l=pt&phone=${number}&text=${encodeURI(
        text,
      )}`}
      className="floater whatsapp"
    >
      <FaWhatsapp size={24} />
      {message}
    </Whats>
  );
};

export default Whatsapp;
