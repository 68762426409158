import styled from 'styled-components';
import { ContainerRef } from '../styles';

export const Container = styled(ContainerRef)`

>div{
    margin: 10px 0px !important;

}

table{
    span{
        position: relative !important;
        font-size: 14px !important;
        top:auto !important;
        left: auto !important;
    }
}
`;