import styled from 'styled-components';
import {ContainerRef} from '../styles';

export const Container = styled(ContainerRef)`

.cursor {
    transition: 0.5s all;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
}

.noValue{
    justify-content: flex-start;

    width: 60px;

    background: #777;
    >div{
        border: 2px solid #777;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        background: #fff;
    }
}

.yesValue{
    width: 60px;
    padding-left: 30px;
    background: rgb(0,150,0);
    >div{
        border: 2px solid rgb(0,150,0);
        border-radius: 50%;
        height: 15px;
        width: 15px;
        background: #fff;
    }
}

`;