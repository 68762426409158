import styled, { keyframes } from 'styled-components';
import { defaultTheme } from '../../config';

export const Container = styled.div`
  font-size: 50px;
  color: ${defaultTheme.menu.logoColor};

  > img {
    width: 200px;
  }

  @media (max-width: 750px) {
    font-size: 40px;
    max-width: auto !important;
    > img {
      width: 200px !important;
      margin: 0 5px !important;
    }
  }
`;
