import React from 'react';


import { SeparatorUI } from "./styles";

interface IProps {
    children?: React.ReactNode;
    style?: Record<string, any>;
    theme?: 'dark' | 'light';
    className?: string;
}

const Separator: React.FC<IProps> = ({ children, style, theme, className }) => {


    return <SeparatorUI themeValue={theme} style={style} className={className} />;
}

export default Separator;