import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Container, Content, Background, AnimatedDiv2 } from './styles';

import PosterComponent from '../../components/PosterComponent';
import { useSocket } from '../../hooks/Socket';
import { useConfiguration } from '../../hooks/Configuration';

interface PosterData {
  email: string;
  password: string;
  name: string;
}

const Poster: React.FC = () => {
  const { socket } = useSocket();
  const { configuration } = useConfiguration();

  useEffect(() => {
    const ping = setInterval(() => {
      socket?.emit('SetAttendance', {
        room: 87,
        project_id: configuration?.url,
        event_id: configuration?.current_event_id_response?.url || '',
      });
    }, 120000);

    return () => clearInterval(ping);
  }, []);

  useEffect(() => {
    socket?.emit('SetAttendance', {
      room: 87,
      project_id: configuration?.url,
      event_id: configuration?.current_event_id_response?.url || '',
    });
  }, []);

  return (
    <Container>
      <PosterComponent />
    </Container>
  );
};
export default Poster;
