import React from 'react';

// import { Container } from './styles';
interface VimeoProps {
  id: string;
  keyName?: string;
  time?: string;
}

const OnDemandPlayer: React.FC<VimeoProps> = ({ id = '', keyName, time = '0' }) => {
  return (
    <div
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
      
        height: 0,
        width: '100%',
      }}
    >
      <iframe key={keyName} src={`https://iframe.mediadelivery.net/embed/${id}?autoplay=true${time ? `&t=${time}` : ''}`}   style={{border: 'none', position: 'absolute', top: 0, height: '100%', width: '100%'}} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowFullScreen></iframe>
    </div>
  );
};

export default OnDemandPlayer;
