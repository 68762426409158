import React, { useEffect } from 'react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiInfo,
  FiXCircle,
} from 'react-icons/fi';
import { Container } from './styles';
import { ToastMessage, useToast } from '../../../hooks/Toast';

interface ToastProps {
  message: ToastMessage;
  style: Record<string, any>;
}

const ToastContainer: React.FC<ToastProps> = ({ message, style }) => {
  const icons = {
    info: <FiInfo />,
    success: <FiCheckCircle />,
    error: <FiAlertCircle />,
  };
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => removeToast(message.id), 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <Container
      style={style}
      key={message.id}
      hasDescription={!!message.description}
      type={message.type || 'info'}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>
      <button onClick={() => removeToast(message.id)} type="button">
        <FiXCircle />
      </button>
    </Container>
  );
};

export default ToastContainer;
