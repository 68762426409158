import React from 'react';
import { uuid } from 'uuidv4';

// import { Container } from './styles';

interface YoutubeI {
  id: string;
  keyName ?: string;
}

const Youtube: React.FC<YoutubeI> = ({ id , keyName = uuid()}: YoutubeI) => {
  return (
    <div
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        paddingTop: 25,
        height: 0,
        width: '100%',
      }}
    >
      <iframe key={keyName}
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title={id}
        src={`https://www.youtube.com/embed/${id}?rel=0&controls=1&showinfo=0&autoplay=1`}
        frameBorder="0"
      />
    </div>
  );
};

export default Youtube;
