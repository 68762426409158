import React from 'react';

// import { Container } from './styles';
interface VimeoProps {
  id: string;
  keyName?: string;
  time?: string;
}

const IframePlayer: React.FC<VimeoProps> = ({ id = '', keyName, time = '0' }) => {
  return (
    <div
      className="video full-width"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,

        height: 0,
        width: '100%',
        overflow: 'hidden',
        borderRadius: '15px'
      }}
    >
      <iframe
        key={keyName || `videoPlay${new Date().getTime()}`}
        frameBorder="0"
        allow="fullscreen"
        allowFullScreen

        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title={id}
        src={`${id}`}
      />
    </div>
  );
};

export default IframePlayer;
