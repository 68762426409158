import React from 'react';
import {
  Route as ReactDomRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/Auth';

import AuthLayout from '../pages/_layouts/auth';
import PageLayout from '../pages/_layouts/page';
import ClientLayout from '../pages/_layouts/ClientLayout';
import ManagerLayout from '../pages/_layouts/ManagerLayout';
import DefaultLayout from '../pages/_layouts/default';
import DashboardLayout from '../pages/_layouts/DashboardLayout';
import { useToast } from '../hooks/Toast';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isSecret?: boolean;
  component: React.ComponentType;
  template?: string;
}

interface LayoutProps {
  [key: string]: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isSecret = false,
  component: Component,
  template = '',
  ...rest
}) => {
  const validTemplate: LayoutProps = {
    default: DefaultLayout,
    auth: AuthLayout,
    page: PageLayout,
    client: ClientLayout,
    manager: ManagerLayout,
    dashboard : DashboardLayout
  };

  const { user, token } = useAuth();
  const isSigned = !!user;

  if (isSecret && user && user.profile !== 1 && user.profile !== 4 && user.profile !== '1' && user.profile !== '4') {
    console.log('error user');

    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  let Layout = validTemplate[template]
    ? validTemplate[template]
    : DefaultLayout;



  if (template === 'dashboard' && !user) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  if (template === 'dashboard' && user.profile !== 1 && user.profile !== 4) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  return (
    <ReactDomRoute
      {...rest}
      render={({ location }) => {
        return (
          <Layout>
            {isPrivate === isSigned || !isPrivate ? (
              <Component />
            ) : (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            )}
          </Layout>
        );
      }}
    />
  );
};

export default Route;
