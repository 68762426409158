import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
`;
