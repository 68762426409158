const testCPF = strCPF => {
    let Soma;
    let Resto;
    Soma = 0;
    let i = 1;
    if (!strCPF) { return false }
    if (strCPF == "00000000000") return false;

    const str = strCPF.toString().replace(/[^0-9/]/g, '')

    for (i = 1; i <= 9; i++)
        Soma += parseInt(str.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(str.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
        Soma += parseInt(str.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(str.substring(10, 11))) return false;
    return true;
};

export default testCPF;