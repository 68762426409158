import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const CientificLibrary: React.FC = () => {
  const endpoint = '/scheduler';
  const title = 'Casos Clínicos';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    date_text: { ref: 'date_text', column: 'date_text', label: 'Data' },
    title: { ref: 'title', column: 'title', label: 'Reunião' },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    status: { ref: 'status', column: 'status', label: 'Ativo?' },
    thumb: { ref: 'thumb', column: 'thumb', label: 'Imagem', show: false },

    meeting_date: { ref: 'meeting_date', column: 'meeting_date', label: 'Data', show: false },

    page_link: { ref: 'page_link', column: 'page_link', label: 'Link' },
    type: { ref: 'type', column: 'type', label: 'Tipo' },
    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    meeting_date: { model: 'datePickerHour', name: 'meeting_date', label: 'Data da Reunião' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Nome da Reunião' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
    category: { model: 'input', type: 'text', name: 'category', label: 'Categoria' },


    page_link: { model: 'input', type: 'text', name: 'page_link', label: 'Link da Página' },






    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',
      default: 'public',

      options: [
        { label: 'Público', value: 'public' },
        { label: 'Privado', value: 'private' },


      ]

    },

    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      default: 'yes',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    date_text: { model: 'input', type: 'text', name: 'date', label: 'Data da Reunião em Texto' },

  };




  const formSchemaUpdateInvite = {
    invites: {
      model: 'ArrayInputIncluder',
      name: 'invites',
      label: 'Convidados',
      defaultValue: '',
    },


  };


  const formSchemaUpdate = {
    ...formSchema,
    thumb: {
      model: 'uploadImage',
      type: 'upload',
      name: 'thumb',
      label: 'Imagem',
    },

  };


  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdateInvite,
          validationSchema: {},
          endpoint,
          Icon: FaUser
        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default CientificLibrary;
