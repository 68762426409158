import styled, { css } from "styled-components";

interface IProps {
    themeValue?: 'dark' | 'light';
}

export const SeparatorUI = styled.div<IProps>`

height: 1px;
background: #333;
width: 100%;
margin: 15px auto;
opacity: 0.7;

 

${props => props?.themeValue === 'dark' && css`
background: #fff;
`}

`