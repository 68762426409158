import React, { useState } from 'react';

import { FaPlus, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import {
  Container,
  Grid,
  Speaker,
  SpeakerLine,
  SpeakerListContainer,
  ContainerWhite,
  SpeakerDiv,
} from './styles';
import { urlWebsite } from '../../config';

import { Modal } from '../ModalContainer/styles';
import ContactIcons from './modules/social';
import { useConfiguration } from '../../hooks/Configuration';
import { useLanguage } from '../../hooks/Language';

interface ISpeakerData {
  title?: string;
  name: string;
  photo: string;
  position?: string;
  description?: string;
  id: number;
  category_response?: Record<any, string>;
  social?: Record<any, string>;
  company?: string;

}

interface IGrid {
  id?: string;
  speakers?: Array<ISpeakerData>;
}

interface IModal {
  speaker: ISpeakerData;
}

interface ISpeaker {
  background: string;
}

interface ISpeakers {
  type?: 'list' | 'grid';
  speakers?: Array<ISpeakerData>;
  title: string;
}



const SpeakerGrid: React.FC<IGrid> = ({
  speakers = [],
  id = 'palestrantes',
  children,
}) => {
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState<ISpeakerData>(
    {} as ISpeakerData,
  );

  const { configuration } = useConfiguration();
  const { language } = useLanguage();

  const showSpeaker = speaker => {
    if (speaker._id === activeSpeaker) {
      setActiveSpeaker('');
      setSelectedSpeaker({} as ISpeakerData);
    } else {
      setActiveSpeaker(speaker._id);
      setSelectedSpeaker(speaker);
    }
  };

  return (
    <Container>
      <div className="title">{children}</div>


      <Grid>
        {speakers.map(speaker => {
          return (<SpeakerDiv className='shadow' onClick={() => { showSpeaker(speaker) }}>
            <div className='speakerPhotoDiv'>
              <Speaker
                className='speakerPhoto'

                background={
                  speaker.photo
                    ? `${urlWebsite}/${speaker.photo}`
                    : 'assets/evento/logo.png'
                }
              >

              </Speaker></div>

            <div className='contentSpeaker'>
              <div>
                <h3 style={{ color: 'rgb(0,14,64)' }}>{speaker.title ? `${speaker?.[`title_${language}`] ? speaker?.[`title_${language}`] : speaker.title} ` : ''}{speaker.name}</h3>
                {speaker.position && <p className='positionSpeaker' style={{ color: 'rgb(0,14,64)', marginBottom: '5px' }}>{speaker?.[`position_${language}`] ? speaker[`position_${language}`] : speaker.position}</p>}
                {speaker.company && <p className='positionSpeaker' style={{ color: 'rgb(0,14,64)', marginBottom: '5px' }}>{speaker.company}</p>}

              </div>
              <p style={{ fontSize: '12px', color: 'rgb(0,14,64)', fontWeight: 'bold' }}>{speaker.category_response && speaker.category_response.title}</p>

              {speaker?.social && <ContactIcons
                backgroundFill={true}
                socialIcons={speaker.social}
              />}
            </div>
          </SpeakerDiv>
          );
        })}
      </Grid>

      {
        activeSpeaker !== '' && (
          <div style={{ position: 'fixed', background: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', top: '0px', left: '0px', width: '100%', height: '100vh', overflowY: 'auto', zIndex: 100000 }}>
            <div style={{ width: '100%', maxWidth: '800px', background: 'none' }}>
              <button
                style={{ marginBottom: '10px' }}
                className="closeButton defaultButton"
                type="button"
                onClick={() => showSpeaker(selectedSpeaker)}
              >
                Fechar
              </button>
              <SpeakerModal speaker={selectedSpeaker} />
            </div>
          </div>
        )
      }
    </Container >
  );
};

export const SpeakerModal: React.FC<IModal> = ({ speaker }) => {

  const { configuration } = useConfiguration();
  const { language } = useLanguage();
  return (
    <>
      <ContainerWhite className={`speakerModalDiv shadow ${configuration?.theme_data?.speakers_className && `modal_${configuration?.theme_data?.speakers_className}`} `} style={{ alignItems: 'flex-start', borderRadius: '15px', flexDirection: 'column', background: '#f3f3f3' }}>

        <div className="p50 p600" style={{ textAlign: 'center', display: 'flex', }}>
          <div className='speakerPhotoDiv'>
            <img
              src={
                speaker.photo
                  ? `${urlWebsite}/${speaker.photo}`
                  : 'assets/evento/logo.png'
              }
              style={{ width: '100%', maxWidth: '250px', minWidth: '250px' }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', alignItems: 'flex-start' }}>
            <h2 style={{ fontSize: '20px', color: '#333', textAlign: 'left', width: '100%' }}>{speaker.title ? `${speaker.title} ` : ''}{speaker.name} </h2>
            <strong style={{ color: '#333', fontSize: '14px', textAlign: 'left', width: '100%', minWidth: '300px' }}> {speaker.company} </strong>
            <strong style={{ color: '#333', fontSize: '12px', textAlign: 'left', width: '100%', minWidth: '300px' }}> {speaker?.[`position_${language}`] ? speaker?.[`position_${language}`] : speaker.position} </strong>
            <p style={{ color: '#333', fontSize: '12px', textAlign: 'left', width: '100%' }}>{speaker.category_response && speaker.category_response.title}</p>
            <br /><br />   <p style={{ color: '#333' }}

              dangerouslySetInnerHTML={{
                __html: speaker?.[`description_${language}`] ? speaker[`description_${language}`] : speaker.description || '',
              }}
            />
          </div>
        </div>

      </ContainerWhite>
    </>
  );
};

export const SpeakerList: React.FC<IGrid> = ({
  speakers = [],
  id = 'palestrantes',
  children,
}) => {
  const [activeSpeaker, setActiveSpeaker] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState<ISpeakerData>(
    {} as ISpeakerData,
  );



  const showSpeaker = speaker => {
    console.log(speaker, activeSpeaker);
    if (speaker._id === activeSpeaker) {
      setActiveSpeaker('');
      setSelectedSpeaker({} as ISpeakerData);
    } else {
      setActiveSpeaker(speaker._id);
      setSelectedSpeaker(speaker);
    }
  };

  return (
    <Container>
      <div className="title">{children}</div>


      <SpeakerListContainer>
        {speakers.map(speaker => {
          return (
            <SpeakerLine
              onClick={() => showSpeaker(speaker)}
              background={
                speaker.photo
                  ? `${urlWebsite}/${speaker.photo}`
                  : 'assets/evento/logo.png'
              }
            >
              <div className="speakerPhoto" />

              <div className="speakerInfo">

                <h2>{speaker.title ? `${speaker.title} ` : ''}{speaker.name}</h2>
                <p>{speaker.category_response && speaker.category_response.title}</p>
                <p>{speaker.position}</p>
              </div>
            </SpeakerLine>
          );
        })}
      </SpeakerListContainer>

      {activeSpeaker !== '' && (
        <div style={{ position: 'fixed', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', top: '0px', left: '0px', width: '100%', height: '100vh', paddingTop: '50px', overflowY: 'auto', zIndex: 100000 }}>
          <button
            style={{ marginBottom: '10px' }}
            className="closeButton defaultButton"
            type="button"
            onClick={() => showSpeaker(selectedSpeaker)}
          >
            Fechar
          </button>
          <SpeakerModal speaker={selectedSpeaker} />
        </div>
      )}
    </Container>
  );
};

export const SpeakersContainer: React.FC<ISpeakers> = ({ speakers, title }) => {
  const [type, setType] = useState('grid');
  const { configuration } = useConfiguration();
  const { translate } = useLanguage();
  if (type === 'list') {
    return (
      <SpeakerList speakers={speakers}>
        <h2 style={{ color: configuration?.color || 'rgb(0,14,64)' }}>
          {title}
          {' '}

        </h2>
        <p style={{ color: configuration?.color || 'rgb(0,14,64)' }}>{translate("Clique para visualizar o currículo")}.</p>
      </SpeakerList>
    );
  }
  if (type === 'grid') {
    return (
      <SpeakerGrid speakers={speakers}>
        <h2 style={{ color: configuration?.color || 'rgb(0,14,64)' }}>
          {title}
          {' '}

        </h2>
        <p style={{ color: configuration?.color || 'rgb(0,14,64)' }}>{translate("Clique para visualizar o currículo")}.</p>

      </SpeakerGrid>
    );
  }
  return <></>;
};

export default SpeakerGrid;
