import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../../../services/api';
import { date, hour, dateLanguage } from '../../../../utils/date';
import { Container, Sidebar, Body } from './styles';
import {addHours} from 'date-fns';
import { useToast } from '../../../../hooks/Toast';
import SelectApi from '../../core/components/Forms/SelectApi';
import { Form } from '@unform/web';
import DatePicker from '../../core/components/Forms/DatePicker';
import Button from '../../core/components/Forms/Button';
import SelectSimple from '../../core/components/Forms/SelectSimple';
import ChartBar from '../../core/components/Charts/Bar';
import { apiUrl, getToken } from '../../../../config';
import { useAuth } from '../../../../hooks/Auth';



interface IOptions {
  text?: string;
  label?: string;
  value: string;
}

interface IChartData {
  hora : string;
  usuarios : number;
}

const UpdateData: React.FC = () => {
const {token} = useAuth();

const [percentage,setPercentage] = useState('0.00');
const [active,setActive] = useState(false);


const startTransfer = async () => {
  const response = await api.post('/getTransferData');

  const { total, current} = response.data;
  

  const distance = total - current;
  setActive(true);

  let x = current;

  for( let x = current; x <= total; x = x + 200){

    const r = await api.post('/setTransferData', { current });

    setPercentage((((x - current)/distance)*100).toFixed(2));

  }

  

}

  return (
    <Container>
    { token && <Button onClick={() => {
        window.location.href = `${apiUrl}/getParticipants?hash=${getToken()}&type=xlsx`;
      }}>Exportar Participantes</Button>}

{ token && <Button onClick={() => {
        window.location.href = `${apiUrl}/getParticipantsMinutes?hash=${getToken()}&type=xlsx`;
      }}>Exportar dos últimos 5 minutos</Button>}

    <Button onClick={() => startTransfer()}>Iniciar transferência</Button>

    { active && <>
      <p>{percentage}%</p> 
      <div style={{width: '300px', margin: '10px auto'}}>
      <div style={{ height: '20px', background:'rgb(0,150,100)', margin: '10px', width: `${percentage}%`}}/></div> </>}
      
 
    </Container>
  );
};
export default UpdateData;
