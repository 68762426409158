import styled from 'styled-components';
import {ContainerRef} from '../styles';

export const Container = styled(ContainerRef)``;

export const Field = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;

  > label {
    margin-bottom: 5px;
    display: flex;
    max-width: 200px;
    min-width: 150px;
  }

  > div {
    width: 100%;
    margin: 0 5px;

    > input,
    > select,
    > textarea {
      margin-top: 0;
    }
  }
`;