import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/Auth';
import { useVideo } from '../../../../hooks/Video';
import { Container } from './styles';
import WebsitePopupComponent from '../WebsitePopupComponent';
import SorteioRoom from '../Sorteio';
import { RoomChangeCreator } from '../RoomChange';
import { RoomMessagerContainer, RoomMessagerCreator } from '../RoomMessager';
import { FaArrowRight, FaChevronRight, FaGift, FaHamburger, FaImage, FaPenSquare, FaXing } from 'react-icons/fa';
import QuizzComponent, { QuizContainerReceiver } from '../QuizzComponent';
import { FiLoader, FiMenu, FiMessageCircle, FiMessageSquare, FiX, FiXCircle } from 'react-icons/fi';
import { Modal } from '../../styles';
import { useLanguage } from '../../../../hooks/Language';


const RoomConfiguration = () => {

    const { currentRoom } = useVideo();
    const { user } = useAuth();
    const [active, setActive] = useState(false);
    const [option, setOption] = useState('');
    const { translate } = useLanguage();
    useEffect(() => {
        setActive(false);
        setOption('');
    }, [currentRoom])


    return <><Container active={active}>
        {user && user?.profile !== 2 ? <>
            <div className='roomConfigActivation'><FiMenu onClick={() => setActive(true)} size={25} /></div>
            {active ? <Modal style={{ zIndex: 100 }}>
                <div className='roomConfigSelector'>
                    <div>
                        <button type='button' className='closeAdminButton' onClick={() => setActive(false)}>{translate("Fechar")} <FiX /></button>
                        <h2>Selecione a opção:</h2>
                        <nav onClick={() => setOption('createQuizz')}><FiMessageCircle /> {translate("Criar Enquete")}</nav>
                        <nav onClick={() => setOption('listQuizz')}><FiMessageSquare /> {translate("Listar Enquetes")}</nav>
                        <nav onClick={() => setOption('sorteio')}><FaGift /> {translate("Sorteio")}</nav>
                        <nav onClick={() => setOption('popup')}><FaImage /> {translate("Popup")}</nav>
                        <nav onClick={() => setOption('roomMessage')}> <FaPenSquare /> {translate("Mensagem da Sala")}</nav>
                        <nav onClick={() => setOption('changeRoom')}><FiLoader /> {translate("Redirecionar Sala")}</nav>

                    </div>

                </div>
            </Modal> : <></>} </> : <></>}

        {user && user.profile === 1 && (
            <>


                <RoomChangeCreator
                    active={option === 'changeRoom' ? true : false}
                    setOption={setOption}
                    room_id={currentRoom?._id || ''
                    }
                    module_id={1}
                />
            </>
        )}

        {user &&
            (user.profile === 1 ||
                user.profile === 4 ||
                user.profile === 6) && (

                <>
                    <QuizzComponent
                        active={option === 'createQuizz' ? true : false}
                        setOption={setOption}
                        activeList={option === 'listQuizz' ? true : false}

                        roomNumber={
                            currentRoom._id
                        }
                    />
                    <SorteioRoom id={currentRoom?._id || '0'}
                        active={option === 'sorteio' ? true : false}
                        setOption={setOption}

                    />
                    <RoomMessagerCreator
                        room_id={
                            currentRoom?._id || ''
                        }
                        active={option === 'roomMessage' ? true : false}
                        setOption={setOption}
                        module_id={1}
                    />
                </>
            )}
        <WebsitePopupComponent
            active={option === 'popup' ? true : false}
            setOption={setOption}
            roomNumber={
                currentRoom?._id || ''
            }
        />
        <QuizContainerReceiver roomNumber={currentRoom?._id} />

    </Container>
    </>
}

export default RoomConfiguration;