import React from 'react';
import { useLanguage } from "../../../hooks/Language";
import { Children } from "react";
import { ContainerLineUI } from "./styles";

interface IProps {
    children?: React.ReactNode;
    style?: Record<string, any>;
    theme?: 'dark' | 'light';
    className?: string;
}

const ContainerLine: React.FC<IProps> = ({ children, style, theme, className }) => {
    const { translate } = useLanguage();

    return <ContainerLineUI className={className} themeValue={theme} style={style}>{translate(children)}</ContainerLineUI>;
}

export default ContainerLine;