import userEvent from '@testing-library/user-event';
import React, { useState, useEffect } from 'react';
import { IconType } from 'react-icons';
import { FaEye } from 'react-icons/fa';
import { FiLoader } from 'react-icons/fi';
import { useAuth } from '../../../../../hooks/Auth';
import { useModal } from '../../../../../hooks/Modal';
import { useSocket } from '../../../../../hooks/Socket';
import { useToast } from '../../../../../hooks/Toast';
import api from '../../../../../services/api';
import { Container } from './styles';

interface ICounterCard {
  room: string;
}

const CounterCardRoomSocket: React.FC<ICounterCard> = ({
  room

}) => {
  const [counter, setCounter] = useState('0');
  const [loading, setLoading] = useState(false);

  const { socket } = useSocket();

  const { user } = useAuth();
  const { addModal } = useModal();
  const { addToast } = useToast();

  useEffect(() => {
    socket?.off('usersRoomList');
    socket?.on('usersRoomList', (data) => {

      try {
        const users: Array<Record<string, any>> = [];

        Object.keys(data).map((key: string) => {

          users.push(JSON.parse(data[key]));

        });

        const content: Array<JSX.Element> = [];

        users.sort((a, b) => a.name > b.name ? -1 : b.name > a.name ? 1 : 0);

        users.map(elem => {

          content.push(<tr style={{ border: '1px solid #333' }}><td><img src={user?.image ? user?.image : "/apoio/user.jpg"} style={{ width: '50px', borderRadius: '50%', margin: '10px' }} /></td><td><h2 style={{ color: '#333', fontSize: '14px' }}>{elem.name}</h2></td></tr>);

        });

        addModal({ title: 'Lista de participantes', theme: 'whiteModal', content: <table style={{ width: '100%', borderCollapse: 'collapse', maxWidth: '450px', margin: '0px auto' }}><tbody>{content}</tbody></table> });


      }
      catch (err) {
        addToast({ title: 'Erro ao disponibilizar dados' });
      }


    })
    socket?.off('usersRoomCount');
    socket?.on('usersRoomCount', (data) => {

      setCounter(data);
    })
  }, []);

  useEffect(() => {
    const item = setInterval(() => {
      if (room) {
        socket?.emit('getRoomReport', { room });
      }
    }, 2000)

    return () => clearInterval(item);
  }, [room])


  const getRoomList = (roomNumber: string) => {

    socket?.emit('getRoomList', { room: roomNumber });
  }

  return (
    <>
      {user && (user.profile === 1 || user.profile > 2) && <div onClick={() => getRoomList(room)} style={{ margin: '10px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><div><FaEye /></div><label style={{ marginLeft: '5px' }}>{counter || '0'}</label></div>}
    </>
  );
};
export default CounterCardRoomSocket;
