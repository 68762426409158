import styled from 'styled-components';
import {ContainerRef} from '../styles';

export const Container = styled(ContainerRef)`


margin: 5px 0px;

flex-direction: column;

.display{
  display: flex ;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100% ;
  margin: 5px 0px;
  border: 1px solid #ddd;
 

  .listModules {
    display: flex ;
  align-items: center;
  justify-content: center;
  div{
    width: 30px;
    height: 30px;
    display: flex ;
  align-items: center;
  justify-content: center;
  background: rgb(0,100,150) ;
  color: #fff;
  padding: 0px;
  border-radius: 10px;
  margin: 0px 3px;

  >svg{
    margin: 0px !important ;
  }
  }
}
}
`;

export const Field = styled.div`
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;

  > label {
    margin-bottom: 5px;
    display: flex;
    max-width: 200px;
    min-width: 150px;
  }

  > div {
    width: 100%;
    margin: 0 5px;

    > input,
    > select,
    > textarea {
      margin-top: 0;
    }
  }
`;


export const Fixed = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 150px;
  flex-direction: column;
  color: #fff;

  width: 100%;
  height: 100vh;
  z-index: 20000;

  > button {
    background: #fff;
    color: rgb(0, 100, 200);
    padding: 20px;
    text-align: center;
    padding-top: 20px;
  }

  > h2 {
    font-size: 24px;
  }

  > strong {
    font-size: 18px;
  }
`;
