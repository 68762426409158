import React, { useCallback, useRef, useEffect, useState } from 'react';
import CertificateV1 from '../CertificadosV1';
import CertificateV2 from '../CertificadosV2';
import { useConfiguration } from '../../hooks/Configuration';



const Certificados: React.FC = () => {

  const { configuration } = useConfiguration();

  return configuration?.theme_data?.certificate_version === 'v1' ? <CertificateV1 /> : <CertificateV2 />

};
export default Certificados;
