import styled from 'styled-components';

export const Box = styled.div`
  display: flex !important;
  align-items: center  !important;
  justify-content: center  !important;
  flex-direction: row;
  margin-top: 10px auto;
  width: 100%;
  max-width: 500px !important;

  >div {
    display: flex !important;
  align-items: center  !important;
  justify-content: center  !important;
  flex-direction: column;
  width: 100% ;

  p{
    text-align:center;
    width : 100% ;
  }

  svg{
    margin-bottom: 30px;
    color: #777;
  }
  }

  background: #fff ;
  border-radius: 15px;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
  padding: 20px;
  color: #333;

  font-size: 14px;
  
    > svg {
      min-width: 30px;
      margin-left: 5px;
    }
  }
`;

export const Error = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  > div {
    > svg {
      margin-right: 5px;
    }
    padding: 5px 10px;
    width: 100%;
    background: rgb(150, 0, 0);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
  }
`;
