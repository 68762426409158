import Create from './Create';
import Delete from './Delete';
import Update from './Update';
import UpdateUnique from './UpdateUnique';


import UploadSubscribeCertificate from './UploadSubscribeCertificate';

const lineModules = {
    create: Create,
    delete: Delete,
    update: Update,
    updateUnique: UpdateUnique,
    uploadSubscribeCertificate: UploadSubscribeCertificate
}

export default lineModules;