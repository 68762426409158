import React, { useRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import { Container } from './styles';
interface VimeoProps {
  id: string;
  keyName?: string;
  time?: string;
}

const HLSPlayer: React.FC<VimeoProps> = ({ id = '', keyName, time = '0' }) => {
  const player = useRef<ReactPlayer>(null);

  const onReady = React.useCallback(() => {

    if (time) {
      if (player?.current) {
        player?.current?.seekTo(parseInt(time, 10), 'seconds');
      }
    }
  }, [player.current]);

  return (<Container>
    <div
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,

        height: 0,
        width: '100%',
      }}
    >




      <ReactPlayer style={{ width: '100%' }} controls={true} onReady={onReady} ref={player} url={id} />
    </div></Container>
  );
};

export default HLSPlayer;
