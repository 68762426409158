import { date, hour } from './date';
import format from './format';

interface IMasks {
  [key: string]: Function;
}

export default function mask(value: string, maskName: string): string {
  const validMask: IMasks = {
    date: (text: string) => date(text),
    hour: (text: string) => hour(text),
    currency: (text: number) => format(text),
  };

  return validMask[maskName] ? validMask[maskName](value) : value;
}
