import React, { useEffect, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';

interface IProps {
    width: number;
    height: number;
}

const PrintBook: React.FC<IProps> = ({ children, height = 800, width = 600 }) => {

    const [currentStats, setCurrentStats] = useState({ width: 0, height: 0 });

    useEffect(() => {

        if (width > 0) {

            const windowWidth = window.screen.width;
            const windowHeight = window.screen.height;

            let defaultValue = 400;

            if (windowWidth > 1200) {

                defaultValue = 600;
            }

            if (defaultValue > windowWidth) {
                defaultValue = windowWidth;
            }

            const newStats = {
                width: defaultValue,
                height: (defaultValue / width) * height
            }


            setCurrentStats(newStats)
        }

    }, [width, height])


    return (currentStats.width > 0 ?
        <HTMLFlipBook
            className=''
            style={{}}
            startPage={0}
            size="fixed"

            minWidth={100}
            maxWidth={window.screen.width}
            minHeight={100}
            maxHeight={800}
            drawShadow
            flippingTime={500}
            usePortrait={true}
            startZIndex={0}
            autoSize={true}
            maxShadowOpacity={1}
            showCover={false}
            mobileScrollSupport={true}
            clickEventForward={true}
            useMouseEvents={true}
            swipeDistance={10}
            showPageCorners={true}
            disableFlipByClick={false}



            width={currentStats.width} height={currentStats.height}>
            {children}
        </HTMLFlipBook> : <> <p style={{ width: '100%', textAlign: 'center', color: '#fff' }}> Carregando</p></>
    );
}

export default PrintBook;