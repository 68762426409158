import styled from 'styled-components';
import { shade } from 'polished';
import { defaultTheme } from '../../config';

export const Button = styled.button`
 
 

  border-radius: 54px;
  border: 0;
  padding: 5 10px;
  min-width: 140px;

  font-weight: 500;
  margin: auto;
  margin-top: 5px;
  font-size: 12px;

  transition: 0.2s all;
 
  border-radius: 54px;
  
  box-shadow: rgb(7 13 28 / 25%) 0px 9px 90px;
  &:hover {
 
  }
`;
