import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useAuth } from '../../hooks/Auth';
import { VideoProvider } from '../../hooks/Video';
import ClassRoomContainer from '../../components/ClassRoomContainer';

import { apiUrl } from '../../config';
import { LoaderWithElement } from '../../components/Loader';
import { useSocket } from '../../hooks/Socket';
import Button from '../../components/Button';

import { useToast } from '../../hooks/Toast';
import { Container } from '../../components/ClassRoomContainer/styles';
import SignInReload from '../SignInCientificLibrary';
import { useConfiguration } from '../../hooks/Configuration';

const ClassRoom: React.FC = () => {
  const { token, user } = useAuth();
  const { socket } = useSocket();
  const { addToast } = useToast();
  const [online, setOnline] = useState(0);
  const [removeUser, setRemoveUser] = useState(false);
  const { configuration } = useConfiguration();



  if (!user) {

    console.log('não achou o usuário')

    return <Container style={{ minHeight: '70vh', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>


      <SignInReload />




    </Container>;


  }


  const options = {
    default: () => <ClassRoomContainer />,

  }

  return removeUser ? (
    <LoaderWithElement
      message={
        <>
          <p>Você foi desconectado</p>
          <Button type="button" onClick={() => window.location.reload()}>
            Entrar novamente
          </Button>
        </>
      }
    />
  ) : (
    <VideoProvider>
      {configuration?.video_type && options?.[configuration?.video_type] ? options?.[configuration?.video_type]() : <ClassRoomContainer />}
    </VideoProvider>
  );
};

export default ClassRoom;
