import styled from 'styled-components';

export const ProductsContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;
  > aside {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: stretch;
    width: 100%;

    @media (max-width: 740px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    > div {
      padding: 40px 20px;
      min-width: 300px;
      margin: 10px;
      background: white;
      color: #333;

      > h2 {
        font-size: 44px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: 500;
      }

      > h3 {
        font-size: 32px;
        margin-bottom: 10px;
        font-weight: 300;
        text-align: center;
        font-family: Arial, Helvetica, sans-serif;
      }

      > button {
        background: rgb(0, 100, 150);
        color: #fff;
        border: 0;
        transition: background 0.2s;
        padding: 15px;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        &:hover {
          background: rgb(0, 90, 130);
        }
      }

      > ul {
        > li {
          list-style: none;
          display: flex;
        }
      }
    }
  }

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
`;
