import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { useAuth } from '../../../hooks/Auth';

const PageLayout: React.FC = ({ children }) => {
  const { token, user } = useAuth();



  return <Wrapper>{children}</Wrapper>;
};

export default PageLayout;
