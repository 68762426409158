import React from 'react';
import { useLanguage } from "../../../hooks/Language";
import { Children } from "react";
import { ButtonUI } from "./styles";

interface IProps {
    children?: React.ReactNode;
    style?: Record<string, any>;
    theme?: 'dark' | 'light';
    className?: string;
    type?: 'button' | 'submit',
    onClick?: Function;
}

const Button: React.FC<IProps> = ({ children, style, theme, type, className, onClick }) => {
    const { translate } = useLanguage();

    return <ButtonUI themeValue={theme} style={style} type={type} onClick={() => onClick ? onClick() : false} className={className}>{translate(children)}</ButtonUI>;
}

export default Button;