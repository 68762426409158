import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { useInteraction } from '../../../../hooks/Interactions';
import {FavoriteDiv} from './styles';
interface IProps {
  type : string;
  subtype : string;

  id : string | number;
  addCallback ?: Function;
};

/*
  hook => interactions
*/

const Favorite: React.FC<IProps> = ({  type, subtype, id, addCallback = () => {} }) => {

  const {interactions,addInteraction} = useInteraction();

  const [active,setActive] = useState(false);
  
  
  useEffect(() => {

   
    const isActive = interactions && interactions[type] && interactions[type][id] &&  interactions[type][id][subtype] ? true : false;
   
    setActive(isActive);
  

  },[type, subtype, id])

 
  
  const interactionRef = {
    star : { render : () => <FavoriteDiv onClick={() => { setActive(!active); addInteraction({ type, subtype, id, value : 1 }); addCallback()}}><FaStar style={{color: active ? 'yellow' : '#fff'}} /></FavoriteDiv> }
  }

  return interactionRef[subtype] ? interactionRef[subtype].render() : <></>;
};

export default Favorite;
