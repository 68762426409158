import { defaultTheme } from 'react-select';
import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

const fade = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotate = keyframes`
from {

  transform: rotate(0deg);
}
to{
 
  transform:rotate(180deg);
}
`;

const rotateBack = keyframes`
from {

  transform: rotate(180deg);
}
to{
 
  transform:rotate(360deg);
}
`;

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: block;
  background: none;
  color: #fff;
  padding: 10px;
  width: 100%;
  max-width: 1000px;

 // border: 2px solid rgb(0,15,64);
  margin: 40px auto;
  background: rgba(255,255,255,1);
  padding: 30px 20px;

  @media (max-width: 750px) {
    padding: 30px 0px;
  }


.defaultButtonMini{
  @media (max-width: 750px) {
    font-size: 12px !important;
  }

 
}

  .miniPrograma {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    margin-top: 5px;
color: #fff !important;
p, strong{
  color: #fff !important;
}
    strong{
      color : #fff;
    }
    > figure {
      max-width: 100%;
      margin: 5px 0;
      width: 100%;
      > table {
        width: 100%;
        border-collapse: collapse;
        max-width: 100%;
        > tbody {
          max-width: 100%;
          > tr {
            border: 1px solid #ddd;
            padding: 5px 10px;
            > td {
              font-size: 10px;
              font-weight: 400;
              padding: 3px;
              min-width: 100px;
            }
            > td + td {
              
              width: 100%;
            }
          }
        }
      }
    }
  }


  .hover {
    >svg{
     animation: ${fade} 0.5s forwards;
    }
  }

  .rotateSVGBack {
    >svg{
     animation: ${rotateBack} 0.5s forwards;
    }
  }

  .rotateSVG{
    animation: ${rotate} 0.5s ease-in forwards;
  }

  > h2, >h1 {
    display: flex;
    justify-content: center;
  }

  >h1{
    font-size: 24px;
    margin-top: 20px;

    @media (max-width: 750px) {
      font-size: 18px;
  }
  }

  table {
    width: 100%;
  }

  tr{
    transition: 0.5s all;
    background: rgb(0,15,64);

    .hourDiv{
      max-width: 140px;
      min-width: 140px;
      @media (max-width: 750px) {
        max-width:85px;
        min-width: 85px;
  }

    }

    .optionDiv{
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 750px) {
        flex-direction: column;
  }
    }

    &:hover{
      
      background: rgba(0,14,64,0.9);
      box-shadow:  2px 2px 5px rgba(0,0,0,0.9);
    }

    >td {
      padding: 15px 10px !important;
    }
  }

  td { 
    padding: 10px 5px;
  }

  .miniPrograma {
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    margin-top: 5px;
color: #fff !important;
p, strong{
  color: #fff !important;
}
    strong{
      color : #fff;
    }
    > figure {
      max-width: 100%;
      margin: 5px 0;
      width: 100%;
      > table {
        width: 100%;
        border-collapse: collapse;
        max-width: 100%;
        > tbody {
          max-width: 100%;
          > tr {
            border: 1px solid #ddd;
            padding: 5px 10px;
            > td {
              font-size: 10px;
              font-weight: 400;
              padding: 3px;
              min-width: 100px;
            }
            > td + td {
              
              width: 100%;
            }
          }
        }
      }
    }
  }

  button.defaultButtonMiniProgram {
         margin-top: 0px !important;
         padding: 5px !important;
      width:auto !important;
      min-width:auto !important;

      text-align: center !important;
      justify-content: center !important;
      background: rgb(0,15,64)  ;
      color: #fff  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: rgb(0,15,64)  !important;
      }
    }

  .programDayHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;

      @media (max-width: 750px) {
    font-size: 14px;
  }
    }


  }

  @media (max-width: 750px) {
    padding: 5px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;

    > tbody {
      > tr {
        border-top: 1px solid #555;
        padding: 5px 10px;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;
