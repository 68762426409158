import React from 'react';

import Default from './Default';
import Library from './Library';
import LibraryBig from './LibraryBig';
import LibraryCientific from './LibraryCientific';
interface IVideos {
    before: any;
    waiting: any;
    online: any;

}

interface IProps {
    videos: IVideos;
    template: string;
}

const RoomListTemplate: React.FC<IProps> = ({ template = 'default', videos }) => {

    const types = {
        default: <Default videos={videos} />,
        library: <Library videos={videos} />,
        librarybig: <LibraryBig videos={videos} />,
        cientific: <LibraryCientific videos={videos} />,
    }

    return types?.[template] ? types?.[template] : types.default;


}

export default RoomListTemplate;