import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import { FaPlus, FaTrash } from 'react-icons/fa';


interface IListInput {
  label: string;
  name: string;
  value?: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  placeholder: string;
}

const JsonList: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => { },
  list,
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);


  const [value, setValue] = useState<Array<IListInput>>(defaultValue || []);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
    });
  }, [fieldName, registerField]);

  const addInput = () => {
    const newHash = `q${new Date().getMilliseconds()}`;
    setValue([...value, { name: newHash, label: '', value: '' }]);
  }

  const removeItem = (index) => {

    const items = [...value];
    const getItems: Array<IListInput> = [];

    items.map((i, indexItem) => {
      if (index !== indexItem) {
        getItems.push(i);
      }
    })

    setValue([...getItems]);

  }



  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <button type="button" onClick={addInput}>
        <FaPlus /> Adicionar
      </button>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />
      {value && value.map((v, index) => {
        const inputValue = () => {

          if (defaultValue) {
            const index = defaultValue.findIndex(defa => defa.name === v.name);
            if (index >= 0) {
              return defaultValue[index].value;
            }
          }
          return '';
        };
        return (
          <Container
            key={v.name}
            {...rest}
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocus}
          >

            <input
              placeholder={v.label}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={inputValue()}
              onChange={e => {
                const newValue = [...value];
                const index = newValue.findIndex(n => n.name === v.name);

                if (index >= 0) {
                  newValue[index].value = e.target.value;



                  setValue([...newValue]);
                }
              }}
              name={v.name}
            />
            {error && (
              <Error title={error}>
                <FiAlertCircle />
              </Error>
            )}
            <FaTrash onClick={() => removeItem(index)} />
          </Container>
        );
      })}

    </div>
  );
};

export default JsonList;
