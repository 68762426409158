import React from 'react';
import { IconType } from 'react-icons';
import { FiCopy } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';
import { useLanguage } from '../../hooks/Language';
import { useToast } from '../../hooks/Toast';
import { Button } from '../Button/styles';
import Whatsapp from '../Whatsapp';

// import { Container } from './styles';
interface IProps {
  text : string,
  Icon ?: IconType
}

const CopyToClipboard: React.FC<IProps> = ({ text, Icon = FiCopy }) => {

  const { user} = useAuth();
  const {translate} = useLanguage();
  const {addToast} = useToast();
  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string);
    addToast({type: 'success', 'title': translate('Copiado para o Clipboard')})

  }



  return (
    <div   style={{  cursor:'pointer', border: '0px solid #fff', borderRadius: '10px',   display: 'flex', alignItems: 'center'}}  onClick={() => copyToClipboard(text)}>
      <Icon size={25}/>
    </div>
  );
};

export default CopyToClipboard;
