import styled, { keyframes,css } from 'styled-components';

const fadeFromRight = keyframes`
from {
  opacity: 0;
  transform: translateX(50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

interface IProps {
  full ?: boolean;
}
export const Container = styled.div<IProps>`
  min-height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 0px;
  background: none;
  width: 100%;

  ${props => props.full === true && css`
  
  

  >button{
    position: fixed;
  top: 0px;

  left: 0px;
  z-index: 1010;
  }
`}

  >.zoomer {
    width: 100%;
    padding-bottom: 70%;
    position: relative;
    resize: both;
    resize: both;
  overflow: auto;

  ${props => props.full === true && css`
  
    position: fixed;
    top: 0px;
    height: 100vh;
    padding: 0px;
    width: 70%;
    left: 0%;
    z-index: 1005;

    @media (max-width: 750px) {
      height: 40vh;
    padding: 0px;
    width: 100%;
  }

    >button{
      position: fixed;
    bottom: 5px;

    left: 5px;
    z-index: 1010;
    }
  `}

  @media (max-width: 750px) {
    padding-bottom: 120%;
  }

    >iframe {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;

    }


  }
`;

export const Header = styled.div`
  width: 100%;
  background: none;
  color: #333;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    max-width: 400px;
    form {
      display: flex;

      > input {
        border: 0;
        padding: 20px;
        background: #eee;
        color: #333;
      }
      > button {
        border: 0;
        padding: 20px;
        background: rgb(0, 100, 150);
        color: #fff;
      }
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;

  > table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    table-layout: auto;
    background: white;
    margin-top: 10px;

    tr {
      td,
      th {
        border: 1px solid #ddd;
        text-align: left;
        color: #333;
        padding: 5px;
      }
    }

    tr + tr {
      border-top: 1px solid #999;
    }
  }
`;
