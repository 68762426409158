import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { Container } from './styles';
import { hour, dateLanguage } from '../../utils/date';
import api from '../../services/api';
import Vimeo from '../Vimeo';
import Helmet from 'react-helmet';
import PosterZoomComponent from '../PodcastLibraryRoom';
import { Button } from '../Button/styles';
import { rgb, rgba } from 'polished';
import Favorite from '../ClassRoomContainer/modules/Favorite';
import { FaBook, FaPlay, FaSearch, FaStar } from 'react-icons/fa';
import { useLanguage } from '../../hooks/Language';
import { string } from 'yup';
import themeDefault from '../../styles/ThemeComponents';
import { urlWebsite } from '../../config';
import { useAuth } from '../../hooks/Auth';
import { useParams, useHistory } from 'react-router-dom';

interface IPoster {
  _id: string;
  keywords: Array<string>;
  star?: number;
  title?: string;
  nested_authors?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  eixo?: string;
  pdf?: string;
  pdf_image?: Array<Record<string, any>>;
  time_to_read?: string;
}

interface IOption {
  page: number;
  order: Record<string, string>;
  where: Record<string, any>;
  eixo?: string;
}

interface IStars {
  stars: number;
}



export const track = ({ id = '', title = '', reference = '', track = '', client_id = '', client_name }) => {

  /* ID do trabalho
  titulo do trabalho
  reference é a ação
  track o que é
  
  */

  api.post('/podcast-add-track', { id, title, reference, track, client_id: client_id, client_name, project_id: 'podcast-library' });


}

export const ShowStars: React.FC<IStars> = ({ stars = 5 }) => {

  return <div className='starCase'>

    <FaStar size={15} style={{ color: stars >= 1 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 2 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 3 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 4 ? themeDefault.alternativeButton.background : '#fff' }} />
    <FaStar size={15} style={{ color: stars >= 5 ? themeDefault.alternativeButton.background : '#fff' }} />
  </div>

}

const CientificLibraryComponent: React.FC = () => {
  const [posters, setPosters] = useState<Array<IPoster>>([]);
  const [selectedPoster, setSelectedPoster] = useState<string>('');

  const { posterid, eixo } = useParams<Record<string, any>>();

  const [openPoster, setOpenPoster] = useState(false);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(1);
  const [search, setSearch] = useState('');
  const [haveSelector, setHaveSelector] = useState(false);
  const [category, setCategory] = useState('');
  const [eixos, setEixos] = useState<Array<string>>([]);
  const { user, signOut } = useAuth();
  const [options, setOptions] = useState<IOption>({
    page: 1,
    order: { orderBy: 'createdAt', direction: 'desc' },
    where: {},
    eixo: ''
  });
  const { translate } = useLanguage();


  useEffect(() => {


    const searchValue = new URLSearchParams(window.location.search).get("search");

    loadKeys();



    track({ id: '', title: 'Pesquisa - Podcasts', reference: 'Visita', track: 'Acesso', client_name: user?.name || '', client_id: user?.id || '' })



    if (searchValue) {

      setSearch(searchValue);
      loadSearch({ listOptions: {}, text: searchValue });

    }
    else {

      load();
    }


  }, [])

  useEffect(() => {

    if (posterid) {
      setSelectedPoster(posterid);
      setOpenPoster(true);
    }

  }, [posterid])


  const history = useHistory();


  const changeEixo = (value) => {

    const newOptions = { ...options };

    newOptions.eixo = value;

    setOptions(newOptions);
    if (value) {
      history.push(`/podcasts/categoria/${value}`);
    }
    else {
      history.push(`/podcasts`);
    }

    loadSearch({ listOptions: newOptions });

  }


  const readEixos = (list: Array<Record<string, any>>) => {


    if (eixos.length < 1 && list?.length > 0) {

      let newEixos: Array<string> = [];

      list.map((item) => {

        if (item?.keywords?.length > 0) {
          item.keywords.map((key: string) => {
            const index = newEixos.findIndex(listedKey => listedKey === key);

            if (index < 0) {
              newEixos.push(key);
            }
          })
        }


      })

      newEixos.sort((a, b) => a > b ? 1 : a < b ? -1 : 0);
      setEixos(newEixos);



    }


  }

  const loadSearch = async ({ join = false, page = 0, text = '', listOptions }) => {


    const newOptions = listOptions?.page ? { ...listOptions } : { ...options };

    const string = `search=${encodeURIComponent(
      text || search || '',
    )}&order=${encodeURIComponent(JSON.stringify({ orderBy: 'episode', orderDirection: 'desc' }))}&page=${page > 0 ? page : newOptions.page
      }&where=${encodeURIComponent(JSON.stringify(newOptions.where))}&eixo=${encodeURIComponent(newOptions.eixo || '')}`;



    const response = await api.get(`/list-podcast-library?${string}`);



    if (response && response.data && response.data.rows) {
      const responseData = response.data.rows.reduce((prev, elem) => {
        elem.formatted_date = dateLanguage(elem.date);
        elem.formatted_start_hour = hour(elem.start_hour);
        elem.formatted_end_hour = hour(elem.end_hour);
        if (!elem.keywords) {
          elem.keywords = [];
        }
        prev.push(elem);


        return prev;
      }, []);

      if (join) {
        setPosters([...posters, ...responseData]);
      } else {
        setPosters([...responseData]);
      }

      setCounter(response.data.count / 20 < 1 ? 1 : response.data.count / 20);


      //  readEixos(response.data.rows);

    } else {
      setPosters([]);
      setCounter(1);
    }
    setLoading(false);
  };


  const loadKeys = async () => {
    const response = await api.get(`/podcast-library-load-keys`);

    if (response.data) {

      const items = [...response.data];

      items.sort((a, b) => a > b ? 1 : a < b ? -1 : 0)

      setEixos(items || []);

    }

  }


  const newSearch = () => {
    const newOptions = { ...options };
    newOptions.page = 1;
    setOptions({ ...newOptions });
    loadSearch({ listOptions: {} });
    setSearch('');
  };

  const changeOrder = (value, direction) => {

    const newOptions = { ...options };

    newOptions.order = { orderBy: value, direction };

    setOptions(newOptions);
    loadSearch({ listOptions: newOptions });

  }

  const loadEixos = async () => {
    /* const response = await api.get(`/poster-list-eixo`);


    if(response){
      const eixo : Array<string> = [];
      response.data.map( (r ) => {
        if(r.eixo){
          eixo.push(r.eixo);
        }
      })

      setEixos(eixo);
    } */

  }


  const load = async () => {

    const newOptions = { ...options };
    if (eixo) {


      newOptions.eixo = eixo;

      setOptions(newOptions);
    }


    loadSearch({ listOptions: newOptions });
  };

  useEffect(() => {
    if (eixo) {
      load();
      track({ id: '', title: 'Pesquisa - Podcast', reference: 'Visita', track: `Categoria - ${eixo}`, client_name: user?.name || '', client_id: user?.id || '' })
    }

  }, [eixo])

  useEffect(() => {

    if (window?.location?.pathname?.indexOf('/acessar') < 0 && openPoster) {
      setOpenPoster(false);
      history.push(`/podcasts`);
    }


  }, [window?.location?.pathname]);


  const closePoster = () => {
    setOpenPoster(false);
  };

  const showPoster = poster => {
    setSelectedPoster(poster.url);
    setOpenPoster(true);
  };

  if (openPoster) {
    return (<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <button className='defaultButton' style={{ margin: '10px auto', width: '100%', maxWidth: '400px' }} onClick={() => { setOpenPoster(false); history.push("/podcasts"); }}>Acessar a Lista de Podcasts</button>
      <PosterZoomComponent
        closePoster={closePoster}
        posterID={selectedPoster}
        closeFunction={() => setOpenPoster(false)}
      />



    </div>
    );
  }

  const addPage = () => {

    setOptions((state) => {
      state.page += 1;
      loadSearch({ join: true, page: state.page, listOptions: {} });
      return state
    });


  };

  const changeCategory = (column, categoryName) => {
    const newOptions = { ...options };
    if (categoryName === '') {
      newOptions.where = {};
      setOptions({ ...newOptions });

      loadSearch({ listOptions: newOptions });
      if (categoryName === 'have_video') {
        setCategory(categoryName);
      }
      return;

    }


    const newCategory =
      newOptions.where && newOptions.where[column] === categoryName
        ? ''
        : categoryName;
    newOptions.page = 1;
    if (newCategory === '' && newOptions.where[column]) {
      delete newOptions.where[column];
    } else {
      newOptions.where[column] = newCategory;
    }

    setOptions({ ...newOptions });

    loadSearch({ listOptions: newOptions });
    setCategory(newCategory);
  };

  const addAndSearch = (text) => {



    loadSearch({ text, listOptions: {} });

  }

  const getColor = categoryName => {
    if (categoryName === 'Apresentação Oral') {
      return 'borderBlue';
    }
    if (categoryName === 'Pôster Comentado') {
      return 'borderPurple';
    }
    if (categoryName === 'Pôster Simples') {
      return 'borderGreen';
    }
  };

  const makeSearch = (value) => {

    setSearch(value);
    loadSearch({ listOptions: {}, text: value });

  }

  return (
    <Container>
      <div className="posterSelector">

        {haveSelector && <>
          <Button
            className={category === '1' ? 'active' : ''}
            onClick={() => changeCategory('have_video', '1')}
            type="button"
          >
            Pôster
          </Button>
          <Button
            className={category === '2' ? 'active' : ''}
            onClick={() => changeCategory('have_video', '2')}
            type="button"
          >
            Apresentação Oral
          </Button></>}



        <div className='posterHeader' >

          <h1>Galeria de Podcasts</h1>
          <div className='posterSearch'>
            <select onChange={(e) => { changeEixo(e.target.value); }} style={{ minWidth: '250px' }}>

              <option selected={options?.eixo === '' || eixo === '' ? true : false} value={''}>{translate('Selecionar especialidade')}</option>
              {eixos.map(eixoValue => <option selected={eixoValue && (options?.eixo === eixoValue || eixo === eixoValue) ? true : false} value={eixoValue}>{eixoValue}</option>)}

            </select>


            <Form
              style={{ display: 'flex', alignItems: 'stretch', background: 'none' }}
              onSubmit={newSearch}
            >


              <input
                name="pesquisa"
                type="text"
                placeholder={`${translate('Escreva para pesquisar')}...`}
                value={search}
                onChange={e => { makeSearch(e.target.value) }}
              />

              <button type="submit"><FaSearch /></button>
            </Form>

          </div>
        </div>
      </div>
      <div className="mainPoster">
        {!loading && posters.length < 1 && <h2>Nenhum podcast encontrado</h2>}
        {posters.map(poster => {
          return (<div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', position: 'relative' }}>
            <div key={`posterDiv${poster._id}`} style={{ position: 'relative' }}
              className={`posterDisplay ${getColor(poster.category)}`}
              onClick={() => showPoster(poster)}
            >
              <div className='posterImageDiv'>
                <img key={`img-${poster._id}`}
                  className="posterImage"
                  src={poster.image ? `${urlWebsite}/${poster.image}` : '/apoio/podcasts.jpg'}
                />
              </div>

              <div className='posterContent'>

                <h2> {poster.title}</h2>


                <p className="posterAuthor">


                  {poster.nested_authors}

                </p>

                {poster?.keywords?.length > 0 && <div className='posterKeywords'>
                  {poster?.keywords?.length > 0 && poster.keywords.map((keyword, indexKeyword) => {

                    return indexKeyword <= 2 ? <strong>{keyword} </strong> : <></>

                  })}

                </div>}
                <div className='starsTime' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px' }}>
                  {poster?.time_to_read ? <> <ShowStars stars={poster?.star || 5} />  <FaPlay /> <p style={{ fontSize: '12px', minWidth: '130px' }}>{poster.time_to_read} minutos de leitura</p></> : <></>}
                </div>

              </div>
            </div>

          </div>
          );
        })}
      </div>
      <div className="mainFooter">
        {counter > options.page && (

          <button
            className="closeButton"
            type="button"
            style={{ marginBottom: '25px' }}
            onClick={() => addPage()}

          >
            Carregar Mais
          </button>

        )}
      </div>
    </Container>
  );
};
export default CientificLibraryComponent;
