import styled, { keyframes } from 'styled-components';
import themeDefault from '../../styles/ThemeComponents';

interface IBackground {
  background?: string;
}

export const Fixed = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100000;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items:  center;
  background: none;
  color: #fff;
  padding: 0px;
  width: 100%;

  margin: auto;

  .borderBlue {
    border-bottom: 5px solid rgb(0, 100, 150);
  }

  .borderPurple {
    border-bottom: 5px solid rgb(100, 0, 150);
  }

  .borderGreen {
    border-bottom: 5px solid rgb(0, 150, 50);
  }

  .posterSelector {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    
    > form {
      > input {
        padding-left: 10px;
      }

      margin: 0 5px;
      > button {
        margin: 0;
        font-size: 12px;
      }
    }

    > button.active {
      background: #fff !important;
      color: #026271 !important;
    }

    > button {
      margin: 0;
      font-size: 12px;

      @media (max-width: 750px) {
        min-width: auto;
        margin-bottom: 10px;
      }
    }
  }

  

  .posterHeader{
    display: flex ;
    align-items: center;
    justify-content: space-between;
    width: 100% ;
    max-width: 1200px;
    margin-top: 50px;

    >h1{
      font-size: 24px;
      color: ${themeDefault.textComponent.title};
    }

    @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      }

    .posterSearch {
      display: flex ;
    align-items: center;
    justify-content: flex-end;

    >select {
      width: 180px;
      min-height: 33px;
      border-radius: 5px;
      margin: 5px;
      padding: 0px 10px;
      border: 0px;
    }

    input{
      width: 250px;
      min-height: 33px;
      border-radius: 5px;
      margin: 5px;
      padding: 0px 10px;
      border: 0px;
    }

    button{
      width: 30px;
      min-height: 33px;
      border-radius: 5px;
      margin: 5px;
      padding: 0px 10px;
      border: 0px;
      background:#f15a23 ;

      >svg{
        min-width: 20px;
        color: #fff;
      }
    }
    @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
      }
    }
  }

  .mainPoster {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    cursor: pointer;

 

    color : ${themeDefault.color};



  @media (max-width: 750px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  }

  .mainFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .posterDisplay {
    max-width: 450px;
    width: 100% ;
    min-width: 300px;
    margin: 10px;
    background: none;

    display: flex ;
    justify-content: flex-start;
    align-items: center;

    color: #fff;
    padding: 10px;
  }



  .posterTitle {
    font-size: 8px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    font-weight: bold;
  }

  .posterAuthor,
  .posterCoAuthor {
    font-size: 8px;
    width: 100%;
   
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .posterImage {
    width: 100%;
    max-width: 100px;
    border-radius: 15px;
  }

  .posterImageDiv{
    overflow-y: hidden ;
    height: 131px;
    width: 100px;
    min-width: 100px;
    border-radius: 10px;
  }

  .posterContent {
    padding: 20px;
    display: flex ;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    h2{
      font-size: 12px;
      color: ${themeDefault.textComponent.title.color};
    }

    p{
      font-size: 12px;
      color: ${themeDefault.textComponent.paragraph.color};
    }
  }


  .posterKeywords{
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    >strong{
      padding: 2px 5px ;
      background: #f15a23;
      border-radius: 3px;
      margin-right: 3px;
      font-size: 9px;
      font-weight: bold;
    }
  }

  .starCase{
    display: flex ;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 10px;

    >svg{
      margin-right: 3px;

    }
  }

  > h2 {
    display: flex;
    justify-content: center;
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 750px) {
    padding: 0px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    > tbody {
      > tr {
        border-top: 1px solid #555;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  button{
    background: #333;
  }
`;
