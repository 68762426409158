import styled from 'styled-components';

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  > div {
    > svg {
      margin-right: 5px;
    }
    padding: 5px 10px;
    width: 100%;
    background: rgb(150, 0, 0);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
  }
`;
