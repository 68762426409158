import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';

import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/Configuration';
import api from '../../../services/api';
import Loader from '../../DashboardPages/core/components/Loader';
import { loadFromJson } from '../../../utils/loadFromJson';
import { hash } from '../../../config';

interface IParams {
  projectHash: string;
}


const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu, prepareMenu } = usePages();

  const { token, user, handleApiErrors } = useAuth();
  const { setConfiguration } = useConfiguration();

  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);



  const { projectHash } = useParams<IParams>();

  const load = async () => {
    // setLoading(true);

    try {
      let data: Record<string, any> = {};

      const project = await loadFromJson({ hash: hash, project_id: projectHash, table: 'Project' });

      if (project?.[0]) {
        data = project[0];

        const event = await loadFromJson({ hash: hash, project_id: data.url, table: 'Event' });
        if (event?.[0]) {


          data.current_event_id_response = { ...event[0] };
        }
        else {

          const response = await api.get(`/get-project-content/${projectHash}`);
          data = response?.data;
        }
      }
      else {

        const response = await api.get(`/get-project-content/${projectHash}`);

        data = response?.data;
      }

      setConfiguration(data);



      if (data?.current_event_id_response?.active_pages?.length > 0) {

        prepareMenu({ projectHash, active_pages: data?.current_event_id_response?.active_pages });
      }



      setLoading(false);
      setReady(true);

    }
    catch (err) {
      handleApiErrors(err);
      setLoading(false);
    }
  }


  useEffect(() => {
    if (projectHash) {
      load();
      window.scrollTo(0, 0);
    }
    else {
      setLoading(false);
      setReady(true);
      setConfiguration({});
    }
  }, [projectHash])



  return (
    <>
      {loading && <Loader message='Carregando' />}
      <Wrapper>

        <Menu menuList={menu} fixedMenu={false} />
        {ready && <>
          {children}

          <PopupComponent />


        </>}
      </Wrapper>
      <FooterLayout />
    </>
  );
};

export default DefaultLayout;
