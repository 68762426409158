import styled from 'styled-components';

interface ContainerProps {
  position: 'fixed' | 'absolute' | 'relative';
}

export const Container = styled.div<ContainerProps>`
  background: rgba(0,0,0,0.7);
  display: flex;
  height: 100vh;
  position: ${props => props.position};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  > p, h3 {
    color: #fff !important;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }
    img {
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
  }
`;
