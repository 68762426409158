import styled from "styled-components";

export const Container = styled.div`

width: 100%;

.video {
    >div{
        width: 100% !important;
        height: auto !important;
    }
}

`;