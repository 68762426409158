import React, { useState, useEffect, useRef } from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR/index.js';
import { Form } from '@unform/web';
import { FiHeart, FiMessageSquare } from 'react-icons/fi';
import { useAuth } from '../../hooks/Auth';
import {
  Container,
  Body,
  Message,
  Footer,
  OnlineUser,
  Info,
  Faces,

  Replies,
  MessageBox
} from './styles';
import { useSocket } from '../../hooks/Socket';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import { FaReply, FaThumbsUp } from 'react-icons/fa';
import { cpuUsage } from 'process';
import { config } from 'react-spring';
import ImageSlider from '../ImageSlider';
import { useLanguage } from '../../hooks/Language';
import { useVideo } from '../../hooks/Video';
import { useInteraction } from '../../hooks/Interactions';
import api from '../../services/api';

interface ISlide {
  url: string;
  link_url: string;
}

interface IMessage {
  id: number;
  chat_id: string;
  message: string;
  createdAt: string;
  created_at: string;
  user_id: string;
  user_name: string;
  formatted_date?: string;
  message_from_id?: number;
  message_from_name?: string;
  user_category?: number;
}

interface ISendMessage {
  id: number;
  message: string;
}

interface ICurrentPrivateChat {
  userID: number;
  userName: string;
}

interface IUserReference {
  hash: string;
  name: string;
}

interface TextMessage {
  hash: string;
  message: string;
  user_name: string;
  user_category: number;
  date: Date;
  likes: Record<string, IUserReference>;
  loves: Record<string, IUserReference>;
  score: number;
}

interface IMessageInfo {
  hash: string;
  user_name: string;
  user_category: number;
  message: string;
  replies: Record<string, TextMessage>;
  likes: Record<string, IUserReference>;
  loves: Record<string, IUserReference>;
  score: number;
}

interface IConfig {
  reply?: number,
  like?: number,
  love?: number

}

interface IChatRoom {
  id: any;
  title?: string;
  config?: IConfig;
  slides?: Array<ISlide>;
  background?: string;
  zoom_id?: string;
  room_name?: string;
  poster_id?: number;
  setCallback?: Function;
}

interface IText {
  text: string;
}

interface IUsers {
  id: number;
  name: string;
  lastMessage?: string;
}

interface IListPrivateChatMessages {
  data: Array<IMessage>;
  chat_id: string;
}

interface IMessageContainer {
  content: IMessageInfo;
  updateMessage: Function;
  updateReplyMessage: Function;
  createReply: Function;
  config: IConfig;
}

interface IReplyMessage {
  content: Record<string, TextMessage>;
  updateMessages: Function;
  config: IConfig;
}


const modelMessage = (userInfo) => {
  return {
    hash: `t${new Date().getTime()}${uuid()}`,
    user_name: userInfo.name,
    user_category: 2,
    message: '',
    replies: {},
    likes: {},
    loves: {}
  }
}

const RenderMessageReply: React.FC<IReplyMessage> = ({ config, content, updateMessages }) => {

  return (<Replies>
    {content && Object.keys(content).map(messageKey => {
      const message = content[messageKey];


      const loveLenght = message?.loves ? Object.keys(message.loves).length : 0;
      const likeLenght = message?.likes ? Object.keys(message.likes).length : 0;

      return (
        <div key={message.score}>
          <div className="messageContent"><p><strong style={{ color: message?.user_category !== 2 ? 'rgb(200,100,0)' : '#333' }} >{message?.user_name}:</strong> {message?.message}  </p>
            <div className="messageModules">
              {config.love === 2 && <div onClick={() => { updateMessages({ replyHash: message.score, option: 'loves' }) }}><FiHeart /> {loveLenght > 0 ? `(${loveLenght})` : ''} </div>}
              {config.like === 2 && <div onClick={() => { updateMessages({ replyHash: message.score, option: 'likes' }) }}> <FaThumbsUp /> {likeLenght > 0 ? `(${likeLenght})` : ''} </div>}
            </div>
          </div>

        </div>)
    })}
  </Replies>)

}

const RenderMessage: React.FC<IMessageContainer> = ({ config, content, createReply, updateMessage, updateReplyMessage }) => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [showReply, setShowReply] = useState(false);
  const [showReplyText, setShowReplyText] = useState('');
  const { translate } = useLanguage();
  const sendReply = () => {

    if (showReplyText) {

      const newMessage = modelMessage(user);
      newMessage.message = showReplyText;

      createReply(content.score, newMessage);
      setShowReplyText('');
      setShowReply(false);

    }
    else {
      addToast({ type: 'info', title: 'A mensagem não pode ser vazia' })
    }

  }

  const updateReplyStatus = ({ replyHash, option }) => {


    updateReplyMessage({
      messageHash: content.score,
      replyHash,
      option
    });


  }

  const updateOption = (option: string) => {

    updateMessage({ messageHash: content.score, option, user });

  }


  const loveLenght = content?.loves ? Object.keys(content?.loves).length : 0;
  const likeLenght = content?.likes ? Object.keys(content?.likes).length : 0;

  return (<MessageBox key={content.score}>
    <div className="messageContent"><p><strong style={{ color: content?.user_category !== 2 ? 'rgb(200,100,0)' : '#333' }}>{content?.user_name}:</strong> {content?.message} </p>
      <div style={{ display: 'inline' }}>
        <div className="messageModules">
          {config.like === 2 && <div onClick={() => updateOption('loves')}><FiHeart /> {loveLenght > 0 ? `(${loveLenght})` : ''} </div>}
          {config.love === 2 && <div onClick={() => updateOption('likes')}> <FaThumbsUp /> {likeLenght > 0 ? `(${likeLenght})` : ''} </div>}
          {config.reply === 2 && <div onClick={() => setShowReply(!showReply)} > {translate('Responder')}</div>}</div>
      </div></div>





    {content.replies && config.reply === 2 && <RenderMessageReply config={config} key={`${content.score}-replies`} content={content.replies || {}} updateMessages={(data) => updateReplyStatus(data)} />}
  </MessageBox>)

}

const ChatComponent: React.FC<IChatRoom> = ({ setCallback, id, title = 'Chat', room_name = '', poster_id = 0, zoom_id = '', background = 'rgba(0,0,0,0.3)', slides = [], config = { reply: 2, love: 2, like: 2 } }) => {

  /*
    Objetivos
      Básico
      Listar mensagem

      Criar mensagem
      Dar responder na mensagem
      Dar like na mensagem
      Dar amei na mensagem
  */


  /* render message */
  const { translate } = useLanguage();
  const [textChat, setTextChat] = useState('');
  const formRef = useRef(null);
  const [messages, setMessages] = useState<Array<IMessageInfo>>([]);
  const { addToast } = useToast();

  const { addGame } = useInteraction();


  const { socket } = useSocket();
  const { user } = useAuth();
  const { courses } = useVideo();

  const [sliders, setSliders] = useState([]);
  const [slidersKey, setSlidersKey] = useState('slider');

  const loadSliders = async (reference, poster) => {

    const response = await api.post('/lesson-slides/list', { reference, poster_id: poster });


    if (response) {
      setSlidersKey(uuid());
      setSliders(response.data);
    }

  }

  /* Connect to room */
  useEffect(() => {

    socket?.emit('Module-SimpleChat-Connect', { room: id });

    socket?.on('reconnect', () => {

      socket?.emit('Module-SimpleChat-Connect', { room: id });
    });

    socket?.on(`Module-SimpleChat-LoadMessages-${id}`, (data) => {

      setMessages(data);
    });

    socket?.on(`Module-SimpleChat-MessageCreated-${id}`, (data) => {

      addMessage(data);
    });

    socket?.on(`Module-SimpleChat-UpdatedReply-${id}`, (data) => {
      addUpdateReply(data)
    });

    socket?.on(`Module-SimpleChat-MessageUpdated-${id}`, (data) => {

      addUpdateMessage({ ...data })
    });

    socket?.on(`Module-SimpleChat-CreatedReply-${id}`, (data) => {
      addReply(data);
    });



  }, [id])

  useEffect(() => {

    if (room_name || poster_id) {

      loadSliders(room_name, poster_id);
    }


  }, [id, room_name, poster_id])


  const createReply = (hash, newMessage) => {

    socket?.emit('Module-SimpleChat-CreateReply', { room: id, messageHash: hash, message: newMessage.message });
  }

  const addReply = ({ messageInfo, messageHash }) => {

    /* ignora loop do state */
    let lastMessage = '';

    setMessages(state => {
      const response: Array<IMessageInfo> = [...state];


      const itemMessage = response?.find(r => r?.score === messageHash);
      if (itemMessage) {
        if (itemMessage.replies) {
          itemMessage.replies[messageInfo.score] = messageInfo;
        }
        else {
          itemMessage.replies = { [messageInfo.score]: messageInfo };
        }
      }


      return response;

    })

  }

  const updateReply = ({ messageHash, replyHash, option }) => {

    socket?.emit('Module-SimpleChat-UpdateReply', { room: id, messageHash, replyHash, option });

  }



  const addUpdateReply = ({ messageHash, replyHash, option, userInfo }) => {

    /* ignora loop do state */
    let lastMessage = '';
    let x = 0;
    setMessages(state => {
      const response: Array<IMessageInfo> = [...state];

      if (!lastMessage && x === 0) {

        const messageItem = response.find(r => r?.score === messageHash);

        if (messageItem) {
          const indexReply = messageItem?.replies?.[replyHash];

          if (indexReply && indexReply.score) {

            if (messageItem?.replies?.[indexReply?.score]?.[option]?.[userInfo?.hash]) {
              delete messageItem.replies[indexReply.score][option][userInfo.hash];
            }
            else {
              messageItem.replies[indexReply.score][option][userInfo.hash] = userInfo;
            }


          }
        }
      }
      x = x + 1;
      lastMessage = messageHash;
      return response;
    });

  }
  const addUpdateMessage = ({ score: messageHash, option, userInfo }) => {

    /* ignora loop do state */
    let lastMessage = '';
    let x = 0;
    setMessages((state) => {


      const response: Array<IMessageInfo> = [...state];


      if (x === 0) {

        const messageItem = response.find(r => r?.score === messageHash);

        if (!messageItem) {
          return response;
        }

        if (!messageItem?.[option]) {
          messageItem[option] = {};
        }

        if (option === 'status') {
          messageItem[option] = messageItem[option] === 2 ? 1 : 2;

        }
        else {
          if (messageItem?.[option]?.[userInfo?.hash]) {
            delete messageItem[option][userInfo.hash];
          }
          else {
            messageItem[option][userInfo.hash] = userInfo;
          }
        }



      }
      x = x + 1;
      return response;

    });

  }

  const updateMessage = ({ messageHash, option, userInfo }) => {

    socket?.emit('Module-SimpleChat-UpdateMessage', { room: id, messageHash, option });

  }




  const renderMessages = () => {
    return messages ? Object.keys(messages).map((key) => {
      const content = messages[key];
      return <div onClick={() => setCallback && setCallback(content)}> <RenderMessage config={config} key={`${content.score}-box`} content={content} createReply={createReply} updateReplyMessage={updateReply} updateMessage={updateMessage} /></div>
    }) : <></>
  }



  const addMessage = (data) => {


    /* Insere localmente */
    setMessages(state => ({ [data.score]: data, ...state }));


  }

  const createMessage = () => {

    if (!textChat) {
      addToast({ type: 'info', title: 'Sua mensagem está vazia' })
      return false;
    }
    let message = modelMessage(user);

    message = { ...message, message: textChat };

    /* Envia para o público */
    socket?.emit('Module-SimpleChat-CreateMessage', { room: id, message });

    if (id > 2000 && id < 4000) {
      addGame({ reference: 'mensagem', id: id, points: 1 })
    }

    /* Insere localmente */
    /*    setMessages(state => [message,...state]); */
    setTextChat('');

  }


  return (<>
    <Container background={background}
      style={{ margin: 'auto' }}

    >
      <div className="main">
        {sliders.length > 0 && <ImageSlider key={slidersKey} slides={sliders} />}
        {zoom_id && <> <p style={{ margin: '5px auto', fontSize: '14px', textAlign: 'center' }}>Clique para acessar com audio e video</p>
          <a style={{ margin: '5px auto', display: 'flex', justifyContent: 'center', maxWidth: '200px' }} href={zoom_id} target="_BLANK"> <button style={{ margin: '0px' }} className="defaultButton">Acessar com vídeo</button></a> </>}

        <h2 style={{ color: '#fff', margin: '5px auto' }} >
          <FiMessageSquare />
          {translate(title)}
        </h2>

        <Footer>
          <Form ref={formRef} onSubmit={createMessage}>
            <input
              type="text"
              name="text"
              placeholder={`${translate('Escreva aqui a sua mensagem')}...`}
              value={textChat}
              onChange={e => {
                const { value } = e.target;
                if (value) {
                  setTextChat(value.substr(0, 240));
                } else {
                  setTextChat('');
                }
              }}
            />

          </Form>
        </Footer>
        <Body>{renderMessages()}</Body>
      </div>

    </Container>
    {user.profile === 1 && <div style={{ display: 'none' }}><button onClick={() => socket?.emit('ClearChatCache', { room: id })}>Limpar cache</button><button onClick={() => socket?.emit('ClearChat', { room: id })}>Limpar Chat</button></div>}</>
  );
};

export default ChatComponent;
