import styled from 'styled-components';
import { backgrounds } from 'polished';

interface IContainer {
  background: string;
}

export const Container = styled.div<IContainer>`
  position: relative;
  cursor: pointer;
 
  transition: background 2s;

  background: url(${props => props.background}) no-repeat center;
  background-size: contain;

  width: 100%;
  height: 0px;
  padding-bottom: 56.25%;
`;
