import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Content, Background, AnimatedDiv } from './styles';


 
import api from '../../../../../../services/api';
import { FaImage, FaPlusCircle, FaSpinner, FaTicketAlt, FaTrash } from 'react-icons/fa';
import { useModal } from '../../../../../../hooks/Modal';
import { uuid } from 'uuidv4';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useToast } from '../../../../../../hooks/Toast';
import Loader from '../../../../core/components/Loader';
import { urlWebsite } from '../../../../../../config';
import { setTimeout } from 'timers';
import { useField } from '@unform/core';

interface IProps {
  label : string;
  currentValue ?: Record<string,any>;
  name : string;
}


const UploadCurriculumContent: React.FC<IProps> = ({ label, name  }) => {
  const {addToast} = useToast();
 
  const {addModal} = useModal();
  const [curriculumContent,setCurriculumContent] = useState('');
  const [loading,setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [key,setKey] = useState(uuid());
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,

      getValue: (ref: any) => {
       
        return curriculumContent ? `${urlWebsite}/${curriculumContent}` : '';
      },
    });
  }, [fieldName, registerField,curriculumContent]);

  useEffect(() => {
    
    setCurriculumContent(defaultValue);
  },[defaultValue])

  const uploadPhoto = async ({ file }) => {

    const formData = new FormData();
    formData.append('file', file);
try{
    const response = await api.post('/upload-image', formData);
    if (response) {

     

      await setTimeout(() => {

        

      
        setLoading(false);
        setCurriculumContent(response?.data?.message);

 
     
       
        

     },2000);


    }
    else{

      setLoading(false);
    }
  }catch(err){
    setLoading(false);
    return addToast({ title : err.response.data.message || 'Erro ao realizar upload'})
  }
  };


 

  const uploadAndShow = async ({ event }) => {

    const file = event?.target?.files?.[0];

    if(!file){
      return addToast({title: 'Arquivo não encontrado', type:'info'});
    }

    if(file.size > 999999){
      return addToast({title: 'O tamanho máximo aceito para envio é de 5MB', type:'error'});
    }



    if( file.type !== 'image/jpg' && file.type !== 'image/png' &&  file.type !== 'image/jpeg' &&  file.type !== 'application/pdf'  ){
      return addToast({title: 'São aceitos apenas arquivos em .png, .jpg, .jpeg e .pdf', type:'error'});
    }

    setLoading(true);

    await uploadPhoto({ file});

   
   
     
  }

  const removeItem = ( ) => {

    setCurriculumContent( '');


  }

  const showImage = (url) => {

    addModal({ theme: 'whiteModal', title: ' ' , content: <img style={{width: '100%'}} src={`${urlWebsite}/${url}`}/>})

  }
   

  
 

  return (
    <Container>
      {loading && <Loader message='Carregando'/>}
     
     <div className='curriculum_div' key={`curriculum-div${name}`}>
        
        
        <h3 >{label}  </h3>
 
        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}} onClick={() => setKey(uuid())}> <p style={{width: '100%', textAlign:'center', padding: '10px'}}> Atualizar</p></div>

        <div key={`curriculum_categories_div-${name}`} className='curriculum_categories_div'>

         <div>
         <button type="button" className='uploadButton'> <input type={'file'} onChange={ (e) => { uploadAndShow({ event: e }) }}/> <FaImage size={20}/> Adicionar Imagem
           </button>
            </div>

            <div className='uploadsDiv'>
        { curriculumContent &&  <div className='uploadItem' key={`uploadItem1-${curriculumContent}`}> { curriculumContent.indexOf('.pdf') >= 0 ? <a href={`${urlWebsite}/${curriculumContent}`} download><img   key={key} style={{width: '250px'}} src={`${urlWebsite}/apoio/pdf.png`}/></a> :   <img onClick={() => showImage(`${urlWebsite}/${curriculumContent}`)} key={key} style={{width: '250px'}} src={`${urlWebsite}/${curriculumContent}`}/>} 
          
            <div className='uploadDelete' onClick={() => removeItem( )}><FaTrash/></div>
          </div>
        } 

        </div>
          
          </div>

 


        </div>

 
 
    </Container>
  );
};

export default UploadCurriculumContent;
