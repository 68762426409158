import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import { urlWebsite } from '../../../../../../config';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => {},
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,

      getValue: (ref: any) => {
        console.log(ref.files[0]);
        return ref.files[0];
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      {defaultValue && (
        <a href={`${urlWebsite}/${defaultValue}`} download><img src={`${urlWebsite}/${defaultValue}`} style={{ width: '100px' }} /></a>
      )}
      <Container
        {...rest}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocus}
      >
        <span>{rest.placeholder}</span>
        {Icon && <Icon />}
        <input
          {...rest}
          type="file"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={e => {
            setCallback(e.target.value, formRef);
          }}
          name={name}
          ref={inputRef}
        />
        {error && (
          <Error title={error}>
            <FiAlertCircle />
          </Error>
        )}
      </Container>
    </>
  );
};

export default Input;
