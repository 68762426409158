import styled, { css } from "styled-components";

interface IProps {
    themeValue?: 'dark' | 'light';
}

export const ContainerLineUI = styled.div<IProps>`

 p,h2,h1,strong,span {
     color: #333 !important;
 }

`