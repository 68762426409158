import styled, { css } from "styled-components";

interface IProps {
    themeValue?: 'dark' | 'light';
}

export const ButtonUI = styled.button<IProps>`

font-size: 14px;
border-radius: 15px;
padding : 10px 16px;
background: #fff;
color: #333;
border: 2px solid #ddd;
transition: 0.5s all;

&:hover{
    transform: scale(1.1);
}

p,strong,span{
    font-size: 14px; 
}

 

${props => props?.themeValue === 'dark' && css`
color: #fff;
`}

`