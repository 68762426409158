import React, { useState, useEffect } from 'react';

import { useAuth } from '../../../../hooks/Auth';
import TextPopper from '../../../TextPopper';
import {
  Container,

  Gift,
  Pulsing
} from './styles';

import { useToast } from '../../../../hooks/Toast';

import { FaGift } from 'react-icons/fa';
import api from '../../../../services/api';
import { useLanguage } from '../../../../hooks/Language';


interface IProps {
  id: string | number;
  active?: boolean;
  setOption: React.Dispatch<React.SetStateAction<string>>;
}

const SorteioRoom: React.FC<IProps> = ({ id,
  active = false,

  setOption }) => {
  const [winner, setWinner] = useState({ name: '', email: '' });
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { addToast } = useToast();
  const { translate } = useLanguage();

  const getRandom = (min, max) => {
    return min + Math.floor((max - min) * Math.random());
  }


  const doSorteio = async () => {
    setLoading(true);
    const response = await api.post(`/sorteio/${id}`);

    if (response) {
      setTimeout(() => {
        const { name, email } = response.data;
        setWinner({ name, email });
        setLoading(false);
        return;
      }, 5000);
    }
    else {
      addToast({ title: 'Erro', type: "error" });
      setLoading(false);
      return;
    }



  }

  useEffect(() => {
    if (active) {
      doSorteio();
      setOption('');
    }
  }, [active])


  const animation = () => {

    const colors = [
      'rgb(200,0,0)',
      'rgb(200,200,0)',
      'rgb(200,0,200)',
      'rgb(0,100,200)'
    ];
    const items: Array<JSX.Element> = [];
    let x = 1;
    while (x < 60) {
      items.push(<Pulsing speed={x / 15} background={colors[x % 3]} top={getRandom(0, 100)} left={getRandom(0, 100)} scale={getRandom(0, 20)} />);
      x++;
    }

    return items;
  }

  let x = 1;
  if (!id || id < 1) { return <></>; }
  if (!user) { return <></>; }
  if (user.profile === 2) { return <></>; }

  if (winner && winner.name) {
    return <Gift className='gradientBg'>
      <div>


        <div className="body" style={{ background: '#fff', borderRadius: '10px', padding: '30px' }}>
          <h3 style={{ fontSize: '32px' }}>{translate("Ganhador(a)")}:</h3>
          <h3 style={{ marginTop: '25px', fontSize: '24px', textAlign: 'center' }}>{winner.name}</h3>
          <div className='actionDiv'>
            <button className="actionAdminButton gradientBg" onClick={() => { setWinner({ name: '', email: '' }) }}>{translate("Fechar")}</button>
          </div>
        </div>

      </div>

    </Gift>

  }


  if (loading) {
    return <Gift className='gradientBg'>
      <div>
        {animation()}

        <div className="body">
          <FaGift />
          <h3 className='animatePulse' style={{ marginTop: '60px', fontSize: '28px', textAlign: 'center', color: '#fff' }}>{translate("Realizando sorteio")}!</h3>

        </div>

      </div>

    </Gift>

  }



  return <></>;

};

export default SorteioRoom;
