import styled from 'styled-components';
import {ContainerRef} from '../styles';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled(ContainerRef)`
  div.react-datepicker-popper{
    z-index: 1000;
  }
`;
