import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Modal } from '../../styles';
import { useSocket } from '../../../../hooks/Socket';

import { useToast } from '../../../../hooks/Toast';
import Button from '../../../Button';
import Input from '../../../Forms/Input';
import { useParams } from 'react-router-dom';
import { useVideo } from '../../../../hooks/Video';
import { FiX } from 'react-icons/fi';
import { useLanguage } from '../../../../hooks/Language';
interface ICreator {
  room_id?: string;
  module_id?: number;
  active?: boolean;
  setOption: React.Dispatch<React.SetStateAction<string>>;
}

interface IReceiver {
  room_id?: string;
  module_id?: number;

}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export const RoomChangeContainer: React.FC<IReceiver> = () => {
  const { socket } = useSocket();
  const { projectHash, eventHash } = useParams<IParams>();
  const { courses, openVideoId } = useVideo();
  useEffect(() => {

    socket?.off('getRoomChange');

    socket?.on('getRoomChange', state => {

      if (state.id === 'reload' && state.current_room) {
        window.location.href = `/app/${projectHash}/dashboard/${state.current_room}`;
      }
      else if (state.id) {

        openVideoId(state.id);

        //   
      }
    });
    socket?.off('getRoomChangeAll');
    socket?.on('getRoomChangeAll', state => {

      if (state.id === 'reload' && state.current_room) {
        window.location.href = `/app/${projectHash}/dashboard/${state.current_room}`;
      }

      else if (state.id) {
        openVideoId(state.id);
        //window.location.href = `/app/${projectHash}/dashboard/${state.id}`;
      }
    });
  }, [courses, openVideoId]);

  return <></>;
};

export const RoomChangeCreator: React.FC<ICreator> = ({
  room_id = 0,
  module_id = 1,
  active = false,
  setOption

}) => {
  const { socket } = useSocket();
  const { addToast } = useToast();
  const { translate } = useLanguage();


  const formRef = useRef<FormHandles>(null);

  const handleChange = data => {
    if (!data.destination_room) {
      addToast({ type: 'error', title: translate('Defina uma sala de destino') });
      return;
    }

    if (data.destination_room) {
      socket?.emit('sendRoomChange', { ...data });
      addToast({ type: 'success', title: translate('Enviado com sucesso') });
      setOption('');
    }
  };

  return (
    <>

      {active && (
        <Modal top right fullHeight>
          <div>
            <button type='button' className='closeAdminButton' onClick={() => setOption('')}>{translate("Fechar")} <FiX /></button>

            <Form style={{ marginTop: '25px' }}
              ref={formRef}
              onSubmit={handleChange}
              initialData={{ current_room: room_id, destination_room: room_id }}
            >
              <label style={{ marginLeft: '16px' }}>{translate("Hash Sala Atual (Zero para Global)")}</label>
              <Input
                name="current_room"
                placeholder=""
              />
              <label style={{ marginLeft: '16px' }}>{translate("Hash de destino")}</label>
              <Input name="destination_room" placeholder="" />
              <div className='actionDiv'>
                <button className="actionAdminButton" type="submit">{translate("Enviar")}</button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
};
