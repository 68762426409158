import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 200px;
  padding: 20px;
  background: #fff;

  width: 100%;
  min-height: auto;
  z-index: -1;
`;

export const Background = styled.div``;

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px 10px;
  background: none;
  color: rgb(0, 100, 150);
  min-height: 100px;
  z-index: 200;
  position: relative;
  bottom: 0px;
  left: 0px;

  > p {
    position: relative;
    z-index: 200;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  > div,
  > h2,
  > p {
    max-width: 1200px;
    margin: auto;
  }

  > h2 {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  div {
    p,h2,strong, label{
      color: #333 !important;
    }
  }
`;

export const SponsorGrid = styled.div<IBackground>`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

interface IBackground {
  background?: string;
}
export const Sponsor = styled.div<IBackground>`
  background: url(${props => props.background}) no-repeat center;
  background-size: cover;

  width: 160px;
  height: 105px;
  margin: 5px;

  @media (max-width: 1200px) {
    width: 120px;
    height: 80px;
  }
`;
