import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { Container } from './styles';
import { hour, dateLanguage } from '../../utils/date';
import api from '../../services/api';
import Vimeo from '../Vimeo';

import PosterZoomComponent from '../CientificLibraryRoom';
import { Button } from '../Button/styles';
import { rgb, rgba } from 'polished';
import Favorite from '../ClassRoomContainer/modules/Favorite';
import { FaSearch, FaStar } from 'react-icons/fa';
import { useLanguage } from '../../hooks/Language';
import { string } from 'yup';
import themeDefault from '../../styles/ThemeComponents';
import { urlWebsite } from '../../config';
import { useAuth } from '../../hooks/Auth';
import { useParams } from 'react-router-dom';
import { SpeakersContainer } from '../SpeakersGrid';

interface IPoster {
  id : number;
  keywords : Array<string>;
  star?: number;
  title?: string;
  nested_authors?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  eixo ?: string;
  pdf ?: string;
  pdf_image?: Array<Record<string,any>>;
}

interface IOption {
  page: number;
  order: Record<string,string>;
  where: Record<string, any>;
}

interface IStars {
  stars : number;
}

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  category_id : number;
  id: number;
  category_response ?: Record<any,string>;
  social ?: Record<any,string>;

}

interface ISpeakers {
  title : string;
  position: string;
  order_type : string;
  speakers : Array<ISpeakerData>;
}

export const track = ( { id = '', title = '', reference = '', track = '', client_id = '', client_name }) => {

/* ID do trabalho
titulo do trabalho
reference é a ação
track o que é

*/

   api.post('/add-track', { id, title, reference, track , client_id : client_id, client_name, project_id: 'cientific-library-author' });


}

const CientificLibraryComponent: React.FC = () => {
  const [posters, setPosters] = useState<Array<IPoster>>([]);
  const [selectedPoster, setSelectedPoster] = useState<string>(''  );

  const {posterid} = useParams<Record<string,any>>();

  const [openPoster, setOpenPoster] = useState(false);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(1);
  const [search, setSearch] = useState('');
  const [haveSelector, setHaveSelector] = useState(false);
  const [category, setCategory] = useState('');
  const [eixos, setEixos] = useState<Array<string>>([]);
  const {user} = useAuth();

  const [speakersData, setSpeakerData] = useState<Array<ISpeakerData>>([]);


  
  const [options, setOptions] = useState<IOption>({
    page: 1,
    order: {orderBy : 'title', direction: 'asc'},
    where: {},
  });
const { translate} = useLanguage();

useEffect(() => {

if(posterid){
  setSelectedPoster(posterid);
  setOpenPoster(true);
}

},[posterid])


  const readEixos = ( list : Array<Record<string,any>>) => {


    if(eixos.length < 1 && list?.length > 0){

      let newEixos : Array<string> = [];

      list.map( (item) => {

        if(item?.keywords?.length > 0){
          item.keywords.map( (key : string) => {
            const index = newEixos.findIndex( listedKey => listedKey === key);
            
            if(index < 0){
              newEixos.push(key);
            }
          })
        }


      })

      newEixos.sort( (a,b) => a > b ? 1 : a < b ? -1 : 0);
      setEixos(newEixos);


      
    }


  }

  const loadSearch = async ({join = false, page = 0, text = ''}) => {
    const string = `search=${encodeURIComponent(
      text || search || '',
    )}&order=${encodeURIComponent(JSON.stringify(options.order))}&page=${
      page > 0 ? page : options.page
    }&where=${encodeURIComponent(JSON.stringify(options.where))}`;

    const response = await api.get(`/autores?${string}`);

   

    if (response && response.data && response.data.rows) {
      const responseData = response.data.rows.reduce((prev, elem) => {
        
        prev.push(elem);
       

        return prev;
      }, []);

      if (join) {
        setSpeakerData([...speakersData, ...responseData]);
      } else {
        setSpeakerData([...responseData]);
      }

      setCounter(response.data.count / 20 < 1 ? 1 : response.data.count / 20);


      readEixos(response.data.rows);

    } else {
      setPosters([]);
      setCounter(1);
    }
    setLoading(false);
  };



  const newSearch = () => {
    const newOptions = { ...options };
    newOptions.page = 1;
    setOptions({ ...newOptions });
    loadSearch({});
    setSearch('');
  };

  const changeOrder = (value,direction) => {

    const newOptions = {...options};

    newOptions.order = { orderBy : value, direction};

    setOptions(newOptions);
    loadSearch({});

  }

  const loadEixos = async () => {
    /* const response = await api.get(`/poster-list-eixo`);


    if(response){
      const eixo : Array<string> = [];
      response.data.map( (r ) => {
        if(r.eixo){
          eixo.push(r.eixo);
        }
      })

      setEixos(eixo);
    } */

  }

  const addPage = () => {
 
    setOptions((state) => {
      state.page += 1;
      loadSearch({ join : true,page : state.page});
      return state
    });

    
  };

  useEffect(() => {
    loadEixos();
    const load = async () => {
      loadSearch({});
    };
    load();

    track({ id: '', title: 'Biblioteca - Autores', reference: 'Visita', track: 'Acesso', client_name: user?.name || '', client_id : user?.id || ''})
  }, []);


  return (
    <Container>
      <div className="posterSelector">
   
      

   
      
       <div className='posterHeader' >
        
       <h1>Corpo editorial</h1>
         <div className='posterSearch'> 
    
    
         
        <Form
          style={{ display: 'flex', alignItems: 'stretch', background:'none' }}
          onSubmit={newSearch}
        >
          
           
          <input  
            name="pesquisa"
            type="text"
            placeholder={`${translate('Escreva para pesquisar')}...`}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

        <button   type="submit"><FaSearch/></button>
        </Form>

         </div>
        </div>
      </div>
      <div className="mainPoster">
      {speakersData.length > 0 ? <Container className='shadow'> <SpeakersContainer speakers={speakersData} title={'Corpo Editorial'} /> </Container> : <></>}

      </div>
      <div className="mainFooter">
        {counter > options.page && (

<button
className="closeButton"
type="button"
style={{marginBottom: '25px'}}
onClick={() => addPage()}
 
>
Carregar Mais
</button>
         
        )}
      </div>
    </Container>
  );
};
export default CientificLibraryComponent;
