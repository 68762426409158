import styled from 'styled-components';
import {ContainerRef} from '../styles';


interface IActive {
  active: boolean;
}

export const Container = styled(ContainerRef)``;

export const FieldSelect = styled.div<IActive>`
  position: relative;
  min-height: 46px;
  width: 100%;

  > div {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: auto;
    width: 100%;

    > div {
      position: relative;
      min-height: 46px;
      width: 100%;

      input {
        opacity: ${props => (props.active ? '1' : '0')};
        display: flex;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 5px;
        background: #fff;
        color: #333;
        width: 100%;
        z-index: 500;
      }

      > button {
        width: 30px;
        height: 30px;
        display: flex;
        position: absolute;
        top: 10px;
        right: 5px;
        z-index: 550;
        border-radius: 50%;
        background: #eee;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border: 0px;
        cursor: pointer;

        > svg {
          cursor: pointer;
        }
      }
      div.selectDiv {
        display: ${props => (!props.active ? 'flex' : 'none')};
        position: relative;
        width: 100%;

        div.selectHolder {
          position: absolute;

          left: 0px;
          bottom: 0px;
          right: 0px;
        }

        select {
          width: 100%;
          background: #fff;
          min-height: 46px;
          border: 0px;
    padding: 10px;
        }
      }
    }
  }
`;

export const List = styled.div<IActive>`
  position: relative;
  display: flex;
  width: 100%;

  z-index: ${props => (props.active ? '3000' : '-1')};

  ul {
    background: #fff;
    position: absolute;
    margin-top: 0px;
    top: 0px;
    left: 0px;
    padding: 0px 5px;
    width: 100%;
    display: ${props => (props.active ? 'flex' : 'none')};
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    align-items: flex-start;
    z-index: 3000;

    > li {
      z-index: 3000;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      font-family: Roboto;
      font-size: 16px;
      cursor: pointer;

      height: 44px;
      padding: 0 15px;
      margin: 0;
      color: #444;
      border-bottom: 0px solid #ddd;

      transition: background 0.2s;

      > button {
        width: 100%;
        background: #fff;
        color: #444;
        text-align: left;
        border: 0px;
        opacity: 1;
        transition: opacity 0.2s;
        padding: 10px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
