import React, { FC, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useModal } from '../../../hooks/Modal';
import { useToast } from '../../../hooks/Toast';
import StarInput from '../../DashboardPages/core/components/Forms/StarInput';
import { FaChevronCircleLeft } from 'react-icons/fa';
import RangeInput from '../../DashboardPages/core/components/Forms/RangeInput';
import { info } from 'console';
import CheckboxInput from '../../../components/Forms/CheckboxInput';

interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}




/*

Bloco de perguntas [
  {
  title : '',
  description : '',
  questions : [{
    title : '',
    description : '',
    type : '',
    image : '',

  }]
  ]
}

*/

interface IOptions {
  id: string;
  value: string;
  label: string;
  sub_title ?: string;
}

interface IQuestion {
  title ?: string;
  description ?: string;
  image ?: string;
  type ?: string;
  options ?: Array<Record<string,any>>;
  mandatory?: string;
}

interface IQuestions {
  title ?: string;
  description ?: string;
  questions : Array<IQuestion>
}

interface IPropsQuestion {
  questionsList : Array<IQuestions>;
  information : Record<string,any>;
  idName: string;
  exam_id : string;
  handle ?: Function;
}

interface IProps {
  id : string;
  exam_id : string;
  handle ?: Function;
  content : {
    title: string;
    description: string;
    thanks_title: string;
    thanks_description : string;
    questions : Array<IQuestions>;
    image ?: string;
   
  }
  
}

 
const Questions : React.FC<IPropsQuestion> = ({idName, exam_id, information, questionsList, handle}) => {

  const [position,setPosition] = useState(0);
  const [ready,setReady] = useState(false);

  const {removeModal} = useModal();


  const [failed,setFailed] = useState(false);
  const [corrects,setCorrects] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [knowAbout, setKnowAbout] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
 
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();


  let index = 0;
  let quest = 0;



  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    console.log(data);

 

    try {
      formRef.current?.setErrors({});
      

      const shape = {
   
      };

      let x = 1;

      const correctAnswers = {};
      let correctPoints = 0;

      questionsList.map( quest => {
        quest.questions.map( q => {

          const opt = q?.options ? q?.options : [];

           opt.map( qu => { 
            if(qu.correct === 'yes'){
              correctAnswers[`p${x}`] = qu.title;
            }
          });

          data[`p${x}`] = data[`p${x}`]?.[0] ? data[`p${x}`][0] : '';
          if(data[`p${x}`] === correctAnswers[`p${x}`]){
            correctPoints = correctPoints + 1;
          }


          shape[`p${x}`] =  Yup.string().required(`Por favor, avalie a pergunta: ${q.title}`);
          
          x = x+1;
        })
        
      })

      
 
      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

     

      const status = parseInt(information.correct_answers ||'0',10) <= correctPoints ? 'yes' : 'no';  

      console.log(correctAnswers, status);


      const response = await api.post(`/send-exam`, {
        status,
        exam_id : exam_id,
         answers : data,
        client_id : idName
      });

      setCorrects(correctPoints)

      if(status === 'yes'){
        setReady(true);
        setSuccess(true);
      }
      else{
        setReady(true);
        setFailed(true);
      }

      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao cadastrar sua pesquisa',
      ]);
    }
    setLoading(false);
  };


const changePosition = () => {

  setTimeout(() => {
    setPosition(position + 1);
    window.scrollTo(0,0);

  },400)

}

  const limit = questionsList.length + 1;

  const mandatory = (items) => {
    return  items.mandatory !== 'yes' ? <aside style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: '15px'}}>

<button className='defaultButtonMini' type='button' style={{ cursor:'pointer'}} onClick={() => setPosition(position +1)}>PULAR</button>
</aside> : <></>
  }

  return   <>  

{loading === true && (
        <Loader message="Carregando..." position="fixed" />
      )}

      <aside style={{width: '100%', maxWidth: '600px'}}>
     
          <Form style={{padding: '0px', borderRadius:'15px'}} ref={formRef} onSubmit={handleNewLogin} initialData={{}}>
         

   <div style={{display: position === 0 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'flex-start', justifyContent:'center'}}><br/><br/><h2 style={{width:'100%', textAlign:'center', fontSize:'24px', color: "#333"}} dangerouslySetInnerHTML={{__html: information.title}}/>

<br/><br/> 

<p style={{margin: '10px auto', width:'100%', maxWidth: '500px',textAlign:'center', color: "#333"}} dangerouslySetInnerHTML={{__html: information.description}}/>

  <button className='defaultButtonMini' style={{minWidth: '150px', padding: '10px', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', margin: '10px auto'}} type='button' onClick={() => setPosition(1)}>Iniciar</button>

  </div>
  
   {questionsList.map( (question,indexQuestion) => {
    quest = quest + 1;
 
    
    return <div key={`question${indexQuestion}`} className="fade" style={{      boxShadow:'0px 0px 0px #fff', border: '0px', display: position === quest ? 'flex' : 'none', flexDirection:'column',  margin: '20px auto', maxWidth: '650px'}}>
    
<p style={{color: '#ccc', textAlign:'center', width: '100%'}}>{position} / {limit - 1}</p>
    
     {question.title ?  <h2 style={{fontSize: '12px',textAlign:'justify', width: '100%',padding: '15px'}} dangerouslySetInnerHTML={{__html: question.title}}/> : <></>}
      {question.description ? <p style={{fontSize: '10px', padding: '15px', width: '100%', textAlign:'justify'}} dangerouslySetInnerHTML={{__html: question.description}}/> : <></>}

      {question.questions.map( (items,itemsIndex) => {

        index++;

        const data : Array<IOptions> = [];

        const options : Array<Record<string,any>> = items.options ? [...items.options ] : [];
   
        options.map((opt) => {
          data.push({ label: opt.title, value: opt.title,   id: opt.title});
        })

      

       return <CheckboxInput unique options={data} name={`p${index}`} setCallBack={() => { changePosition(); }} key={`questionItem${itemsIndex}`} /> 
      
     })}


     <aside style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: '15px'}}>
<FaChevronCircleLeft size={25} style={{color: '#777', marginRight: '10px'}} onClick={() => setPosition(position -1)}/>
<p style={{color: '#777', cursor:'pointer'}} onClick={() => setPosition(position -1)}>VOLTAR</p>
</aside> 

    </div>


  })}


{ ready && failed &&
          <aside style={{display:   'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '100%'}}>
        
        <h2 style={{width:'100%', textAlign:'center', fontSize:'18px',   color: "rgb(200,0,0)"}} dangerouslySetInnerHTML={{__html: information.failure_title}}/>
          <p style={{margin: '10px 0px',textAlign:'center', maxWidth: '500px', color: "rgb(100,0,0)"}} dangerouslySetInnerHTML={{__html: information.failure_description}}/>

              <h2 style={{width:'100%', textAlign:'center', fontSize:'24px', color: "#333", padding: '15px', border: '2px solid #ccc', margin: '20px auto'}}>Você acertou {corrects}/{questionsList.length}</h2>
    
            </aside>}

            { ready && success &&
          <aside style={{display: 'flex'  , flexDirection:'column', justifyContent:'center', alignItems:'center', width: '100%'}}>
        
        
          <h2 style={{width:'100%', textAlign:'center', fontSize:'18px', color: "rgb(0,100,150)"}} dangerouslySetInnerHTML={{__html: information.success_title}}/>
          <p style={{margin: '10px 0px',textAlign:'center', maxWidth: '500px', color: "#333"}} dangerouslySetInnerHTML={{__html: information.success_description}}/>

              <h2 style={{width:'100%', textAlign:'center', fontSize:'24px', color: "#333", padding: '15px', border: '2px solid #ccc', margin: '20px auto'}}>Você acertou {corrects}/{questionsList.length}</h2>

              {handle && <aside style={{display:'flex', alignItems:'center', justifyContent:'center'}}>

<button className='defaultButtonMini'  style={{ minWidth: '150px', padding: '10px', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', margin: 'auto' }} onClick={() => { removeModal('exam'); handle(); }} type="button">
  Finalizar
</button>
</aside>}
            </aside>}
   
        { !ready &&  <aside style={{display: position ===  limit ? 'flex' : 'none', flexDirection:'column', justifyContent:'center', alignItems:'center', width: '100%'}}>
        
        
          <h2 style={{width:'100%', textAlign:'center', fontSize:'18px', color: "#333"}} >Clique no botão abaixo para finalizar e visualizar seu resultado.</h2>
          <p style={{margin: '10px 0px', textAlign:'center', maxWidth: '500px', color: "#333", fontWeight:'bold'}} dangerouslySetInnerHTML={{__html: information.thanks_title}}/>

<p style={{margin: '10px 0px',textAlign:'center', maxWidth: '500px', color: "#333"}} dangerouslySetInnerHTML={{__html: information.thanks_title}}/>

<aside style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
<FaChevronCircleLeft size={30} style={{color: '#777', marginRight: '10px'}} onClick={() => setPosition(position -1)}/>
            <button className='defaultButtonMini'  style={{ minWidth: '150px', padding: '10px', background: 'rgb(0,100,150)', border: '2px solid #fff', color: '#fff', margin: 'auto' }} type="submit">
              Finalizar
            </button>
            </aside>
            </aside>}


   
         


            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
    
          </Form>
        
      </aside>     

  
  </>
}

const FormContainer : React.FC<IProps> = ({ id, content, exam_id, handle }) => {





  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const [information,setInformation] = useState<Record<string,any>>({});
  const [question,setQuestion] = useState<Array<IQuestions>>([]);

  useEffect(() => {

    const newItem : Array<IQuestions> = [];

    content.questions.map( q => {
      newItem.push({ title: q.title, description: q.description, questions: [q]});
    })

    
    setInformation(content);
    setQuestion(newItem);

  },[content])



  {/* 
  
              <SelectSimple name="p1" label="" options={[
              { label: '0', value: '0'},
              { label: '1', value: '1'},
              { label: '2', value: '2'},
              { label: '3', value: '3'},
              { label: '4', value: '4'},
              { label: '5', value: '5'},
              { label: '6', value: '6'},
              { label: '7', value: '7'},
              { label: '8', value: '8'},
              { label: '9', value: '9'},
              { label: '10', value: '10'},
            ]}/>
 
 
          */         }



  return (
    <> {question.length > 0 && <Questions handle={handle} exam_id={exam_id} idName={id} information={information}  questionsList={question}/>}
     
    </>
  );
};

export default FormContainer;
