import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Whatsapp from '../../../../../components/Whatsapp';
import { logo, authTitle, urlWebsite, supportNumber } from '../../../../../config';
import { useConfiguration } from '../../../../../hooks/Configuration';
import { useLanguage } from '../../../../../hooks/Language';
import {

  Capa,

} from './styles';

interface IParams {
  projectHash: string;
  eventHash: string;
}

const SidebarEventTheme: React.FC = () => {
  const { configuration } = useConfiguration();
  const { translate } = useLanguage();
  const { projectHash, eventHash } = useParams<IParams>();
  return (
    <>
      <Capa>
        <div style={{ padding: '20px' }} >

          {(configuration?.current_event_id_response?.image || configuration.header_image) && <div>
            <img style={{ width: '100%', opacity: 1, maxHeight: '80vh', margin: '0px auto' }}
              src={`${urlWebsite}/${configuration?.current_event_id_response?.image || configuration.header_image}`} />

          </div>}
        </div>
        <div className="p51 backgroundWhiteGradient" style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', flexDirection: 'column' }}>
          <div className="titleCapa">
            {configuration?.current_event_id_response?.data_image ? <img src={`${urlWebsite}/${configuration?.current_event_id_response?.data_image}`} style={{ width: '100%', opacity: 1, maxWidth: '500px', margin: '0px auto' }} /> : <></>}

            {configuration.certificates && configuration.certificates === 'yes' ? <Link to="/certificados" style={{ margin: '10px' }}><button className="defaultButton">{translate("Certificados")}</button></Link> : <></>}
            <Link to={`/app/${projectHash}/event/${eventHash}/inscricao`} style={{ margin: '5px 10px' }}><button className="defaultButtonReverse">{translate("Inscreva-se")}</button></Link>
            {/*  <Link to={`/app/${projectHash}/certificados`} style={{margin: '5px 10px'}}><button className="defaultButton">Certificados</button></Link> */}
            {/*  <Link to={`/app/${projectHash}/programacao`} style={{margin: '5px 10px'}}><button className="defaultButton">Programação</button></Link> */}
            <Link to={`/app/${projectHash}/event/${eventHash}/login`} style={{ margin: '5px 10px' }}><button className="defaultButton">{translate("Entrar")}</button></Link>
            <Whatsapp className='fixedWhatsapp' number={supportNumber} message={translate("Suporte técnico")} text={`Olá, estou no ${authTitle}`} />


            <Link to={`/app/${projectHash}/`} style={{ margin: '5px 10px' }}><button className="defaultButton" style={{ marginTop: '30px' }}>{translate("Mais eventos")}</button></Link>

          </div>
        </div>
      </Capa>

    </>
  );
};
export default SidebarEventTheme;
