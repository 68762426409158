import { useParams } from "react-router-dom";
import api from "../services/api";

 

const sendTrack = async ({category, title, url ,user_name, user_id,project_id = '', event_id = '' }) => {

   
      const response = await api.post('/track',{category, title, url ,user_name, user_id,project_id, event_id});
      
      return true;
      
    
    
      }

      export default sendTrack;