import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { useField } from '@unform/core';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns/esm';
import pt from 'date-fns/locale/pt-BR';
import { Container } from './styles';
import { Error } from '../styles';
import { startOfDay } from 'date-fns';

registerLocale('pt', pt);

interface IProps extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  placeholder?: string;
  setCallback?: Function;
}
const DatePicker: React.FC<IProps> = ({ name, placeholder, setCallback = () => { }, ...rest }) => {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);


  const [date, setDate] = useState<Date | null>(
    defaultValue ? typeof defaultValue === 'string' ? new Date(parseISO(defaultValue)) : defaultValue : null,
  );
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: undefined,
      clearValue: (ref: any) => {
        ref.clear();
      },
      getValue: (ref: any) => {

        return date ? startOfDay(date) : date;
      },


    });
  }, [fieldName, registerField, date]);
  return (
    <Container isFocused={false} isErrored={false} isFilled={false}>
      <span>{placeholder}</span>
      <ReactDatePicker
        locale="pt"
        dateFormat="P"
        ref={datepickerRef}
        selected={date}
        onChange={(v) => {

          if (v) { setDate(startOfDay(v)) }
          else { setDate(null) };

          setCallback(v ? startOfDay(v) : null)
        }}


        {...rest}
      />
    </Container>
  );
};
export default DatePicker;
