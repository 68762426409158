import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../core/components/Forms/Input';
import Button from '../../../core/components/Forms/Button';
import Loader from '../../../core/components/Loader';
import MaskedInput from '../../../core/components/Forms/MaskedInput';
import { ResponseMessage } from '../../../core/components/ResponseMessage';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import { Error } from './styles';
import { AnimatedDiv } from '../styles';

import api from '../../../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../../../config';

import { useToast } from '../../../../../hooks/Toast';
import CheckboxInput from '../../../core/components/Forms/CheckboxInput';

import { useLanguage } from '../../../../../hooks/Language';
import SelectSimple from '../../../core/components/Forms/SelectSimple';
import { countries } from './countries';

interface ICaptcha {
  getValue(): string;
}

interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}


interface ICaptcha {
  getValue(): string;
}

interface IParams {
  hash: string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { hash } = useParams<IParams>();

  const [failure, setFailure] = useState(false);
  const [ready, setReady] = useState(false);

  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const [term, setTerm] = useState({
    hash: '',
    header_text: '',
    term: '',
    language: 'ptBr'
  })

  const load = async () => {
    setLoading(true);

    if (hash) {
      try {
        const response = await api.get(`/get-term/${hash}`);
        setTerm(response?.data);
        setLoading(false);
        setReady(true);
      }
      catch (err) {
        setFailure(true);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    load();
  }, [hash])

  const { addToast } = useToast();
  const { translate } = useLanguage();

  const [countryISO, setCountryISO] = useState('BR');
  const [position, setPosition] = useState('');





  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required(term?.language === 'en' ? 'Name is required' : term?.language === 'es' ? 'Se requiere el nombre' : 'Nome obrigatório'),
        email: Yup.string()
          .required(term?.language === 'en' ? 'Email is required' : term?.language === 'es' ? 'Correo electronico es requerido' : 'E-mail obrigatório')
          .email(term?.language === 'en' ? 'Fill in a valid email' : term?.language === 'es' ? 'Rellene un correo electrónico válido' : 'Preencha um email válido'),

        document_number: Yup.string().required(term?.language === 'en' ? 'Document number is required' : term?.language === 'es' ? 'Se requiere número de documento' : 'CPF é obrigatório'),
        term: Yup.string().required(term?.language === 'en' ? 'Please confirm that you accept the term.' : term?.language === 'es' ? 'Por favor, confirme que acepta el término.' : 'Pedimos que confirme se aceita o termo'),

      });



      data.term = data.term[0];


      await schema.validate(data, { abortEarly: false });


      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;



      const newUser = await api
        .post(`/confirm_term/${hash}`, {
          ...data,
          captcha: captchaValue,
          term: data.term
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || term?.language === 'en' ? 'Error sending' : term?.language === 'es' ? 'Error al enviar' : 'Erro ao enviar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: term?.language === 'en' ? 'Validation error' : term?.language === 'es' ? 'Error de validacion' : 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  if (failure) {
    return <AnimatedDiv> <ResponseMessage
      active
      type="error"
      title=""
      description={
        <p style={{ color: '#333' }}>
          <h2 className="titleH2" style={{ color: '#333' }}> {term?.language === 'en' ? 'Failed to identify term' : term?.language === 'es' ? 'No se pudo identificar el término' : 'Falha ao identificar o termo'} </h2>
          <br />
          {term?.language === 'en' ? 'Please try again in a few minutes or contact the organization' : term?.language === 'es' ? 'Vuelva a intentarlo en unos minutos o póngase en contacto con la organización.' : 'Tente novamente em alguns minutos ou entre em contato com a organização'}

        </p>
      }
    /> </AnimatedDiv>
  }

  return (
    <>
      {loading === true && (
        <Loader message={term?.language === 'en' ? 'Loading...' : term?.language === 'es' ? 'Cargando...' : 'Carregando...'}
          position="fixed" />
      )}

      <aside>

        {success ? (
          <AnimatedDiv> <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{ color: '#333' }}>
                <h2 className="titleH2" style={{ color: '#333' }}> {term?.language === 'en' ? 'Term successfully signed!' : term?.language === 'es' ? 'Término firmado con éxito!' : 'Termo assinado com sucesso!'}</h2>
                <br />
                {term?.language === 'en' ? 'You will receive an email with a copy of the term.' : term?.language === 'es' ? 'Recibirá un correo electrónico con una copia del término.' : 'Vocẽ receberá um email com uma cópia do termo.'}

              </p>
            }
          /> </AnimatedDiv>
        ) : (<>
          {ready && <AnimatedDiv>
            <Form ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>


              <h2 style={{ textAlign: 'center', width: '100%', color: '#333', fontSize: '18px', maxWidth: '400px' }}>{term.header_text}</h2>

              <p style={{ textAlign: 'justify', width: '100%', fontSize: '14px', color: '#333', maxWidth: '400px' }} dangerouslySetInnerHTML={{ __html: term.term }} />
              <br /><br />
              <SelectSimple name="country" options={countries} setCallback={(value) => { setCountryISO(value) }} label={term?.language === 'en' ? 'Country' : term?.language === 'es' ? 'Pais' : 'País'} />
              <Input name="name" placeholder={term?.language === 'en' ? 'Full name' : term?.language === 'es' ? 'Nombre completo' : 'Nome completo'} />
              <Input name="email" placeholder={term?.language === 'en' ? 'Email' : term?.language === 'es' ? 'Correo electrónico' : 'E-mail'} />
              {countryISO === 'BR' ? <MaskedInput mask={'999.999.999-99'} name="document_number" placeholder={translate('CPF')} /> : <Input type='text' name="document_number" placeholder={term?.language === 'en' ? 'Identification document number' : term?.language === 'es' ? 'Numero de documento de identificacion' : 'CPF'} />}

              <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
                <br />
                <p style={{ color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }}>{term?.language === 'en' ? `Do you accept the ` : term?.language === 'es' ? `¿Aceptas el ` : `Você aceita o `}{term.header_text}?</p>
                <CheckboxInput
                  style={{ display: 'flex' }}
                  name="term"
                  unique={true}

                  options={[
                    {
                      id: `Sim`,
                      label: term?.language === 'en' ? `Yes` : term?.language === 'es' ? `Si` : `Sim`,
                      value: `Sim`,
                    },

                  ]}
                />

              </div>
              <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

              </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button className="defaultButton" type="submit">


                  {term?.language === 'en' ? 'Send confirmation' : term?.language === 'es' ? 'Enviar confirmación' : 'Enviar confirmação'}
                </button>
              </aside>
            </Form></AnimatedDiv>}
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
