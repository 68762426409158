import React, { useState, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import { AnimatedDiv } from './styles';

interface ITextPopperProps {
  messages: Array<string>;
  time: number;
  group?: boolean;
  direction?: 'up' | 'down' | 'left' | 'right' | 'fade';
  keyName?: string;
}

const TextPopper: React.FC<ITextPopperProps> = ({
  messages,
  time = 1000,
  group = false,
  direction = 'down',
  keyName = 'oi',
}) => {


  return messages?.[0] && messages?.[0] !== ' ' ? <Marquee pauseOnHover gradientWidth={30} speed={50} style={{ width: '100%' }}>
    <p style={{ color: '#333' }}>{messages?.[0]}</p> : <></>
  </Marquee> : <></>
};

export default TextPopper;
