import React, { useCallback, useRef, useEffect, useState } from 'react';

import { Form } from '@unform/web';
import { Container } from './styles';
import { hour, dateLanguage } from '../../utils/date';
import api from '../../services/api';
import Vimeo from '../Vimeo';

import PosterZoomComponent from '../PosterZoomComponent';
import { Button } from '../Button/styles';
import { rgb, rgba } from 'polished';
import Favorite from '../ClassRoomContainer/modules/Favorite';
import { FaStar } from 'react-icons/fa';
import { useLanguage } from '../../hooks/Language';

interface IPoster {
  id : number;
  title?: string;
  author?: string;
  image?: string;
  video_type?: number;
  video_id: string;
  co_authors?: string;
  category?: string;
  have_video?: number;
  eixo ?: string;
}

interface IOption {
  page: number;
  order: Record<string,string>;
  where: Record<string, any>;
}

const PosterComponent: React.FC = () => {
  const [posters, setPosters] = useState<Array<IPoster>>([]);
  const [selectedPoster, setSelectedPoster] = useState<IPoster>({} as IPoster);
  const [openPoster, setOpenPoster] = useState(false);
  const [loading, setLoading] = useState(true);
  const [counter, setCounter] = useState(1);
  const [search, setSearch] = useState('');
  const [haveSelector, setHaveSelector] = useState(false);
  const [category, setCategory] = useState('');
  const [eixos, setEixos] = useState<Array<string>>([]);
  const [options, setOptions] = useState<IOption>({
    page: 1,
    order: {orderBy : 'title', direction: 'asc'},
    where: {},
  });
const { translate} = useLanguage();
  const loadSearch = async (join = false, newOption = {} as IOption) => {

    const getOption : IOption = newOption?.page > 0 ? { ...newOption} : { ...options };

    const string = `search=${encodeURIComponent(
      search || '',
    )}&order=${encodeURIComponent(JSON.stringify(getOption.order))}&page=${
      getOption.page
    }&where=${encodeURIComponent(JSON.stringify(getOption.where))}`;

    const response = await api.get(`/poster-list?${string}`);

    if (response && response.data && response.data.rows) {
      const responseData = response.data.rows.reduce((prev, elem) => {
        elem.formatted_date = dateLanguage(elem.date);
        elem.formatted_start_hour = hour(elem.start_hour);
        elem.formatted_end_hour = hour(elem.end_hour);
        prev.push(elem);

        return prev;
      }, []);

      if (join) {
        setPosters([...posters, ...responseData]);
      } else {
        setPosters([...responseData]);
      }

      setCounter(response.data.count / 20 < 1 ? 1 : response.data.count / 20);
    } else {
      setPosters([]);
      setCounter(1);
    }
    setLoading(false);
  };



  const newSearch = () => {
    const newOptions = { ...options };
    newOptions.page = 1;
    setOptions({ ...newOptions });
    loadSearch();
    setSearch('');
  };

  const changeOrder = (value,direction) => {

    const newOptions = {...options};

    newOptions.order = { orderBy : value, direction};

    setOptions(newOptions);
    loadSearch();

  }

  const loadEixos = async () => {
    const response = await api.get(`/poster-list-eixos`);


    if(response){
      setEixos(response.data);
    }

  }

  useEffect(() => {
    const load = async () => {
      loadSearch();
    };
    load();
  }, []);

  const closePoster = () => {
    setOpenPoster(false);
  };

  const showPoster = poster => {
    setSelectedPoster(poster);
    setOpenPoster(true);
  };

  if (openPoster) {
    return (
      <PosterZoomComponent
        closePoster={closePoster}
        posterItem={selectedPoster}
      />
    );
  }

  const addPage = () => {
    const newOptions = { ...options };
    newOptions.page = newOptions.page + 1;
  
  
    setOptions(state => ({ ...state, page : state.page + 1  }));

    loadSearch(true,newOptions);
  };

  const changeCategory = (column,categoryName) => {
    const newOptions = { ...options };
    if(categoryName === ''){
      newOptions.where = {};
      setOptions({ ...newOptions });
      loadSearch();
      if(categoryName === 'have_video'){
      setCategory(categoryName);
      }
      return;

    }

   
    const newCategory =
      newOptions.where && newOptions.where[column] === categoryName
        ? ''
        : categoryName;
    newOptions.page = 1;
    if (newCategory === '' && newOptions.where[column]) {
      delete newOptions.where[column];
    } else {
      newOptions.where[column] = newCategory;
    }

    setOptions({ ...newOptions });
    loadSearch();
    setCategory(newCategory);
  };

  const getColor = categoryName => {
    if (categoryName === 'Apresentação Oral') {
      return 'borderBlue';
    }
    if (categoryName === 'Pôster Comentado') {
      return 'borderPurple';
    }
    if (categoryName === 'Pôster Simples') {
      return 'borderGreen';
    }
  };

  return (
    <Container>
      <div className="posterSelector">
   
       { haveSelector && <> 
        <Button
          className={category === '1' ? 'active' : ''}
          onClick={() => changeCategory('have_video', '1')}
          type="button"
        >
          Pôster
        </Button>
        <Button
          className={category === '2' ? 'active' : ''}
          onClick={() => changeCategory('have_video', '2')}
          type="button"
        >
          Apresentação Oral
        </Button></>}

   
      
       <div style={{display: 'flex', flexWrap:'wrap', padding: '10px', flexDirection: 'row', alignItems: 'center', justifyContent:'center', width: '100%',  background: 'rgba(0,0,0,0.5)'}}>
      { eixos.length > 0 && <>   <label style={{margin:'5px'}}>{translate('Eixo Temático')}</label>
      <select style={{minHeight: '43px', margin: '5px', width: '100%' , maxWidth:'400px',}} onChange={(e) => changeCategory('eixo',e.target.value)}>
        <option value={''}>{translate('Todos')}</option>
        {eixos.map( eixo =>  <option value={eixo}>{eixo}</option>)}
       
      </select> </>}
    
         
        <Form
          style={{ display: 'flex', alignItems: 'stretch', background:'none' }}
          onSubmit={newSearch}
        >
          
           
          <input style={{height: '43px', padding: '10px'}}
            name="pesquisa"
            type="text"
            placeholder={`${translate('Escreva para pesquisar')}...`}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

        <Button style={{height: '43px', margin: 0}} type="submit">{translate('Pesquisar')}</Button>
        </Form>

        <button style={{padding:'5px', height: '43px', width:'43px', borderRadius: '10px', border:'0px', color: options?.order?.orderBy === 'stars' ? '#fff' : '#333', background: options?.order?.orderBy === 'stars' ? 'rgb(0,150,0)' : '#fff' }} onClick={() => { options?.order?.orderBy === 'stars' ? changeOrder('title','asc') : changeOrder('stars','desc')}}><FaStar/></button>
        </div>
      </div>
      <div className="mainPoster">
        {!loading && posters.length < 1 && <h2>Nenhum trabalho encontrado</h2>}
        {posters.map(poster => {
          return (<div style={{display:'flex', alignItems:'stretch', justifyContent:'center', position:'relative'}}>
            <div key={`posterDiv${poster.id}`} style={{position:'relative'}}
              className={`posterDisplay ${getColor(poster.category)}`}
              onClick={() => showPoster(poster)}
            ><div style={{position:'absolute', zIndex:100, top: 0, left: 0, right: 0, bottom: 0, background: 'none'}}/>
              {poster.have_video === 2 ? (
                <Vimeo keyName={`poster${poster.id}`} id={poster.video_id} />
              ) : (
                <img
                  className="posterImage"
                  src={poster.image}
                />
              )}
              <p className="posterTitle">
                <strong> {translate('Título')}:</strong>
                <br />
                {poster.title}
              </p>
              <p className="posterAuthor">
                <strong>{translate('Autores')}:</strong>
{' '}
<br />
                {poster.author}
                {poster.co_authors ? `, ${poster.co_authors}` : ''}
              </p>
              <strong style={{fontSize: '10px'}}>
              {translate('Eixo Temático')}: {poster.eixo}
              </strong>
              
            </div>
            <div style={{position: 'absolute', left:'50%', zIndex:100, bottom: '0px', transform: 'translateX(-50%)'}}>
                <div style={{background:"rgb(0,50,150)", padding:'5px', borderRadius:'50%'}}><Favorite type="poster" subtype="star" id={poster.id}/></div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mainFooter">
        {counter > options.page && (
          <Button type="button" onClick={() => addPage()}>
            {' '}
            Carregar Mais
          </Button>
        )}
      </div>
    </Container>
  );
};
export default PosterComponent;
