import styled, { css } from 'styled-components';
import theme from '../../template/index';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused?: boolean;
  isFilled?: boolean;
  isErrored?: boolean;
}

export const ContainerRef = styled.div<ContainerProps>`
  display: flex;
  justify-content: stretch;
  align-items: center;

  background: ${theme.input.background};
  color: ${theme.input.color};
  border-radius: 10px;
  border: ${theme.input.border};
  padding: 0px 16px;
  position: relative;
  min-height: 60px;

  div.react-datepicker-popper{
    z-index: 1000;
  }

  span {
          display: inline-block;
          position: absolute;
          top: -7px;
          left: 25px;
          color: #333;
          font-size: 10px;
          background: #fff;
          padding: 0px 5px;
          font-weight:bold;
        }


  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;

      > span {
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 16px;
        color: #fff;
        font-size: 10px;
      }
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${theme.input.colorFocused};
      border-color: ${theme.input.borderColorFocused};

   
    `}

    ${props =>
      props.isFilled &&
      css`
        color: ${theme.input.colorFilled};
        
      `}



  & + div {
    margin-top: 8px;
  }

  svg {
    font-size: 16px;
    margin-right: 16px;
  }



  input {
    width: 100%;
    border: 0;
    color: ${theme.input.color};
    padding: 16px;
    background: transparent;

    &::placeholder {
      color: #555;
    }
  }
`;

export const Error = styled(Tooltip)`
  margin-left: 16px;
  height: 20px;

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
  svg {
    margin-right: 0;
    color: #c53030;
    font-size: 20px;
  }
`;
