import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import ReactInputMask from 'react-input-mask';
import { useField, FormHandles } from '@unform/core';

import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container } from './styles';
import { Error } from '../styles';
import { FaStar } from 'react-icons/fa';
import { urlWebsite } from '../../../../../../config';
import { useToast } from '../../../../../../hooks/Toast';
import { useLanguage } from '../../../../../../hooks/Language';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;

  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  image?: string;
}
const EmojiInput: React.FC<Props> = ({
  name,
  icon: Icon,
  setCallback = (v) => { },

  formRef,
  image = '',
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const { addToast } = useToast();
  const { translate } = useLanguage();
  const [currentValue, setCurrentValue] = useState<number>();



  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFilled(!!inputRef.current);

    const length = setIsFocus(false);
  }, [inputRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,

      getValue: (ref: any) => {

        return currentValue;
      },
    });
  }, [fieldName, registerField, currentValue]);

  const itemChange = (v) => {
    setTimeout(() => {
      setCallback(v);
    }, 1000)
  }

  const changeValue = (v) => {

    setCurrentValue(v);
    itemChange(v);
    addToast({ type: 'success', title: 'adicionado' })

  }

  return (
    <Container style={{ margin: '10px auto', width: '350px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', border: '0px', padding: '0px', background: '#fdfdfd', borderRadius: '10px' }} isErrored={!!error} isFilled={isFilled} isFocused={isFocus}>
      <div className='starInput' style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' }}>
        {
          image ? <div style={{ width: '100%' }}>
            <img src={`${urlWebsite}/${image}`} style={{ width: '100%', borderRadius: '15px 15px 0px 0px' }} />
          </div> : <></>}
        <div>
          {rest.placeholder ? <p style={{ padding: '10px', width: '100%', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: rest.placeholder || '' }} /> : <></>}
          <div style={{ display: 'flex', padding: '20px 0px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div onClick={() => changeValue(1)} className="emojiDiv">
              <img src="/emoji_survey/sad.png" style={{ cursor: 'pointer', width: currentValue === 1 ? '60px' : '55px' }} />
              <p>{translate('Muito insatisfeito')}</p>
            </div>
            <div onClick={() => changeValue(2)} className="emojiDiv">

              <img src="/emoji_survey/bad.png" style={{ cursor: 'pointer', width: currentValue === 2 ? '60px' : '55px' }} />
              <p>{translate('Insatisfeito')}</p>
            </div>
            <div onClick={() => changeValue(3)} className="emojiDiv">

              <img src="/emoji_survey/normal.png" style={{ cursor: 'pointer', width: currentValue === 3 ? '60px' : '55px' }} />
              <p>{translate('Indiferente')}</p>
            </div>
            <div onClick={() => changeValue(4)} className="emojiDiv">

              <img src="/emoji_survey/good.png" style={{ cursor: 'pointer', width: currentValue === 4 ? '60px' : '55px' }} />
              <p>{translate('Satisfeito')}</p>
            </div>
            <div onClick={() => changeValue(5)} className="emojiDiv">

              <img src="/emoji_survey/love.png" style={{ cursor: 'pointer', width: currentValue === 5 ? '60px' : '55px' }} />
              <p>{translate('Muito satisfeito')}</p>
            </div>
          </div>







        </div>
      </div>
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};
export default EmojiInput;
