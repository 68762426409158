import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons';
import { Feira, Expositor, Container } from './styles';
import { ContactIcons } from '../../components/Menu';
import { urlWebsite } from '../../config';
import api from '../../services/api';
import { Button } from '../../components/Button/styles';
import { Form } from '@unform/web';
import { useSearch } from '../../hooks/Search';
import { useAuth } from '../../hooks/Auth';
import QrReader from 'react-qr-reader';
import CheckinQRList from '../DashboardPages/features/CheckinQRList';
import { FaCheckCircle } from 'react-icons/fa';
import { useToast } from '../../hooks/Toast';
import { useParams } from 'react-router-dom';
interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IExpositor {
  id: number;
  background: string;
  capa: string;
  title: string;
  link_name: string;
}

const AreaConfirmacaoCurso: React.FC = () => {
  const { user } = useAuth();
  const { course } = useParams<Record<string, any>>();

  const [courseInfo, setCourseInfo] = useState<Record<string, any>>()
  const [data, setData] = useState<Array<Record<string, any>>>([]);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('search');
  const { reloadSearchAll } = useSearch();
  const { addToast } = useToast();

  const [notFound, setNotFound] = useState(false)

  const load = async (searchString) => {

    const dataItems = await api.get(`/list-activity-subscriptions/${course}`, {
      params: {
        search: searchString,
        where: {
          course_product_id: course
        },
        order: {
          orderBy: 'client_id_response.name',
          direction: 'asc'
        }

      }
    });
    setData(dataItems?.data?.rows);

  };

  const loadCourse = async (id) => {

    try {
      const dataItems = await api.get(`/check-activity/${course}`);

      setCourseInfo(dataItems?.data);
    }
    catch (err) {
      setNotFound(true)
    }

  };


  const confirmate = async (item) => {

    try {
      await api.put(`/credentiate-activity-participation/${item?._id?.toString()}/${course}`);
      load(search)

    }
    catch (err) {
      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao confirmar usuário' })
    }


  }

  useEffect(() => {

    if (course) {
      loadCourse(course);
    }

  }, [course])

  useEffect(() => {

    if (course) {
      load(search);
    }
  }, [search, type, course]);


  if (notFound) {
    return <Container>
      <h2>Curso não identificado</h2>
    </Container>
  }

  return (
    <Container>

      {type === 'search' ? <button className='defaultButton' onClick={() => setType('camera')}>Usar câmera</button> : <button className='defaultButton' onClick={() => setType('search')}>Usar pesquisa</button>}


      {type === 'search' ? <aside style={{ width: '100%', maxWidth: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px', flexDirection: 'column' }}>
        <input style={{ padding: '15px', borderRadius: '10px', background: '#fff', margin: '15px auto' }} placeholder="Pesquisar" onChange={(e) => setSearch(e.target.value)} value={search} />

        {
          data?.map(item => {
            return <aside style={{ display: 'flex', alignItems: 'flex-start', width: '100%', background: '#fff', borderRadius: '10px', gap: '10px', padding: '15px' }} >

              <aside style={{ minWidth: '40px', alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {item?.confirmed === 'yes' ? <FaCheckCircle onClick={() => confirmate(item)} style={{ width: '30px', height: '30px', color: 'green' }} /> : <FaCheckCircle onClick={() => confirmate(item)} style={{ width: '30px', height: '30px', cursor: 'pointer', color: '#ddd' }} />}
              </aside>
              <aside>
                <h2 style={{ color: '#333', width: '100%', textAlign: 'left' }}>Nome: {item.client_id_response?.name}</h2>
              </aside>
            </aside>
          })
        }

      </aside> : <></>}


      {type === 'camera' ? <>


        <CheckinQRList />

      </> : <></>}

    </Container>
  );
};
export default AreaConfirmacaoCurso;
