import React from 'react';

import { Container } from './styles';
import ValidateIntegration from './Components/ValidateIntegration';

const Validate: React.FC = () => {
  return (
    <Container>
      <ValidateIntegration />
    </Container>
  );
};
export default Validate;