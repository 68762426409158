import React from 'react';
import { useAuth } from '../../hooks/Auth';
import { useLanguage } from '../../hooks/Language';
import { useToast } from '../../hooks/Toast';
import { Button } from '../Button/styles';
import Whatsapp from '../Whatsapp';
import CopyToClipboard from '../CopyToClipboard';
import { eventName, supportNumber } from '../../config';
import { FaLock, FaLockOpen } from 'react-icons/fa';

// import { Container } from './styles';
interface VimeoProps {
  id: string;
  keyName?: string;
  time?: string;
  roomName?: string;
  class_name ?: string;
  status ?: number;
  room_id ?: number;
  dificulty_id?: number;
}

const ZoomContainer: React.FC<VimeoProps> = ({ id = '', dificulty_id, roomName = '', room_id, status, keyName, time = '0', class_name = '' }) => {

  const { user} = useAuth();
  const {translate} = useLanguage();
  const {addToast} = useToast();

const getButtonResponse = ( status, id) => {



  if(dificulty_id === 2){ return <button className="defaultButtonRed" style={{background: 'red', color: '#fff'}}> <FaLock/>{translate('Vagas esgotadas')}</button>}
  else if(status === 1){ return <Button onClick={() => addToast({title:translate('Aguardando iniciar'), description:translate('A atividade será aberta até 15 minutos antes do início'), type:"info"})}> <FaLock/>{translate('Aguardando iniciar')}</Button>}
  else if(status === 2){  return <a style={{marginBottom: '0 auto', display: 'flex'}} href={`${id}`} target="_BLANK"><Button type="button"><FaLockOpen/> {translate('Acessar sala')}</Button></a>}
  else if(status === 3){ return <Button onClick={() => addToast({title:'Atividade encerrada', description:'', type:"info"})}> <FaLock/> {translate('Programação encerrada')}</Button>}
  else{
return <a style={{marginBottom: '0 auto', display: 'flex'}} href={`${id}`} target="_BLANK"><Button type="button"><FaLockOpen/> {translate('Acessar sala')}</Button></a>
  }
 
}


  return (
    <div style={{padding: '40px 40px', background: 'rgba(0,0,0,0.5)', margin:'10px auto',  textAlign: 'left', marginTop: '30px', maxWidth: '500px', width:'100%', border: '5px solid #fff', borderRadius:'20px'}}>
     <h1>{translate('Sala de Audio e Vídeo')}</h1><br/>
      <h2>{translate('Instruções')}</h2><br/>
      <p style={{marginBottom: '20px'}}><div style={{marginBottom: '20px'}}>
1. {translate('Esta sala será aberta 15 minutos antes da atividade.')}<br/><br/>
2. {translate('Se faltam menos de 15 minutos para a atividade e a sala não abriu, clique no botão atualizar página.')}<br/><br/>
3. {translate('O limite de participantes desta sala foi definido pelo coordenador da atividade, em função da metodologia. Caso esta sala não tenha mais capacidade, temos atividades simultânea sem restrição de público.')}<br/><br/>
4. {translate('Caso enfrente algum problema, clique no botão de suporte técnico')}  <br/><br/>

<div style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>{getButtonResponse( status, id)}
      
        <br/>
        <a style={{margin: '0px auto'}} href={`/dashboard/${room_id}`}> <button type="button" className="defaultButtonReverse">{translate('Atualizar página')}</button></a></div>
        </div>

      </p>
      <div style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>

     <Whatsapp number={supportNumber} text={`Estou tentando acessar a sala de Zoom ${roomName} - ${eventName}`} message={translate('Suporte Técnico')}/>
     </div></div>
  );
};

export default ZoomContainer;
