interface Format {
  [key: string]: Function;
}

const format = (string: number | string): string => {
if(!string){
  return '0,00';
}

  const response: Format = {
    n0: (value: string) => `0,0${value}`,
    n1: (value: string) => `0,0${value}`,
    n2: (value: string) => `0,${value}`,
    n3: (value: string) => {
      const valueString = value;
      const { length } = valueString;
      const cents = valueString.substring(length - 2);
      const currency = valueString.substring(0, length - 2);

      return `${currency},${cents}`;
    },
  };
  const newString = string.toString().replace(/[^0-9]/g, '');
  const value = parseInt(`${newString}`, 10);
  const stringValue = value ? value.toString() : '000';
  const { length } = stringValue;

  return length >= 3
    ? response.n3(stringValue)
    : response[`n${length}`](stringValue);
};

export default format;
