import React from 'react';
import { urlWebsite } from '../../../../../config';
import { useLanguage } from '../../../../../hooks/Language';
import { useVideo } from '../../../../../hooks/Video';
import { date, dateWithHour } from '../../../../../utils/date';
import { AnimatedContainer } from '../../../styles';
import Favorite from '../../Favorite';
import { Block } from './styles';

interface IVideos {
    before: any;
    waiting: any;
    online: any;

}


interface IProps {
    videos: IVideos
}

const Template: React.FC<IProps> = ({ videos }) => {
    const { translate, language } = useLanguage();
    const { openVideoByID, courses, videoStatus } = useVideo();




    const listBlock = ({ block, type, title }) => {

        const index = block?.list?.findIndex(i => i.visible === 'yes' || i.visible === '2');

        if (index < 0) {
            return <></>;
        }


        return (<>
            {videoStatus &&
                block &&
                block.list &&
                block.list.length > 0 &&
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Block>
                        {block.list.map((lesson, index) => (
                            lesson?.visible !== '1' ? printLesson(lesson, index, type) : <></>))}
                    </Block> </div>
            }


        </>)
    }


    const getKeywords = (keywords: Array<string>) => {
        if (keywords && keywords.length > 0) {
            return <div className="keywordList">
                {keywords.map(key => <label>{key}</label>)}
            </div>
        }
        else {
            return <></>;
        }
    }

    const getSpeakers = (speakers: string) => {
        if (speakers && speakers.indexOf(';') > 0) {

            const listSpeakers = speakers.split(";");

            if (listSpeakers && listSpeakers.length >= 0) {
                return <div className="speakerList">
                    {listSpeakers.map(speaker => <p>{speaker}</p>)}
                </div>
            }


        }
        else {
            return <></>;
        }
    }

    const printLesson = (lesson, index, type) => {

        const statusSelector = {
            "1": translate('Em breve'),
            "2": <><div className="redBall" /> {translate('Ao vivo')}</>,
            "3": translate('Finalizado')
        }





        return (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', maxWidth: 'auto' }}>
            <div key={`posterDiv${lesson._id}`} style={{ position: 'relative' }} onClick={() => { openVideoByID(courses, lesson._id) }}
                className={`posterDisplay `}

            >

                <div className='posterImageDiv'>
                    {lesson.image ? <div style={{ width: '100%', position: 'relative' }}>
                        <img key={`img-${lesson._id}`}
                            className="posterImage"
                            src={lesson.image ? `${urlWebsite}/${lesson.image}` : '/apoio/biblioteca/capa.jpeg'}
                        />
                        {lesson?.title ? <div style={{ position: 'absolute', top: '0px', left: '0px', padding: '20px', right: '0px', bottom: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}><h2 style={{ color: '#fff', textAlign: 'center' }}>{lesson?.[`title_${language}`] ? lesson[`title_${language}`] : lesson.title}</h2> </div> : <></>}
                    </div>

                        : <div className='eventTitle'><h2>
                            {lesson?.[`title_${language}`] ? lesson[`title_${language}`] : lesson.title}
                        </h2></div>}
                </div>

                <div className='typeButtonDiv'>
                    <button className='typeButtonInitial'>{type === 'waiting' ? dateWithHour(lesson?.release_date) : type === 'online' ? 'Live' : 'On demand'}</button>
                    <button className='typeButton'>{type === 'waiting' ? 'Em breve' : 'Disponível'}</button>

                </div>

            </div>

        </div>
        );

    }

    return (<>
        {listBlock({ block: videos.online, type: "online", title: translate('Online Agora') })}
        {listBlock({ block: videos.waiting, type: "waiting", title: translate('Em breve') })}
        {listBlock({ block: videos.before, type: "before", title: translate('On Demand') })}

    </>);

}

export default Template;