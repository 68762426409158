import styled, { css } from "styled-components";

interface IProps {
    themeValue?: 'dark' | 'light';
}

export const TitleUI = styled.h2<IProps>`

font-size: 26px;
line-height: 32px;

p,strong,span{
    font-size: 26px; 
    color: #555;
}

color: #555;

${props => props?.themeValue === 'dark' && css`
color: #fff;
`}

`