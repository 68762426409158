import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import ContainerLine from '../../components/UIComponents/ContainerLine';
import { Title } from '../Expositor/styles';
import ParagraphDangerous from '../../components/UIComponents/ParagraphDangerous';
import CientificLibrary from '../CientificLibrary';
import CientificLibraryComponent from '../../components/CientificLibrary';
import { ContainerCientific } from '../CientificLibrary/styles';
import { ContainerLineUI } from '../../components/UIComponents/ContainerLine/styles';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Subtitle from '../../components/UIComponents/Subtitle';
import { urlWebsite } from '../../config';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Autores: React.FC = () => {

  const [authors, setAuthors] = useState<Array<Record<string, any>>>();
  const history = useHistory();
  const loadAuthors = async () => {
    const response = await api.get(`/list-cientific-library-authors`, { params: { limitItems: 10000 } });

    if (response.data) {


      response?.data?.rows.sort((a, b) => a.title.localeCompare(b.title, 'pt-BR', { sensitivity: 'base', ignorePunctuation: true }));


      setAuthors(response?.data?.rows || []);

    }

  }

  useEffect(() => {

    loadAuthors();


  }, [])

  const goToAuthor = (name, id) => {
    history.push(`/conteudos/autores/${id}/${name}`);

    window.scrollTo(0, 0);
  }


  const authorsList = authors?.filter(i => i?.is_author === 'yes');
  const presentersList = authors?.filter(i => i?.is_author !== 'yes');
  return (
    <>

      <ContainerLine className='full-width column column pad '>
        <ContainerLine className='max-1200 column column-start gap'>
          <ContainerLineUI className='full-width column pad'>
            <Title style={{ color: '#555', fontSize: '26px', lineHeight: '32px', padding: '30px 30px' }} className="text-left width-full">Conheça nossos autores</Title>

            <ContainerLineUI className='full-width row row-start gap-sm flex-wrap' style={{ alignItems: 'stretch' }}  >
              {authorsList?.map(authorInfo => {

                return <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px' }} onClick={() => { goToAuthor(authorInfo?.title, authorInfo?._id) }} className='column column-start max-160 pointer'>
                  <div style={{ width: '130px', height: '130px', borderRadius: '50%', background: authorInfo.photo ? `url('${urlWebsite}/${authorInfo.photo}')` : `url("/apoio/user.jpg")` }} className="thumb grow" />
                  <Subtitle style={{ color: '#555', display: 'inline-block', fontSize: '10px' }} className="text-center max-200 pad-sm">{authorInfo?.title}</Subtitle>
                </div>

              })}
            </ContainerLineUI>
          </ContainerLineUI>
          <ContainerLineUI className='full-width column pad'>
            <Title style={{ color: '#555', fontSize: '26px', lineHeight: '32px', padding: '30px 30px' }} className="text-left width-full">Conheça nossos apresentadores</Title>

            <ContainerLineUI className='full-width row row-start gap-sm flex-wrap' style={{ alignItems: 'stretch' }}  >
              {presentersList?.map(authorInfo => {

                return <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '10px' }} onClick={() => { goToAuthor(authorInfo?.title, authorInfo?._id) }} className='column column-start max-160 pointer'>
                  <div style={{ width: '130px', height: '130px', borderRadius: '50%', background: authorInfo.photo ? `url('${urlWebsite}/${authorInfo.photo}')` : `url("/apoio/user.jpg")` }} className="thumb grow" />
                  <Subtitle style={{ color: '#555', display: 'inline-block', fontSize: '10px' }} className="text-center max-200 pad-sm">{authorInfo?.title}</Subtitle>
                </div>

              })}
            </ContainerLineUI>
          </ContainerLineUI>

          <ContainerCientific>
            <CientificLibraryComponent header={false} />

          </ContainerCientific>
        </ContainerLine>
      </ContainerLine>
    </>
  );
};
export default Autores;
