import React from 'react';
import { ContainerLineUI } from '../../../UIComponents/ContainerLine/styles';
import Paragraph from '../../../UIComponents/Paragraph';
import ParagraphDangerous from '../../../UIComponents/ParagraphDangerous';
import Title from '../../../UIComponents/Title';
import DefaultCategoryGallery from '../DefaultCategoryGallery';

interface IProps {
    bigAreas: Record<string, any>[];
    posters: Record<string, any>[];
    showPoster: Function;
}

const DefaultCientificLibraryRender: React.FC<IProps> = ({ bigAreas, posters, showPoster }) => {


    return <ContainerLineUI className='full-width column'  >  <ContainerLineUI className='full-width column pad'  >
        <ContainerLineUI className='max-1200 column column-start pad' style={{ padding: '15px 30px' }}>
            <Title>Assista e explore nossos vídeos!</Title>
            <Paragraph>Nossos últimos vídeos publicados</Paragraph>
        </ContainerLineUI>
        <DefaultCategoryGallery setCallback={showPoster} applySlide={true} filtered={posters} />
    </ContainerLineUI>
        {bigAreas.map((area, index) => {
            return <ContainerLineUI className='full-width row row-to-column pad' style={{ padding: '30px', background: index % 2 > 0 ? '#fff' : '#eee' }}  >
                <ContainerLineUI className='max-300 column column-start pad' style={{ padding: '15px 30px' }}>
                    <Title style={{ fontSize: '18px' }}>{area?.title}</Title>
                    <ParagraphDangerous text={area?.description} />
                </ContainerLineUI>
                <DefaultCategoryGallery setCallback={showPoster} show={3} applySlide={true} filtered={posters?.filter(i => i?.big_area?.toUpperCase() === area?.title?.toUpperCase())} />
            </ContainerLineUI>


        })}
    </ContainerLineUI>
}

export default DefaultCientificLibraryRender;