import styled, { css } from 'styled-components';

interface IMessage {
  isMe: boolean;
}

interface Igreen {
  className?: string;
  background: string;
}

export const Container = styled.div<Igreen>`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: rgba(0,0,0,0.3);
  padding: 20px;
  max-width: 700px;

  width: 400px;
  min-height: 80vh;

  .message {
    color: #333 !important;
  }

  ${props =>
    props.className === 'greenMessages' &&
    css`
      background: green !important;
      div {
        background: green !important;
        color: #fff !important;

        p,
        h2,
        .message,
        .date {
          color: #fff !important;
          font-weight: bold;
        }
      }
    `}

  .greenball {
    width: 15px;
    height: 15px;
    min-width: 15px;
    background: rgb(0, 200, 0);
    border-radius: 50%;
    margin-right: 10px;
  }

  @media (max-width: 750px) {
    flex-direction: column-reverse;
    width: 100%;
    padding: 10px;

    justify-content: flex-end;

    > div.main {
      margin-top: 0px;
      h2 {
        display: none;
      }
    }
  }

  > div.main {
    width: 100%;
    > h2 {
      color: #fff;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      > svg {
        font-size: 30px;
        color: #fff;
      }
      @media (max-width: 750px) {
        display: none;
      }
    }

    @media (max-width: 750px) {
      justify-content: flex-end;
      width: 100%;
    }

    > div {
      justify-content: flex-start;
    }
  }
`;

export const Body = styled.div`
  z-index: 1;
  display: block;
  flex-direction: column;
  height: auto;
  max-height: 50vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  padding: 0px;
  overflow-y: auto;

  @media (max-width: 750px) {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const Message = styled.div<IMessage>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: ${props => (props.isMe === true ? '#fff' : 'none')};
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px;


  justify-content: flex-start;

  font-size: 12px;
  margin: 0px;

  @media (max-width: 750px) {
    width: 100%;
  }

  > div.messageChat {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
    color: #333;
    margin-bottom: 0px;
    > p.dateChat {
      font-weight: 400;
      max-width: 50px;
    }
  }

  > div.infoChat {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
    color: #333;
  }
`;

export const OnlineUser = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px;

  @media (max-width: 750px) {
    width: 100%;
  }

  > div.message {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
    color: #333;
    margin-bottom: -10px;
  }

  > div.info {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
    color: #333;
  }
`;

export const Footer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  padding: 0px;
  border-top: 0px solid #333;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  > form {
    width: 100%;
    display: flex;
    justify-content: space-space-between;
    align-items: center;
    border-radius: 10px;
    > input {
      width: 100%;
      color: #333;
      padding: 15px;
      border: 1px solid #ddd;
      border-radius: 10px 0px 0px 10px;
    }

    > div {
      text-align: right;
      width: auto;
      margin: 0;
      border-radius: 0px 10px 10px 0px;

      > button {
        padding: 15px 20px;
        border-radius: 0px 10px 10px 0px;
        background: rgb(0, 100, 150);
        color: #fff;
        cursor: pointer;
        border: 0;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    @media (max-width: 750px) {
      display: flex;

      > input {
        padding: 20px;
      }
      > div {
        max-width: 100px;
        > button {
          padding: 20px;
        }
      }
    }
  }
`;

export const Faces = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 70px;
    max-width: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 50, 100);
    padding: 5px;
    margin: 5px;
    min-height: 70px;
    border-radius: 8px;
    overflow: hidden;
    > span {
      color: white;
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
  }
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background: rgba(0, 100, 200, 0.7);
  color: #fff;
  font-size: 12px;
  margin: 5px 0px;
`;

export const MessageBox = styled.div`
  padding: 2px;
  display: flex;
  flex-direction: column;

  .messageContent {
    font-size: 16px;
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    justify-content: space-between;
    color: #333;
    p{
      color: #333 !important;
      font-family: Helvetica, Arial, sans-serif;
    }
    

    strong{
      color: #333 !important;
      display: block;
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  .messageModules {
    display: flex;
    font-size: 0.75rem;
    justify-content: flex-end;
    align-items: center;

    
    >div{
      cursor: pointer;
      margin: 2px 5px;
      transition: 0.5s all;

      &:hover{
        transform: scale(1.2);
      }
    }
  }

  .messageSendReply {
    display:flex;
    justify-content: stretch;
    margin: 5px 0px;
>form {
  display:flex;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    
    input {
      border : 2px solid rgb(230,230,230);
      border-radius: 5px;
      padding: 5px;
      width: 100%;

    }

    button{
      border : 2px solid rgb(0,230,230);
      border-radius: 5px;
      padding: 5px;
      width: 100px;
    }

}

  }
`;

export const Replies = styled(MessageBox)`
  padding-left: 20px;
  margin: 0px 3px;

  .messageContent {
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    background: rgb(250,250,250);
    margin-top: 5px;
    >strong{
      font-size: 12px;
    }

    >div{
      margin-left: 5px;
    }
  }
  .messageModules {
    display: flex;
    font-size: 0.75rem;
    justify-content: flex-end;
    align-items: center;

    
    >div{
      cursor: pointer;
      margin: 2px 5px;
      transition: 0.5s all;

      &:hover{
        transform: scale(1.2);
      }
    }
  }
`;
