import styled from 'styled-components';
import {ContainerRef} from '../styles';

export const Container = styled(ContainerRef)`

.emojiDiv{
    display: flex;
     width: 60px, 
     cursor:pointer; 
     flex-direction:column; 
     justify-content:center;
     align-items: center; 
     margin:10px;
     
   img{
    transition: 0.5s all;

    &:hover{
        transform: scale(1.2) ;
    }

   }
    p{
        height: 40px;
        margin-top: 10px;
        font-size: 10px;
        font-weight: bold;
        width: 100%;
        text-align: center;

    }

}

`;