
import React from 'react';
import { FaFacebookSquare, FaGithub, FaGoogle, FaHome, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { eventName } from '../../../../config';
import { IconSet, TextMessage, Icon } from './styles';
interface IIcons {
    message?: string;
    showText?: boolean;
    backgroundFill?: boolean;
    socialIcons?: Record<string, any>;
  }
  
  const ContactIcons: React.FC<IIcons> = ({
    message = '',
    showText = false,
    backgroundFill = true,
    socialIcons = {}
  }) => {
    return (
      socialIcons && (
        <IconSet column={showText}>
          {message !== '' && (
            <div className="messageSet">
              <TextMessage>{message}</TextMessage>
            </div>
          )}
          <div className="iconSet">
            {socialIcons.facebook && (
              <Icon
                target="_blank"
                extend={showText}
                className="socialIcon"
                title="Facebook"
                href={`https://www.facebook.com/${socialIcons.facebook}`}
                background={backgroundFill === true ? '#3d6ad6' : 'none'}
              >
                <FaFacebookSquare />
  {' '}
  {showText && <span>Facebook</span>}
              </Icon>
            )}

{socialIcons.github && (
              <Icon
                target="_blank"
                extend={showText}
                className="socialIcon"
                title="GitHub"
                href={`https://www.github.com/${socialIcons.github}`}
                background={backgroundFill === true ? '#3d6ad6' : 'none'}
              >
                <FaGithub />
  {' '}
  {showText && <span>GitHub</span>}
              </Icon>
            )}
  
            {socialIcons.instagram && (
              <Icon
                extend={showText}
                className="socialIcon"
                title="Instagram"
                target="_blank"
                href={`https://www.instagram.com/${socialIcons.instagram}`}
                background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
              >
                <FaInstagram/>
  {' '}
  {showText && <span>Instagram</span>}
              </Icon>
            )}
  
            {socialIcons.linkedin && (
              <Icon
                target="_blank"
                extend={showText}
                className="socialIcon"
                title="Linkedin"
                href={`https://www.linkedin.com/${socialIcons.linkedin}`}
                background={backgroundFill === true ? '#3d6ad6' : 'none'}
              >
                <FaLinkedin />
  {' '}
  {showText && <span>Linkedin</span>}
              </Icon>
            )}
  
            {socialIcons.youtube && (
              <Icon
                target="_blank"
                extend={showText}
                className="socialIcon"
                title="Youtube"
                href={`https://www.youtube.com/${socialIcons.youtube}`}
                background={backgroundFill === true ? '#3d6ad6' : 'none'}
              >
                <FaYoutube />
  {' '}
  {showText && <span>Youtube</span>}
              </Icon>
            )}
  
            {socialIcons.twitter && (
              <Icon
                target="_blank"
                extend={showText}
                className="socialIcon"
                title="Twitter"
                href={`https://www.twitter.com/${socialIcons.twitter}`}
                background={backgroundFill === true ? '#3d6ad6' : 'none'}
              >
                <FaTwitter /> {showText && <span>Twitter</span>}
              </Icon>
            )}
  
            {socialIcons.whatsapp && (
              <Icon
                extend={showText}
                target="_blank"
                className="socialIcon"
                background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
                title="Whatsapp"
                href={`https://api.whatsapp.com/send?l=pt&phone=55${
                  socialIcons.whatsapp
                }&text=${encodeURI(`Olá! Estou no evento ${eventName}!`)}`}
              >
                <FaWhatsapp />
  
                {showText && <span>Whatsapp</span>}
              </Icon>
            )}
  
            {socialIcons.website && (
              <Icon
                extend={showText}
                target="_blank"
                className="socialIcon"
                background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
                title="Website"
                href={socialIcons.website}
              >
                <FaHome />
  
                {showText && <span>Website</span>}
              </Icon>
            )}
  
            {socialIcons.zoom && (
              <Icon
                extend={showText}
                target="_blank"
                className="socialIcon"
                background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
                title="Zoom"
                href={socialIcons.zoom}
              >
                <FaLinkedin />
  
                {showText && <span>Zoom</span>}
              </Icon>
            )}
  
            {socialIcons.meet && (
              <Icon
                extend={showText}
                target="_blank"
                className="socialIcon"
                background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
                title="Reunião no Goole Meet"
                href={socialIcons.meet}
              >
                <FaGoogle />
  
                {showText && <span>Google Meet</span>}
              </Icon>
            )}
          </div>
        </IconSet>
      )
    );
  };

  export default ContactIcons;