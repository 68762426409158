import React from 'react';
import { Switch } from 'react-router-dom';
import { home } from '../config';
import Route from './routes';
import SignIn from '../pages/SignIn';
import Sobre from '../pages/Sobre';
import SignInAdmin from '../pages/SignInAdmin';
import SignUp from '../pages/SignUp';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import Products from '../pages/Products';

import VideosDashboard from '../pages/VideosDashboard';
import Dashboard from '../pages/DashboardPages';

import Home from '../pages/Home';
import AreaExposicao from '../pages/AreaExposicao';
import Expositor from '../pages/Expositor';
import Galeria from '../pages/Galeria';
import ExpositorChat from '../pages/ExpositorChat';
import SigninExpositor from '../pages/SigninExpositor';
import Program from '../pages/Program';
import Poster from '../pages/Poster';

import Speakers from '../pages/Speakers';
import Zoom from '../components/ZoomStarter';
import SpeakerLink from '../pages/SpeakerLink';
import ModeratorLink from '../pages/ModeratorLink';
import Validate from '../pages/DashboardPages/features/AuthIntegration';
import HomeApplication from '../PagesApplication/HomeApplication';
import GreenRoom from '../pages/GreenRoom';
import UserHome from '../pages/DashboardUserPages/Pages/Home';
import FileGallerys from '../pages/FileGallery';
import Sponsors from '../pages/Sponsors';
import ProgramTheme from '../PagesApplication/HomeApplication/modules/Program';
import Certificados from '../pages/Certificados';
import SurveyView from '../pages/DashboardPages/features/SurveyByURL';
import CientificLibraryAuthor from '../pages/CientificLibraryAuthor';
import CientificLibrary from '../pages/CientificLibrary';


import PodcastLibrary from '../pages/PodcastLibrary';

import EventsPages from '../pages/Paginas';
import SelecionarData from '../pages/SelecionarDatas';

import ListarEventos from '../pages/ListarEventos';
import Termos from '../pages/Termos';
import SignInUpdate from '../pages/SignInUpdate';
import SpeakerTermComponent from '../pages/DashboardPages/features/SpeakerTermPage';
import AccessContentByURL from '../pages/AccessContentByURL';
import AreaConfirmacaoCurso from '../pages/AreaConfirmacaoCurso';

import CertificadosValidar from '../pages/CertificadosValidar';
import Clube from '../pages/Clube';
import Autores from '../pages/Autores';

const ValidPaths = {
  SignIn,
  SignInAdmin,
  SignUp,
  RecoverPassword,
  ResetPassword,
  Products,
  VideosDashboard,

  Home,
  Sobre,
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route

        path="/"
        exact
        component={CientificLibrary}
      />
      <Route path="/validar-certificado/:hash" component={CertificadosValidar} />

      <Route template={'page'} isPrivate isSecret path="/app/:projectHash/dashboard/green-room/:id" component={GreenRoom} />

      <Route
        isPrivate
        path="/user-dashboard"
        component={UserHome}
      />

      <Route

        path="/app/:projectHash/credenciar-curso/:course"
        component={AreaConfirmacaoCurso}
      />

      <Route path="/conheca-os-autores" component={Autores} />
      <Route path="/clube" component={Clube} />
      <Route path="/sobre" component={Sobre} />
      <Route path="/acessar-conteudo/app/:projectHash/:userHash/:contentHash" component={AccessContentByURL} />
      <Route path="/acessar-conteudo/app/:projectHash/:userHash/" component={AccessContentByURL} />

      <Route path="/atualizar-cadastro" component={SignInUpdate} />
      <Route path="/conteudos/autores/:author" component={CientificLibrary} />
      <Route path="/conteudos/autores" component={CientificLibraryAuthor} />
      <Route path="/conteudos/acessar/:posterid" component={CientificLibrary} />

      <Route path="/conteudos/categoria/:eixo" component={CientificLibrary} />
      <Route path="/conteudos" component={CientificLibrary} />

      <Route path="/podcasts/acessar/:posterid" component={PodcastLibrary} />
      <Route path="/podcasts/categoria/:eixo" component={PodcastLibrary} />
      <Route path="/podcasts" component={PodcastLibrary} />

      <Route path="/eventos/:categoryName" component={ListarEventos} />
      <Route path="/eventos" component={ListarEventos} />
      <Route path="/app/:projectHash/accept_terms/:hash" component={SpeakerTermComponent} />

      <Route path="/app/:projectHash/arquivos-de-apoio" component={FileGallerys} />

      <Route path="/app/:projectHash/programacao" component={ProgramTheme} />
      <Route path="/app/:projectHash/paginas/:url" component={EventsPages} />
      <Route path="/app/:projectHash/login/" component={SignIn} />
      <Route path="/login" component={SignIn} />

      <Route path="/app/:projectHash/event/:eventHash/survey/:survey_id" component={SurveyView} />
      <Route path="/app/:projectHash/survey/:survey_id" component={SurveyView} />

      <Route path="/app/:projectHash/event/:eventHash/login/" component={SignIn} />

      <Route path="/app/:projectHash/event/:eventHash/arquivos-de-apoio" component={FileGallerys} />
      <Route path="/app/:projectHash/event/:eventHash/certificados" component={Certificados} />


      <Route path="/app/:projectHash/event/:eventHash/palestrantes" component={Speakers} />
      <Route path="/app/:projectHash/event/:eventHash/patrocinadores" component={Sponsors} />
      <Route path="/app/:projectHash/event/:eventHash/inscricao" component={SignUp} />
      <Route path="/app/:projectHash/selecionar-data" component={SelecionarData} />
      <Route path="/app/:projectHash/inscricao/:productId" component={SignUp} />
      <Route path="/app/:projectHash/certificados" component={Certificados} />

      <Route path="/app/:projectHash/palestrantes" component={Speakers} />
      <Route path="/app/:projectHash/patrocinadores" component={Sponsors} />
      <Route path="/app/:projectHash/inscricao" component={SignUp} />
      <Route path="/inscricao" component={SignUp} />
      <Route path="/recuperar_senha" component={RecoverPassword} />
      <Route path="/reset_password/:key" component={ResetPassword} />
      <Route

        path="/app/:projectHash/event/:eventHash/dashboard/:videoIdItem"
        component={VideosDashboard}
      />

      <Route

        path="/app/:projectHash/event/:eventHash/dashboard/"
        component={VideosDashboard}
      />
      <Route

        path="/app/:projectHash/dashboard/:videoIdItem"
        component={VideosDashboard}
      />
      <Route

        path="/app/:projectHash/dashboard/"
        component={VideosDashboard}
      />


      <Route isPrivate path="/app/:projectHash/poster" component={Poster} />

      <Route path="/app/:projectHash/event/:eventHash" component={HomeApplication} />
      <Route path="/app/:projectHash" component={HomeApplication} />

      <Route template="client" path="/validate/:hash" component={Validate} />
      <Route template="client" path="/events/access/:hash" component={SpeakerLink} />
      <Route path="/zoom" component={Zoom} />

      <Route path="/moderator/access/:projectHash/:hash" component={ModeratorLink} />

      <Route path="/exposicao" component={AreaExposicao} />
      <Route path="/termos" component={Termos} />
      <Route template="client" path="/admin" component={SignInAdmin} />
      <Route isPrivate path="/login_expositor" component={SigninExpositor} />

      <Route

        path="/expositores/:link_name"
        template="page"
        component={ExpositorChat}
      />
      <Route
        isPrivate
        path="/galeria/:link_name"
        template="page"
        component={Galeria}
      />
      <Route
        isPrivate
        path="/expositores_chat/:link_name"
        template="page"
        component={ExpositorChat}
      />


      <Route
        path="/manager/:tab/:projectHash/:eventHash"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />


      <Route
        path="/manager/:tab/:projectHash"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />

      <Route
        path="/manager/:tab"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />
      <Route
        path="/manager"
        template="dashboard"
        isPrivate
        exact
        isSecret
        component={Dashboard}
      />


    </Switch>
  );
};

export default Routes;
