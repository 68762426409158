import Axios from "axios"

export const loadFromJson = async ({ hash, project_id, table }) => {

    try {
        const date = new Date().getTime();

        const response = await Axios(`https://content.encontrodigital.com.br/${hash}/${project_id}/${table}.json?t=${date}`);
        console.log('response', response)

        return response.data;


    } catch (err) {
        return false;
    }

}