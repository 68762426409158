import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaAddressCard,
} from 'react-icons/fa';

import { MdError, MdDateRange } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../Forms/Input';
import Button from '../Button';
import Loader from '../Loader';
import MaskedInput from '../Forms/MaskedInput';
import { ResponseMessage } from '../ResponseMessage';
import getValidationErrors from '../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../Forms/SelectSimple';
import Whatsapp from '../WhatsAppCompartilhar';

import api from '../../services/api';
import { recaptchaKey } from '../../config';
import getCep from '../../services/cep';
import TextareaInput from '../Forms/TextareaInput';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';

interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ hash, callback }) => {
  const { addToast } = useToast();
  const { removeModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const handleNewMessage = async (data: IRecoverFormData) => {
    setLoading(true);

    try {
      formRef.current?.setErrors({});

      const shape = {
        name: Yup.string().required('O campo Nome é obrigatório'),

        email: Yup.string()
          .email('Preencha um email válido')
          .required('O campo Email é obrigatório'),

        message: Yup.string().required('O campo mensagem é obrigatório'),
      };

      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/message-poster/${hash}`, {
        ...data,

        captcha: captchaValue,
      });

      addToast({ title: 'Mensagem enviada com sucesso!', type: 'success' });
      removeModal('messageToPoster');
      setLoading(false);
      callback();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao enviar sua mensagem',
      ]);
    }
    setLoading(false);
  };

  return (
    <>
      <aside>
        {loading === true && (
          <Loader message="Carregando..." position="absolute" />
        )}
        <Form ref={formRef} onSubmit={handleNewMessage} initialData={{}}>
          <Input icon={FiUser} name="name" placeholder="Nome Completo" />
          <Input icon={FiMail} name="email" placeholder="E-mail" />
          <TextareaInput placeholder="Mensagem" name="message" />

          <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
          {errorMessage && (
            <Error>
              {errorMessage.map((item, index) => (
                <div key={`error${index}`}>
                  <MdError />
                  {item}
                </div>
              ))}
            </Error>
          )}
          <Button style={{ width: '100%' }} type="submit">
            Enviar
          </Button>
        </Form>
      </aside>
    </>
  );
};

export default FormContainer;
