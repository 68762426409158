import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;  z-index: 101;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
`;
