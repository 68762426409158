import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const ChartPie = ({ data /* see data tab */, headers, indexBy }) => {


    const [dataSet,setDataSet] = useState([]);

    useEffect(() => {
        let dataTotal = 0;

        const newData = [...data];

        newData.map( d => {
            dataTotal += parseInt(d.value || '0',10);
        })
    
        newData.map( (d,index) => {

  
            newData[index].id = `${d.title} (${((d.value/dataTotal)*100).toFixed(2)}%)`;
        })
    

        setDataSet([...newData]);

    },data)
    

  
     

return   <ResponsivePie
        data={dataSet}
        keys={headers}
        indexBy={indexBy}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
      
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
 
    />
    }

export default ChartPie