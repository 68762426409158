import styled, { css } from "styled-components";

interface IProps {
    themeValue?: 'dark' | 'light';
}

export const ParagraphUI = styled.p<IProps>`

font-size: 14px;
line-height: 18px;
color: #333 !important;
width: 100%;

p,strong,span{
    font-size: 14px; 
    color: #333;
    text-align: left;
}

color: #333;

${props => props?.themeValue === 'dark' && css`
color: #fff !important;
`}



`