import React from 'react';
import { FaTextWidth } from 'react-icons/fa';

import { useAuth } from '../../../../../hooks/Auth';

import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { AiFillRobot } from 'react-icons/ai';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function ExportList({
  lineData,
}: ICreateModule): JSX.Element {

  const { user } = useAuth();
  const { addToast } = useToast();
  const upgradeText = async (data) => {


    await api.post(`upgrade-text-with-ai`, { id: lineData?._id?.toString(), column: 'transcription', table: 'CientificLibrary' });
    addToast({ title: 'Encaminhado para otimização com IA', type: 'success' })
    return;


  }

  const moduleList = ({ data }): JSX.Element => {

    return <> {user && user.profile === 1 ? <button className="lineIcon" title="Remover" type="button" onClick={() => upgradeText(data)}>  <AiFillRobot color="#333" size={30} title="Aprimorar texto" /> </button> : <></>}
    </>;
  }



  return moduleList({ data: lineData });
}
