import styled, { keyframes } from 'styled-components';


export const StatusContainer = styled.div`
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 10px;
  margin: 15px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  background: #fff ;

  >div{
    display: flex;
  justify-content: center;
  align-items: center;
  }

`;

interface IActive {
  active: boolean;
}
export const StatusItem = styled.div<IActive>`
padding: 5px;
border-radius: 50%;
margin: 5px;
background: ${props => props.active ? 'rgb(0,100,0)' : '#fff'};
color: ${props => props.active ? '#fff' : 'rgb(0,100,0)'};
display: flex;
    align-items: center;
    justify-content: center;
  transition: 0.4s all;
  cursor: pointer;

  &:hover {
      transform: scale(1.2);
    }
  >svg, >img{

    
    font-size: 20px;
    
    
  }
  
`