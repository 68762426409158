import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const CientificLibraryAuthor: React.FC = () => {
  const endpoint = '/cientific-library-author';
  const title = 'Autores da Biblioteca';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    is_author: { ref: 'is_author', column: 'is_author', label: 'É um autor?' },
    category: { ref: 'category', column: 'category', label: 'Área' },
    title: { ref: 'title', column: 'title', label: 'Título' },

    createdAt: {
      ref: 'createdAt',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    is_author: {
      model: 'selectSimple',
      name: 'is_author',
      label: 'É um autor?',
      default: 'yes',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    category: { model: 'input', type: 'text', name: 'category', label: 'Área' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Minicurrículo' },

    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },



  };






  const formSchemaUpdate = {
    ...formSchema,

    photo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'photo',
      label: 'Foto',
    },
  };


  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default CientificLibraryAuthor;
