import styled, { keyframes } from 'styled-components';
interface IBackground {
    background ?: string;
}

const active = keyframes`
0% {
  transform: scale(1);
    box-shadow: 0px;
    border: 0px;
}

20%{
  transform: scale(1);
    box-shadow: 0px;
    border: 0px;
}
100%{
  transform: scale(1.1);
    box-shadow: 0px 3px 15px rgba(0,0,0,0.3);
    border: 3px solid #fff;
}
`

export const Capa = styled.div<IBackground>`
min-height: 30vh;

display: flex;
flex-direction: column;

justify-content: center;
align-items: flex-start;
 
color: #fff;
padding: 10px  0px;
width: 100%;
max-width: 500px;
 
position: relative;



>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
width: auto;
padding: 0px 10px;
  margin: 0 10px;
  @media (max-width: 1200px) {
      width: 100%;
      padding: 0px;
      margin: 0px;
    }

}

@media (max-width: 1200px) {
  min-height: auto;
  flex-direction: column;
  padding: 20px 10px;
}

> .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    a{
      width : 100% ;
    }

    @media (max-width: 1200px) {
      width: 100%;
    }

    > h2 {
      font-size: 18px;

      @media (max-width: 1200px) {
        text-align: center;
      }
    }

    > p {
      text-align: justify;
    }
  }

  .active {
    animation: ${active} 0.5s forwards ;
  }

  .proximoseventos{
    display: flex ;
    align-items: center;
    justify-content: flex-start;
    background:rgba(45, 77, 181,0.6) ;
    border-radius: 10px;
    margin: 10px 0px;
    .data-proximoseventos{
      display: flex ;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70px ;
    height: 70px ;
    min-width: 70px ;
    min-height: 70px ;
    margin: 0px;
    
    margin-right: 25px;
    background: #fff;
    border-radius: 10px;
    color:  rgb(45, 77, 181);
    background:#fff ;

    >h2{
      font-size: 24px;
    
    }
    >h3{
      font-size: 18px;
    }
    }

    h2{
      text-transform: uppercase;
    }
  }

`;


export const Photo = styled.div<IBackground>`
  width: 250px;
  margin: 10px;
  border-radius: 10px;
  height: 250px;
  background: url(${props => props.background}) no-repeat;
  background-size: cover;
  background-position: center;
transition: 0.2s all;
box-shadow: rgb(7 13 28 / 25%) 0px 10px 30px;
border: 0px;
  &:hover{
    transform: translateY(-15px);

    border: 3px solid #fff;
  }

 
`;
