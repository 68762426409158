import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
// import background from '../../assets/background.jpg';

import theme from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Container = styled.div`
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1500px;
  padding: 0px 50px;
  padding-bottom: 200px;

  .posterPlace{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 750px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  }

  @media (max-width: 750px) {
    padding: 5px;
    padding-bottom: 200px;
  }

  > div.textContent {
    padding: 20px 40px;

    > p.title {
      font-size: 16px;
      font-weight: 400;
    }

    > p {
      margin: 20px 0;
      font-size: 14px;
    }
  }

  .fixedplayer {
    position: fixed;
    bottom: 0%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(50, 50, 50, 0.8);
    padding: 10px;
    z-index: 1000000;

    > button {
      min-width: 100px;
      width: 100px;
      background: #026271;
      color: #fff;
      border: 0;
      transition: background 0.4s;
      padding: 10px;
      height: 46px;

      &:hover {
        background: #027271;
      }
    }

    > button.closeButton {
      cursor: pointer;
      background: #f15a23 !important;
      color: #fff  !important;
      border-radius: 10px  !important;
    }
  }

    > button.notUse {
      cursor: default;
      background: #555;
      &:hover {
        background: #555;
      }
    }
  }

  .sumario {
    background: #026271;
    color: #fff;
    z-index: 1999;
    min-width: 150px;
    height: auto;

    > nav {
      padding: 10px;
      color: #fff;
      cursor: pointer;
      transition: 0.5s all;
      font-size: 12px;

      &:hover {
        background: #027271;
      }
    }

    > nav + nav {
      border-top: 1px solid #fff;
    }

    @media (max-width: 750px) {
      display: none;
    }
  }

  .posterBlock {
    width: 100%;
    max-width: 1000px;
    max-height: auto;

    overflow: hidden;
  }

  .posterDiv {
    width: 100%;

    position: relative;
    z-index: 10000;
    transition: 1s all;
    > .sector {
      position: absolute;

      background: none;
      z-index: 1999;
      cursor: pointer;
      transition: 1s all;
    }

    > img {
      width: 100%;
      transition: 1s all;
    }
  }
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0,0,0,0.3);
  padding: 40px 0px;
  animation: ${fadeFromLeft} 1s;

  form {
    margin: 30px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4edef;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4edef')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  img {
    width: 200px;
    max-width: 300px;
    margin: 10px;
  }

  @media (max-width: 450px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 0;
`;
