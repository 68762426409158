import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { background } from '../../config';
import theme from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Container = styled.div`
  min-height: 80vh;
  display: flex;

  justify-content: center;
  align-items: center;

  @media (max-width: 750px) {
    min-height: auto;
    align-items: flex-start;
  }
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0,0,0,0.7);
  padding: 40px 0px;
  animation: ${fadeFromLeft} 1s;

  @media (max-width: 750px) {
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
  }

  form {
    margin: 30px 0;
    width: 340px;
    max-width: 100%;
    text-align: center;

    @media (max-width: 750px) {
      padding: 0px 20px;
      width: 100%;
      max-width: 100%;
    }

    h2 {
      margin-bottom: 24px;
      color: theme.color};
    }

    a {
      color: theme.color};
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2,theme.color)};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  img {
    width: 250px;
    max-width: 250px;
    margin: 10px;

    @media (max-width: 750px) {
      display: none;
    }
  }

  @media (max-width: 750px) {
    padding: 0;
    width: 100%;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top left;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
