import React from 'react';
import { FaAudioDescription, FaFileAudio, FaTextWidth } from 'react-icons/fa';

import { useAuth } from '../../../../../hooks/Auth';

import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/Toast';
import { AiFillRobot } from 'react-icons/ai';

interface ICreateModule {
  lineData: Record<string, any>;
}


export default function ExportList({
  lineData,
}: ICreateModule): JSX.Element {

  const { user } = useAuth();
  const { addToast } = useToast();
  const textToAudio = async (data) => {


    await api.post(`text-to-audio-ai`, { id: lineData?._id?.toString(), column: 'transcription_audio', getColumn: 'transcription', table: 'CientificLibrary' });
    addToast({ title: 'Encaminhado para geração do audio', type: 'success' })
    return;


  }

  const moduleList = ({ data }): JSX.Element => {

    return <> {user && user.profile === 1 ? <button className="lineIcon" title="Remover" type="button" onClick={() => textToAudio(data)}>  <FaFileAudio color="#333" size={30} title="Gerar audio a partir da transcrição" /> </button> : <></>}
    </>;
  }



  return moduleList({ data: lineData });
}
