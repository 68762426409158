import React from 'react';
import {Container} from './styles';

interface IStyle {
  maxWidth ?: string;
}

const Gallery: React.FC<IStyle> = ({children, maxWidth = '100%'})=> {
  return ( <Container style={{maxWidth}}> {children}</Container> )
}

export default Gallery;
  