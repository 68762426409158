import styled, { css } from 'styled-components';

interface IMessage {
  isMe: boolean;
}

interface Igreen {
  className?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  min-height: 100vh;
  overflow-y: auto;
  z-index: 1900;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div<Igreen>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin: 5px;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fff !important;
  padding: 20px;
  max-width: 700px;

  width: 400px;
  min-height: 80vh;

  .message {
    color: #333 !important;

    overflow-wrap: anywhere;
  }

  ${props =>
    props.className === 'greenMessages' &&
    css`
      background: green !important;
      div {
        background: green !important;
        color: #fff !important;

        p,
        h2,
        .message,
        .date {
          color: #fff !important;
          font-weight: bold;
        }
      }
    `}

  .greenball {
    width: 15px;
    height: 15px;
    min-width: 15px;
    background: rgb(0, 200, 0);
    border-radius: 50%;
    margin-right: 10px;
  }

  @media (max-width: 750px) {
    flex-direction: column-reverse;
    width: 100%;
    padding: 10px;

    justify-content: flex-end;

    > div.main {
      margin-top: 10px;
      h2 {
        display: none;
      }
    }
  }

  > div.main {
    width: 100%;
    > h2 {
      color: #333;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      align-items: center;
      display: flex;
      justify-content: center;
      > svg {
        font-size: 40px;
        color: rgb(0, 100, 150);
      }
      @media (max-width: 750px) {
        display: none;
      }
    }

    @media (max-width: 750px) {
      justify-content: flex-end;
      width: 100%;
    }

    > div {
      justify-content: flex-start;
    }
  }
`;

export const Body = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 0px;
  overflow-y: auto;

  @media (max-width: 750px) {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const Message = styled.div<IMessage>`
  z-index: 1;
  display: flex;
  flex-direction: row;
  background: ${props => (props.isMe === true ? '#fff' : 'none')};
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px;
  align-items: center;
  @media (max-width: 750px) {
    width: 100%;
  }

  > div {
    width: 100%;
    > div.message {
      display: flex;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      padding: 5px;
      justify-content: space-between;
      color: #333;
      margin-bottom: -10px;

      > p.date {
        font-weight: 400;
        max-width: 50px;
      }
    }

    > div.info {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      width: 100%;
      padding: 5px;
      justify-content: space-between;
      color: #333;
    }
  }
`;

export const OnlineUser = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px;

  @media (max-width: 750px) {
    width: 100%;
  }

  > div.message {
    display: flex;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
    color: #333;
    margin-bottom: -10px;
  }

  > div.info {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 100%;
    padding: 5px;
    justify-content: space-between;
    color: #333;
  }
`;

export const Footer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 0px;
  border-top: 2px solid #333;
  padding-top: 10px;
  margin-top: 10px;
  > form {
    width: 100%;
    display: flex;
    justify-content: space-space-between;
    align-items: center;
    > input {
      width: 100%;
      color: #333;
      padding: 10px;
      border: 1px solid #ddd;
    }

    > div {
      text-align: right;
      width: auto;
      margin: 0;

      > button {
        padding: 10px 20px;
        border-radius: 0px;
        background: rgb(0, 100, 150);
        color: #fff;
        cursor: pointer;
        border: 0;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    @media (max-width: 750px) {
      display: flex;

      > input {
        padding: 20px;
      }
      > div {
        max-width: 100px;
        > button {
          padding: 20px;
        }
      }
    }
  }
`;

export const Faces = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 70px;
    max-width: 70px;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 148, 217);
    padding: 5px;
    margin: 5px;
    min-height: 70px;
    border-radius: 8px;
    overflow: hidden;
    > span {
      color: white;
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
  }
`;

export const Info = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background: rgba(0, 100, 200, 0.7);
  color: #fff;
  font-size: 12px;
  margin: 5px 0px;
`;

interface IStatus {
  status?: number;
}

export const Ball = styled.div<IStatus>`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  background: grey;
  border-radius: 50%;
  margin: 0 5px;

  ${props =>
    props.status === 1 &&
    css`
      background: green;
    `}

  ${props =>
    props.status === 2 &&
    css`
      background: blue;
    `}

  ${props =>
    props.status === 3 &&
    css`
      background: grey;
    `}
`;
