import styled from 'styled-components';

export const Container = styled.div`
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #fff;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  padding-top: 50px;
  padding-bottom: 50px;
  align-items: flex-start;
  width: 100%;
`;
