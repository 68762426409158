import React, { useState, useEffect } from 'react';

import {
  FaPlus,
  FaTrash,
  FaQuestion,
  FaList,
  FaChartPie,
  FaEye,
} from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { Container, Field } from './styles';
import { Modal } from '../../styles';
import { useModal } from '../../../../hooks/Modal';


import { useSocket } from '../../../../hooks/Socket';
import { useLanguage } from '../../../../hooks/Language';

interface IID {
  roomNumber: string;
  active?: boolean;
  setOption: React.Dispatch<React.SetStateAction<string>>;
}

interface IAnswers {
  name: string;
  value?: string;
  label?: string;
}

interface IQuestions {
  id?: string;
  title: string;
  type: number;
  questions: Array<Record<string, any>>;
}

const PopupComponent: React.FC<IID> = ({ active = false, setOption, roomNumber = 0 }) => {
  const { socket } = useSocket();
  const { translate } = useLanguage();
  const [value, setValue] = useState({
    title: '',
    message: '',
    room_id: roomNumber,
    button: '',
    button_url: '',
  });

  const [activePopup, setActivePopup] = useState(false);
  const [popup, setPopup] = useState(<> </>);

  const { addModal } = useModal();

  /* EXCLUI UMA ENQUETE */

  const read = async item => {

    setPopup(
      <div style={{ textAlign: 'center' }}>
        {item.title && <h2>{item.title}</h2>}
        {item.message && (
          <p style={{ fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: item.message }} />
        )}
        {item.button && item.button_url && (
          <a href={item.button_url}>
            <button
              style={{ margin: '10px auto' }}
              type="button"
              className="actionAdminButton gradientBg"
            >
              {item.button}
            </button>
          </a>
        )}
      </div>,
    );

    setActivePopup(true);
  };

  const send = async () => {
    const data = {
      ...value,
    };
    console.log(data);
    socket?.emit('sendPopup', data);
  };

  const changeValue = (target, text) => {
    const newValue = { ...value };
    newValue[target] = text;
    setValue({ ...newValue });
  };

  useEffect(() => {
    socket?.off('showPopup');
    socket?.on('showPopup', data => {
      console.log(data)
      if (data) {
        read(data);
      }
    });
  }, []);

  useEffect(() => {
    changeValue('room_id', roomNumber);
  }, [roomNumber]);

  return (
    <Container>

      {active && (
        <Modal style={{ justifyContent: 'flex-start', paddingTop: '30px' }}>
          <div>
            <button type='button' className='closeAdminButton' onClick={() => setOption('')}>{translate("Fechar")} <FiX /></button>


            <h2>{translate("Criar Popup")}</h2>
            <Field>
              <input
                onChange={e => changeValue('room_id', e.target.value)}
                value={value.room_id}
                placeholder={translate("Sala")}
              />
            </Field>
            <Field>
              <label>Título</label>
              <textarea
                onChange={e => changeValue('title', e.target.value)}
                value={value.title}
                placeholder={translate("Pergunta")}
              />
            </Field>
            <Field>
              <label>Mensagem</label>
              <textarea
                onChange={e => changeValue('message', e.target.value)}
                value={value.message}
                placeholder={translate("Mensagem")}
              />
            </Field>

            <Field>
              <label>Texto do Botão</label>
              <input
                onChange={e => changeValue('button', e.target.value)}
                value={value.button}
                placeholder={translate("Texto do botão")}
              />
            </Field>

            <Field>
              <label>Link do Botão</label>
              <input
                onChange={e => changeValue('button_url', e.target.value)}
                value={value.button_url}
                placeholder={translate("Texto do botão")}
              />
            </Field>

            <div className='actionDiv'>
              <button className='actionAdminButton' type="button" onClick={() => read(value)}>
                {translate("Visualizar")}
              </button>

              <button className='actionAdminButton' type="button" onClick={() => send()}>
                {translate("Enviar")}
              </button>
            </div>
          </div>

        </Modal>
      )}

      {activePopup && (
        <Modal
          style={{
            flexDirection: 'column',
            justifyContent: 'flex-start',

            zIndex: 1000000000,
            paddingTop: '30px',
          }}
        >
          {popup}



          <button style={{ marginTop: '25px' }}
            className='actionAdminButton'
            type="button"
            onClick={() => setActivePopup(false)}
          >
            Fechar
          </button>
        </Modal>
      )}
    </Container>
  );
};

export default PopupComponent;
